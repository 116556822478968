/* eslint-disable no-unsafe-optional-chaining */
import { container } from '@_plugins/ioc';
// api
import LaravelAPI from '@data/api/laravel.api';
import { toActivity } from '@data/dto/activity.dto';
import { Activity, ActivityHttp } from '@data/models/activity.interface';
import { Meta, PaginateResult } from '@data/models/api.interface';
// models
import { FilterParams } from '@data/models/filter.interface';
import { TrainingCourse } from '@data/models/training-course.interface';
// services
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class ActivityService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private errorService = container.get<ErrorService>(ErrorService);

  private readonly activityEndpoint = '/activities';

  public async listActivities(
    filters: FilterParams,
    trainingCourse: TrainingCourse,
  ): Promise<PaginateResult<Activity[]>> {
    const { data: response, error } = await this.API?.get<{
      data: ActivityHttp[];
      meta?: Meta;
    }>(this.activityEndpoint, {
      ...filters,
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const items =
      response?.data.map((http) => toActivity(http, trainingCourse)) || [];

    return {
      items,
      totalSize: response?.meta?.page.total || 0,
    };
  }
}

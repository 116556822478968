import {
  DOCUMENT_TYPE,
  DOCUMENT_TYPE_CATEGORY,
  DocumentType,
} from '@data/models/document.interface';

export const getDocumentTypes = (): DocumentType[] => {
  return [
    {
      value: DOCUMENT_TYPE.TYPE_CONVOCATION,
      label: 'training-course.ged.type.convocation',
      category: DOCUMENT_TYPE_CATEGORY.TRAINING_ORGANIZATION,
    },
    {
      value: DOCUMENT_TYPE.TYPE_QUOTATION,
      label: 'training-course.ged.type.quotation',
      category: DOCUMENT_TYPE_CATEGORY.TRAINING_ORGANIZATION,
    },
    {
      value: DOCUMENT_TYPE.TYPE_TRAINING_AGREEMENT,
      label: 'training-course.ged.type.training_agreement',
      category: DOCUMENT_TYPE_CATEGORY.TRAINING_ORGANIZATION,
    },
    {
      value: DOCUMENT_TYPE.TYPE_PROGRAM,
      label: 'training-course.ged.type.program',
      category: DOCUMENT_TYPE_CATEGORY.TRAINING_ORGANIZATION,
    },
    {
      value: DOCUMENT_TYPE.TYPE_ATTENDANCE,
      label: 'training-course.ged.type.attendance ',
      category: DOCUMENT_TYPE_CATEGORY.TRAINING_ORGANIZATION,
    },
    {
      value: DOCUMENT_TYPE.TYPE_ACHIEVEMENT_CERTIFICATE,
      label: 'training-course.ged.type.achievement_certificate',
      category: DOCUMENT_TYPE_CATEGORY.TRAINING_ORGANIZATION,
    },
    {
      value: DOCUMENT_TYPE.TYPE_INVOICE,
      label: 'training-course.ged.type.invoice',
      category: DOCUMENT_TYPE_CATEGORY.TRAINING_ORGANIZATION,
    },
    {
      value: DOCUMENT_TYPE.TYPE_TRAINING_CERTIFICATE,
      label: 'training-course.ged.type.training_certificate',
      category: DOCUMENT_TYPE_CATEGORY.TRAINING_ORGANIZATION,
    },
    {
      value: DOCUMENT_TYPE.TYPE_CERFAS,
      label: 'training-course.ged.type.cerfas ',
      category: DOCUMENT_TYPE_CATEGORY.GENERATED_DOCUMENTS,
    },
    {
      value: DOCUMENT_TYPE.TYPE_YOUTH_MISSION_COMMITMENT_CHARTER,
      label: 'training-course.ged.type.youth_mission_commitment_charter ',
      category: DOCUMENT_TYPE_CATEGORY.GENERATED_DOCUMENTS,
    },
    {
      value: DOCUMENT_TYPE.TYPE_TRIPARTITE_AGREEMENT,
      label: 'training-course.ged.type.tripartite_agreement ',
      category: DOCUMENT_TYPE_CATEGORY.GENERATED_DOCUMENTS,
    },
    {
      value: DOCUMENT_TYPE.TYPE_AID_APPLICATION_FORM,
      label: 'training-course.ged.type.aid_application_form ',
      category: DOCUMENT_TYPE_CATEGORY.GENERATED_DOCUMENTS,
    },
    {
      value: DOCUMENT_TYPE.TYPE_DRF,
      label: 'training-course.ged.type.drf',
      category: DOCUMENT_TYPE_CATEGORY.OPCO,
    },
    {
      value: DOCUMENT_TYPE.TYPE_DRF_AGREEMENT,
      label: 'training-course.ged.type.drf_agreement',
      category: DOCUMENT_TYPE_CATEGORY.OPCO,
    },
    {
      value: DOCUMENT_TYPE.TYPE_GENERATION_STANDARD_CONTRACTS,
      label: 'training-course.ged.type.generation_standard_contracts',
      category: DOCUMENT_TYPE_CATEGORY.OPCO,
    },
    {
      value: DOCUMENT_TYPE.TYPE_PARTS_TRANSMISSION,
      label: 'training-course.ged.type.parts_transmission ',
      category: DOCUMENT_TYPE_CATEGORY.OPCO,
    },
    {
      value: DOCUMENT_TYPE.TYPE_OPCO_AGREEMENT,
      label: 'training-course.ged.type.opco_agreement',
      category: DOCUMENT_TYPE_CATEGORY.OPCO,
    },
    {
      value: DOCUMENT_TYPE.TYPE_DR,
      label: 'training-course.ged.type.dr',
      category: DOCUMENT_TYPE_CATEGORY.OPCO,
    },
    {
      value: DOCUMENT_TYPE.TYPE_COFINANCING_REQUESTS,
      label: 'training-course.ged.type.cofinancing_requests ',
      category: DOCUMENT_TYPE_CATEGORY.OPCO,
    },
    {
      value: DOCUMENT_TYPE.TYPE_COFUNDING_AGREEMENT,
      label: 'training-course.ged.type.cofunding_agreement ',
      category: DOCUMENT_TYPE_CATEGORY.OPCO,
    },
    {
      value: DOCUMENT_TYPE.TYPE_PROOF_OF_REFUND,
      label: 'training-course.ged.type.proof_of_refund',
      category: DOCUMENT_TYPE_CATEGORY.OPCO,
    },
    {
      value: DOCUMENT_TYPE.TYPE_EPSP_CHARTER,
      label: 'training-course.ged.type.epsp_charter ',
      category: DOCUMENT_TYPE_CATEGORY.INTERNAL,
    },
    {
      value: DOCUMENT_TYPE.TYPE_REQUEST_ABSENCE,
      label: 'training-course.ged.type.request_absence',
      category: DOCUMENT_TYPE_CATEGORY.INTERNAL,
    },
    {
      value: DOCUMENT_TYPE.TYPE_TRAINING_CONTRACT,
      label: 'training-course.ged.type.training_contract',
      category: DOCUMENT_TYPE_CATEGORY.INTERNAL,
    },
    {
      value: DOCUMENT_TYPE.TYPE_MISSION_CONTRACT,
      label: 'training-course.ged.type.mission_contract ',
      category: DOCUMENT_TYPE_CATEGORY.INTERNAL,
    },
    {
      value: DOCUMENT_TYPE.TYPE_PAYSLIPS,
      label: 'training-course.ged.type.payslips ',
      category: DOCUMENT_TYPE_CATEGORY.INTERNAL,
    },
    {
      value: DOCUMENT_TYPE.TYPE_CV,
      label: 'training-course.ged.type.cv ',
      category: DOCUMENT_TYPE_CATEGORY.INTERNAL,
    },
    {
      value: DOCUMENT_TYPE.TYPE_ELIGIBILITY_PROOF,
      label: 'training-course.ged.type.eligibility_proof',
      category: DOCUMENT_TYPE_CATEGORY.INTERNAL,
    },
    {
      value: DOCUMENT_TYPE.TYPE_DUE_ACKNOWLEDGMENT_OF_RECEIPT,
      label: 'training-course.ged.type.due_acknowledgment_of_receipt',
      category: DOCUMENT_TYPE_CATEGORY.NATIONAL_AGENCY,
    },
    {
      value: DOCUMENT_TYPE.TYPE_NATIONAL_TRAINING_CONTRACT,
      label: 'training-course.ged.type.national_training_contract ',
      category: DOCUMENT_TYPE_CATEGORY.NATIONAL_AGENCY,
    },
    {
      value: DOCUMENT_TYPE.TYPE_NATIONAL_PAYSLIP,
      label: 'training-course.ged.type.national_payslip ',
      category: DOCUMENT_TYPE_CATEGORY.NATIONAL_AGENCY,
    },
    {
      value: DOCUMENT_TYPE.TYPE_COMPETENCE_DIAGNOSTIC,
      label: 'training-course.ged.type.diag_competence',
      category: DOCUMENT_TYPE_CATEGORY.EXTERNAL,
    },
    {
      value: DOCUMENT_TYPE.TYPE_OTHER,
      label: 'training-course.ged.type.other',
      category: DOCUMENT_TYPE_CATEGORY.OTHER,
    },
  ];
};

export const getDocumentTypeByValue = (
  value: DOCUMENT_TYPE,
): DocumentType | null => {
  return (
    getDocumentTypes().find(({ value: typeValue }) => typeValue === value) ||
    null
  );
};

export const getDocumentTypeCategoryLabel = (
  type: DocumentType | null,
): string => {
  if (!type?.category) {
    return '';
  }

  return `training-course.ged.optgroup-type.${type.category}`;
};

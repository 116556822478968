import { forwardRef, useMemo, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { flatRoutes } from '@_plugins/router/router';
import { NavRoute } from '@data/models/navigation.interface';
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProvider } from '@groupeactual/ui-kit';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import useStore from '@stores/store';
import isEmpty from 'lodash/isEmpty';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  navRoute: NavRoute;
  level?: number;
  path?: string;
  closeParentMenu?: () => void;
}

// eslint-disable-next-line complexity
const Navigation = ({
  navRoute,
  level = 1,
  path = navRoute.path,
  closeParentMenu = () => null,
  t: trans,
}: Props & WithTranslation): JSX.Element => {
  const { pathname } = useLocation();
  const [anchor, setAnchor] = useState(null);

  const { user } = useStore(
    useShallow((state) => ({
      user: state.user,
    })),
  );
  const activeRoute = useMemo(
    () => flatRoutes.find(({ path }) => pathname === path),
    [pathname],
  );

  const isActive =
    (activeRoute?.ancestors?.find(({ name }) => navRoute.name === name) &&
      true) ||
    false;

  if (
    navRoute.hidden ||
    (navRoute.permissions &&
      !navRoute.permissions.some((permission) =>
        user.permissions.includes(permission),
      ))
  ) {
    return <></>;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openMenu = (e: any) => {
    setAnchor(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
    closeParentMenu();
  };

  if (isEmpty(navRoute.children)) {
    const CustomLink = useMemo(
      () =>
        // eslint-disable-next-line react/display-name
        forwardRef<HTMLAnchorElement>((linkProps, ref) => (
          <MuiLink
            ref={ref}
            component={Link}
            to={path}
            {...linkProps}
            sx={{
              padding: 0,
            }}
            onClick={() => closeMenu()}
          />
        )),
      [path],
    );

    return (
      <div>
        <ListItem
          selected={isActive}
          button
          component={CustomLink}
          alignItems="center"
          sx={[
            {
              height: 46,
              width: 'auto',
            },
            {
              '&:hover': {
                backgroundColor: 'blueHoverEquivalence',
                color: 'blueClickable',
                fontWeight: 500,
              },
            },
            {
              '&.Mui-selected:not(.MuiPopover-paper .MuiListItem-gutters)': {
                fontWeight: 500,
                color: 'greyXDark',
                backgroundColor: '#FFFFFF !important',
                /* prettier-ignore */
                borderBottom: '2px solid #E40521',
              },
            },
          ]}
        >
          <ListItemIcon sx={{ minWidth: 25 }}>{navRoute.icon}</ListItemIcon>
          <span>{trans(navRoute.name)}</span>
        </ListItem>
      </div>
    );
  }

  return (
    <div>
      <ListItem
        button
        onClick={openMenu}
        alignItems="center"
        selected={isActive}
        sx={[
          {
            height: 46,
            width: 'auto',
            cursor: 'pointer',
          },
          {
            '&:hover': {
              backgroundColor: 'blueHoverEquivalence',
              color: 'blueClickable',
              fontWeight: 500,
            },
          },
          {
            '&.Mui-selected:not(.MuiPopover-paper .MuiListItem-gutters)': {
              backgroundColor: '#FFFFFF !important',
              fontWeight: 500,
              /* prettier-ignore */
              borderBottom: '2px solid #E40521',
              color: 'greyXDark',
            },
          },
        ]}
      >
        <ListItemIcon sx={{ minWidth: 25 }}>{navRoute.icon}</ListItemIcon>
        <ListItemText primary={trans(navRoute.name)} disableTypography />
        <Box ml={1}>
          {level === 1
            ? (Boolean(anchor) && (
                <IconProvider icon={faChevronUp} size="sm" />
              )) || <IconProvider icon={faChevronDown} size="sm" />
            : (Boolean(anchor) && (
                <IconProvider icon={faChevronRight} size="sm" />
              )) || <IconProvider icon={faChevronDown} size="sm" />}
        </Box>
      </ListItem>

      <Popover
        id={navRoute.name}
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: (level === 1 && 'bottom') || 'bottom',
          horizontal: (level === 1 && 'left') || 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{
          top: level === 1 ? 5 : -5,
          left: level === 1 ? 0 : 5,
        }}
      >
        <List>
          {(navRoute?.children || []).map((c, key) => (
            <NavigationWithTranslation
              key={`${c.name}-${key}`}
              navRoute={c}
              level={level + 1}
              path={`${path}${c.path}`}
              closeParentMenu={closeMenu}
            />
          ))}
        </List>
      </Popover>
    </div>
  );
};

const NavigationWithTranslation = withTranslation()(Navigation);

export default NavigationWithTranslation;

/* eslint-disable no-unsafe-optional-chaining */
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import {
  fromTrainingCourseCandidateHttp,
  toTrainingCourseCandidateHttp,
} from '@data/dto/training-course-candidate.dto';
import {
  fromTrainingCourseCustomerHttp,
  toTrainingCourseCustomerHttp,
} from '@data/dto/training-course-customer.dto';
import {
  fromEduationalCostHttp,
  fromEstimatorCandidateTypeHttp,
  fromTrainingCourseEstimatorHttp,
  toEducationalCostWizard,
  toEstimatorCandidateTypeWizard,
  toTrainingCourseEstimatorWizard,
} from '@data/dto/training-course-estimator.dto';
import {
  fromTrainingCourseGroupHttp,
  toTrainingCourseGroupHttp,
} from '@data/dto/training-course-group.dto';
import {
  toTrainingCourseSession,
  toTrainingCourseSessionHttp,
} from '@data/dto/training-course-session.dto';
import {
  toTrainingCourse,
  toTrainingCourseIncluded,
} from '@data/dto/training-course.dto';
import { toUser } from '@data/dto/user.dto';
import { Meta, PaginateResult } from '@data/models/api.interface';
import { FilterParams } from '@data/models/filter.interface';
import { TRAINING_ACTION_STATUSES } from '@data/models/training-action.interface';
import {
  TrainingCourseCandidate,
  TrainingCourseCandidateHttp,
} from '@data/models/training-course-candidate.interface';
import {
  TrainingCourseCustomer,
  TrainingCourseCustomerHttp,
} from '@data/models/training-course-customer.interface';
import {
  EducationalCost,
  EducationalCostHttp,
  EducationalCostWizard,
  EstimatorCandidateType,
  EstimatorCandidateTypeHttp,
  EstimatorCandidateTypeWizard,
  TrainingCourseEstimator,
  TrainingCourseEstimatorHttp,
  TrainingCourseEstimatorWizard,
} from '@data/models/training-course-estimator.interface';
import {
  TrainingCourseGroup,
  TrainingCourseGroupHttp,
} from '@data/models/training-course-group.interface';
import {
  TrainingCourseSession,
  TrainingCourseSessionHttp,
} from '@data/models/training-course-session.interface';
import { TrainingCourseTraining } from '@data/models/training-course-training.interface';
import {
  TRAINING_COURSE_PRIORITIES,
  TRAINING_COURSE_STATUSES,
  TrainingCourse,
  TrainingCourseHttp,
  TrainingCourseRawIncluded,
  TrainingCourseStatus,
  TrainingCourseWizard,
} from '@data/models/training-course.interface';
import { TrainingCoursePriority } from '@data/models/training-course.interface';
import { User } from '@data/models/user.interface';
import useStore from '@stores/store';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';
import omit from 'lodash/omit';

@injectable()
export default class TrainingCourseService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly trainingCourseEndpoint = '/training-courses';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listTrainingCourse(
    filters: FilterParams,
  ): Promise<PaginateResult<TrainingCourse[]>> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingCourseHttp[];
      meta?: Meta;
      included: TrainingCourseRawIncluded;
    }>(this.trainingCourseEndpoint, {
      ...filters,
      include: [
        'groups',
        'potential_candidates',
        'project_origin',
        'profession',
        'strategic_axes',
        'educational_paths',
        'educational_paths.training_course_trainings',
        'educational_paths.training_course_trainings.expected_city',
        'educational_paths.training_course_trainings.city',
        'educational_paths.training_course_trainings.training_options',
        'educational_paths.training_course_trainings.training',
        'educational_paths.training_course_trainings.sessions',
        'educational_paths.training_course_trainings.educational_path',
        'educational_paths.training_course_trainings.validation_type_repository',
        'educational_paths.validation_type',
        'educational_paths.course_type',
        'customers',
        'region',
        'estimators',
        'estimators.device_type',
      ],
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const included = toTrainingCourseIncluded(response?.included || []);

    const items =
      response?.data?.map((trainingCourse) =>
        toTrainingCourse(trainingCourse, useStore.getState().user, included),
      ) || [];

    return {
      items,
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async listTrainingCourseCandidates(
    filters: FilterParams,
  ): Promise<PaginateResult<TrainingCourseCandidate[]>> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingCourseCandidateHttp[];
      meta?: Meta;
    }>('/training-course-candidates', {
      ...filters,
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const items =
      response?.data?.map((candidate) =>
        fromTrainingCourseCandidateHttp(candidate, []),
      ) || [];

    return {
      items,
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async getTrainingCourseByRef(
    reference: string,
  ): Promise<TrainingCourse | null> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingCourseHttp[];
      included: TrainingCourseRawIncluded;
    }>(`${this.trainingCourseEndpoint}`, {
      filter: {
        reference,
        with_trashed: 1,
      },
      itemsPerPage: 1,
      page: 1,
      include: [
        'groups',
        'potential_candidates',
        'potential_candidates.candidate_type',
        'project_origin',
        'profession',
        'strategic_axes',
        'customers.customer_type',
        'region',
        'manager',
        'cpef_manager',
        'sessions',
        'potential_candidates.profession',
        'estimators',
        'estimators.educational_costs',
        'estimators.min_wage',
        'estimators.opco_charge',
        'estimators.device_type',
        'estimators.validation_type',
        'estimators.approver',
        'estimators.candidate_types',
        'estimators.candidate_types.candidate_type',
        'estimators.co_funders',
        'educational_paths',
        'educational_paths.training_course_trainings',
        'educational_paths.training_course_trainings.expected_city',
        'educational_paths.training_course_trainings.city',
        'educational_paths.training_course_trainings.training_options',
        'educational_paths.training_course_trainings.training',
        'educational_paths.training_course_trainings.sessions',
        'educational_paths.training_course_trainings.educational_path',
        'educational_paths.training_course_trainings.validation_type_repository',
        'educational_paths.validation_type',
        'educational_paths.course_type',
      ],
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }

    if (!response?.data?.[0]) {
      return null;
    }

    const trainingCourseHttp = response.data[0];

    const included = toTrainingCourseIncluded(response?.included || []);

    const emptyUser = toUser({});

    return toTrainingCourse(
      trainingCourseHttp,
      {
        ...emptyUser,
        id: trainingCourseHttp.attributes.user_id?.toString() || '',
      },
      included,
    );
  }

  public async listStatus(): Promise<TrainingCourseStatus[]> {
    return new Promise((res) => {
      res([
        ...Object.values(TRAINING_COURSE_STATUSES),
        ...Object.values(TRAINING_ACTION_STATUSES),
      ]);
    });
  }

  public async listPriority(): Promise<TrainingCoursePriority[]> {
    return new Promise((res) => {
      res(Object.values(TRAINING_COURSE_PRIORITIES));
    });
  }

  public async upsertTrainingCourse(
    { id, ...trainingCourseWizard }: TrainingCourseWizard,
    user: User = toUser({}),
    isDraft = false,
  ): Promise<TrainingCourse | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<TrainingCourseWizard, 'id'> },
        { data: TrainingCourseHttp }
      >(`${this.trainingCourseEndpoint}${(isDraft && '/draft') || ''}`.trim(), {
        data: trainingCourseWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toTrainingCourse(data?.data || {}, user);
    }

    const { data, error } = await this.API?.patch<
      { data: TrainingCourseWizard },
      { data: TrainingCourseHttp }
    >(
      `${this.trainingCourseEndpoint}/${id}${
        (isDraft && '/draft') || ''
      }`.trim(),
      {
        data: { ...trainingCourseWizard, id },
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingCourse(data?.data || {}, toUser({}));
  }

  public async duplicateTrainingCourse({
    id,
  }: TrainingCourse): Promise<TrainingCourse | undefined> {
    const { data, error } = await this.API?.patch<
      {},
      { data: TrainingCourseHttp }
    >(`${this.trainingCourseEndpoint}/${String(id)}/duplicate`.trim(), {});

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingCourse(data?.data || {}, useStore.getState().user);
  }

  public async upsertTrainingCourseCandidates(
    { id: trainingCourseId, candidates }: TrainingCourse,
    isDraft = false,
  ): Promise<TrainingCourseCandidate[] | null> {
    const results = await Promise.all(
      candidates.map(({ id, ...candidate }) => {
        if (!id) {
          return this.API?.post<
            { data: Omit<TrainingCourseCandidateHttp, 'id'> },
            { data: TrainingCourseCandidateHttp }
          >(
            `/training-course-candidates${(isDraft && '/draft') || ''}`.trim(),
            {
              data: omit(
                toTrainingCourseCandidateHttp(candidate, trainingCourseId),
                'id',
              ),
            },
          );
        }

        return this.API?.patch<
          { data: TrainingCourseCandidateHttp },
          { data: TrainingCourseCandidateHttp }
        >(
          `/training-course-candidates/${id}${
            (isDraft && '/draft') || ''
          }`.trim(),
          {
            data: {
              ...toTrainingCourseCandidateHttp(candidate, trainingCourseId),
              id,
            },
          },
        );
      }),
    );

    return (!results.some(({ error }) => error) && candidates) || null;
  }

  public async upsertTrainingCourseEstimators({
    id: trainingCourseId,
    estimators,
  }: TrainingCourse): Promise<TrainingCourseEstimator[] | null> {
    const results = await Promise.all(
      estimators.map(({ id, ...estimator }) => {
        if (!id) {
          return this.API?.post<
            { data: Omit<TrainingCourseEstimatorWizard, 'id'> },
            {
              data: TrainingCourseEstimatorHttp;
              included: TrainingCourseRawIncluded;
            }
          >(
            '/training-course-estimators?include=approver,min_wage,opco_charge,device_type,validation_type,educational_costs,candidate_types,co_funders,educational_path,educational_path.training_course_trainings',
            {
              data: omit(
                toTrainingCourseEstimatorWizard(estimator, trainingCourseId),
                'id',
              ),
            },
          );
        }

        return this.API?.patch<
          { data: TrainingCourseEstimatorWizard },
          {
            data: TrainingCourseEstimatorHttp;
            included: TrainingCourseRawIncluded;
          }
        >(
          `/training-course-estimators/${id}?include=approver,min_wage,opco_charge,device_type,validation_type,educational_costs,candidate_types,co_funders,educational_path,educational_path.training_course_trainings`,
          {
            data: {
              ...toTrainingCourseEstimatorWizard(estimator, trainingCourseId),
              id,
            },
          },
        );
      }),
    );

    return (
      (!results.some(({ error }) => error) &&
        results.map((item) => {
          const { included: rawIncluded, data } = item.data || {
            data: {},
            included: [],
          };

          const included = toTrainingCourseIncluded(rawIncluded);
          return fromTrainingCourseEstimatorHttp(data, included);
        })) ||
      null
    );
  }

  public async upsertEducationCosts({
    id: estimatorId,
    educationalCosts,
  }: TrainingCourseEstimator): Promise<EducationalCost[] | null> {
    const results = await Promise.all(
      educationalCosts.map(({ id, ...educationalCost }) => {
        if (!id) {
          return this.API?.post<
            { data: Omit<EducationalCostWizard, 'id'> },
            { data: EducationalCostHttp }
          >('/estimator-educational-costs', {
            data: omit(
              toEducationalCostWizard(educationalCost, estimatorId),
              'id',
            ),
          });
        }

        return this.API?.patch<
          { data: EducationalCostWizard },
          { data: EducationalCostHttp }
        >(`/estimator-educational-costs/${id}`, {
          data: {
            ...toEducationalCostWizard(educationalCost, estimatorId),
            id,
          },
        });
      }),
    );

    return (
      (!results.some(({ error }) => error) &&
        results.map((item) => fromEduationalCostHttp(item.data?.data || {}))) ||
      null
    );
  }

  public async upsertCandidateTypes({
    id: estimatorId,
    candidateTypes,
  }: TrainingCourseEstimator): Promise<EstimatorCandidateType[] | null> {
    const results = await Promise.all(
      candidateTypes.map(({ id, ...candidateType }) => {
        if (!id) {
          return this.API?.post<
            { data: Omit<EstimatorCandidateTypeWizard, 'id'> },
            { data: EstimatorCandidateTypeHttp }
          >('/estimator-candidate-types?include=candidate_type', {
            data: omit(
              toEstimatorCandidateTypeWizard(candidateType, estimatorId),
              'id',
            ),
          });
        }

        return this.API?.patch<
          { data: EstimatorCandidateTypeWizard },
          { data: EstimatorCandidateTypeHttp }
        >(`/estimator-candidate-types/${id}?include=candidate_type`, {
          data: {
            ...toEstimatorCandidateTypeWizard(candidateType, estimatorId),
            id,
          },
        });
      }),
    );

    return (
      (!results.some(({ error }) => error) &&
        results.map((item) =>
          fromEstimatorCandidateTypeHttp(item.data?.data || {}),
        )) ||
      null
    );
  }

  public async upsertTrainingCourseCustomers(
    { id: trainingCourseId, customers }: TrainingCourse,
    isDraft = false,
  ): Promise<TrainingCourseCustomer[] | null> {
    const results = await Promise.all(
      customers.map(({ id, ...customer }) => {
        if (!id) {
          return this.API?.post<
            { data: Omit<TrainingCourseCustomerHttp, 'id'> },
            { data: TrainingCourseCustomerHttp }
          >(`/training-course-customers${(isDraft && '/draft') || ''}`.trim(), {
            data: omit(
              toTrainingCourseCustomerHttp(customer, trainingCourseId),
              'id',
            ),
          });
        }

        return this.API?.patch<
          { data: TrainingCourseCustomerHttp },
          { data: TrainingCourseCustomerHttp }
        >(
          `/training-course-customers/${id}${
            (isDraft && '/draft') || ''
          }`.trim(),
          {
            data: {
              ...toTrainingCourseCustomerHttp(customer, trainingCourseId),
              id,
            },
          },
        );
      }),
    );

    return (
      (!results.some(({ error }) => error) &&
        results.map((item) =>
          fromTrainingCourseCustomerHttp(item.data?.data || {}),
        )) ||
      null
    );
  }

  public async upsertTrainingCourseSessions(
    sessions: TrainingCourseSession[],
    trainingCourseId: string,
  ): Promise<TrainingCourseSession[] | null> {
    const results = await Promise.all(
      sessions.map(({ id, ...session }) => {
        if (!id) {
          return this.API?.post<
            { data: Omit<TrainingCourseSessionHttp, 'id'> },
            { data: TrainingCourseSessionHttp }
          >('/training-course-sessions?include=training_course_training', {
            data: omit(
              toTrainingCourseSessionHttp(session, trainingCourseId || ''),
              'id',
            ),
          });
        }

        return this.API?.patch<
          { data: TrainingCourseSessionHttp },
          { data: TrainingCourseSessionHttp }
        >(`/training-course-sessions/${id}?include=training_course_training`, {
          data: {
            ...toTrainingCourseSessionHttp(
              {
                ...session,
                id,
              },
              trainingCourseId || '',
            ),
            id,
          },
        });
      }),
    );

    return (
      (!results.some(({ error }) => error) &&
        results.map((item) =>
          toTrainingCourseSession(item.data?.data || {}),
        )) ||
      null
    );
  }

  public async deleteTrainingCourseCustomer({
    id,
  }: TrainingCourseCustomer): Promise<void> {
    this.API?.delete(`/training-course-customers/${id}`);
    return;
  }

  public async upsertTrainingCourseGroups(
    { id: trainingCourseId, groups }: TrainingCourse,
    isDraft = false,
  ): Promise<TrainingCourseGroup[] | null> {
    const results = await Promise.all(
      groups.map(({ id, ...group }, index) => {
        if (!id) {
          return this.API?.post<
            { data: Omit<TrainingCourseGroupHttp, 'id'> },
            { data: TrainingCourseGroupHttp }
          >(`/training-course-groups${(isDraft && '/draft') || ''}`.trim(), {
            data: omit(
              toTrainingCourseGroupHttp(group, index === 0, trainingCourseId),
              'id',
            ),
          });
        }

        return this.API?.patch<
          { data: TrainingCourseGroupHttp },
          { data: TrainingCourseGroupHttp }
        >(
          `/training-course-groups/${id}${(isDraft && '/draft') || ''}`.trim(),
          {
            data: {
              ...toTrainingCourseGroupHttp(
                group,
                index === 0,
                trainingCourseId,
              ),
              id,
            },
          },
        );
      }),
    );

    return (
      (!results.some(({ error }) => error) &&
        results.map((item) =>
          fromTrainingCourseGroupHttp(item.data?.data || {}),
        )) ||
      null
    );
  }

  public async updateManager(
    trainingCourse: TrainingCourse,
    managerId: string,
  ) {
    const { error } = await this.API?.patch<
      { data: { type: 'manager_repositories'; id: string } },
      { data: { type: 'manager_repositories'; id: string } }
    >(
      `${this.trainingCourseEndpoint}/${String(
        trainingCourse.id,
      )}/relationships/manager`,
      {
        data: { type: 'manager_repositories', id: managerId },
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }
    return;
  }

  public async lockTrainingCourse({
    id,
  }: TrainingCourse): Promise<TrainingCourse | undefined> {
    const { data, error } = await this.API?.patch<
      {},
      { data: TrainingCourseHttp }
    >(`${this.trainingCourseEndpoint}/${String(id)}/lock`.trim(), {});

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingCourse(data?.data || {}, toUser({}));
  }

  public async deleteTrainingCourse({ id }: TrainingCourse): Promise<void> {
    this.API?.delete(`${this.trainingCourseEndpoint}/${String(id)}`);
    return;
  }

  public async deleteTrainingCourseGroup({
    id,
  }: TrainingCourseGroup): Promise<void> {
    this.API?.delete(`/training-course-groups/${id}`);
    return;
  }

  public async deleteTrainingCourseTraining({
    id,
  }: TrainingCourseTraining): Promise<void> {
    this.API?.delete(`/training-course-trainings/${id}`);
    return;
  }

  public async deleteTrainingCourseEstimator({
    id,
  }: TrainingCourseEstimator): Promise<void> {
    this.API?.delete(`/training-course-estimators/${id}`);
    return;
  }

  public async deleteTrainingCourseCandidate({
    id,
  }: TrainingCourseCandidate): Promise<void> {
    this.API?.delete(`/training-course-candidates/${id}`);
    return;
  }

  public async deleteTrainingCourseSession({
    id,
  }: TrainingCourseSession): Promise<void> {
    this.API?.delete(`/training-course-sessions/${id}`);
    return;
  }

  public async deleteEstimatorCandidateType({
    id,
  }: EstimatorCandidateType): Promise<void> {
    this.API?.delete(`/estimator-candidate-types/${id}`);
    return;
  }
}

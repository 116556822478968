import { toApiDate } from '@data/dto/api.dto';
import { TrainingActionEstimatorCandidate } from '@data/models/training-action-estimator-candidate.interface';
import {
  TrainingActionTrainingCandidateInvoice,
  TrainingActionTrainingCandidateInvoiceHttp,
  TrainingActionTrainingCandidateInvoiceType,
  TrainingActionTrainingCandidateInvoiceWizard,
} from '@data/models/training-action-training-candidate-invoice.interface';
import dayjs from 'dayjs';

import { toApiFloat, toBoolean } from './api.dto';

export const toTrainingActionTrainingCandidateInvoice = ({
  relationships,
  attributes,
  id,
}: Partial<TrainingActionTrainingCandidateInvoiceHttp>): TrainingActionTrainingCandidateInvoice => {
  return {
    id: id || '',
    trainingCourseTrainingId:
      relationships?.training_course_training?.data?.id || '',
    reference: attributes?.reference || '',
    fromDate: dayjs(attributes?.from_date || ''),
    toDate: dayjs(attributes?.to_date || ''),
    type:
      attributes?.invoice_type ||
      TrainingActionTrainingCandidateInvoiceType.Individual,
    isHourCost: toBoolean(attributes?.is_hour_cost),
    hourRate: toApiFloat(attributes?.hour_rate),
    numberHours: toApiFloat(attributes?.hours),
    totalAmount: toApiFloat(attributes?.total_amount),
    totalAmountPerCandidate: toApiFloat(attributes?.total_amount_per_candidate),
    createdAt: dayjs(attributes?.createdAt || ''),
    updatedAt: dayjs(attributes?.updatedAt || ''),
  };
};

export const toTrainingActionTrainingCandidateInvoiceWizard = (
  invoice: Partial<TrainingActionTrainingCandidateInvoice>,
  estimatorCandidate: Partial<TrainingActionEstimatorCandidate>,
): TrainingActionTrainingCandidateInvoiceWizard => {
  return {
    id: invoice?.id || '',
    type: 'training_action_invoices',
    attributes: {
      reference: invoice?.reference || '',
      from_date: toApiDate(invoice.fromDate),
      to_date: toApiDate(invoice.toDate),
      invoice_type:
        invoice?.type || TrainingActionTrainingCandidateInvoiceType.Individual,
      is_hour_cost: invoice.isHourCost || false,
      hour_rate: toApiFloat(invoice?.hourRate),
      hours: toApiFloat(invoice?.numberHours),
      total_amount: toApiFloat(invoice?.totalAmount),
      total_amount_per_candidate: toApiFloat(invoice?.totalAmountPerCandidate),
    },
    relationships: {
      estimator_candidate: {
        data: {
          id: estimatorCandidate?.id || '',
          type: 'estimator_candidates',
        },
      },
      training_course_training: {
        data: {
          id: invoice.trainingCourseTrainingId || '',
          type: 'training_course_trainings',
        },
      },
    },
  };
};

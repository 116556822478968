import useStore from '@stores/store';
import { SnackbarProvider } from 'notistack';
import { useShallow } from 'zustand/react/shallow';

import Toast from './components/Toast';
import './style.scss';

const ToastList = (): JSX.Element => {
  const { toast } = useStore(
    useShallow((state) => ({
      toast: state.toast,
    })),
  );

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        variantSuccess: 'toast success',
        variantError: 'toast error',
        variantWarning: 'toast warning',
        variantInfo: 'toast info',
      }}
    >
      {toast.map((toast) => (
        <Toast toast={toast} key={toast.id} />
      ))}
    </SnackbarProvider>
  );
};

export default ToastList;

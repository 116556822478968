import { PropsWithChildren } from 'react';

import { useMaterialThemeTokens } from '@groupeactual/design-tokens';
import { DesignSystemProvider } from '@groupeactual/ui-kit';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const DesignSystemWrapper = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { muiTokens } = useMaterialThemeTokens('Default');
  const theme = createTheme(muiTokens);

  return (
    <DesignSystemProvider>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </DesignSystemProvider>
  );
};

export default DesignSystemWrapper;

import { CoFunder } from './co-funder.interface';
import { DeviceType } from './device-type.interface';
import { Manager } from './manager.interface';
import { MinWage } from './min-wage.interface';
import { OpcoCharge } from './opco-charge.interface';
import { RELIEF_TYPE } from './relief-type.interface';
import { TrainingCourseEducationalPath } from './training-course-educational-path.interface';
import { TrainingCourseTrainingInvoiceType } from './training-course-training.interface';
import { ValidationType } from './validation-type.interface';

export enum TrainingCourseEstimatorStatus {
  DRAFT_STATUS = 'DRAFT_STATUS',
  REQUEST_VALIDATION_STATUS = 'REQUEST_VALIDATION_STATUS',
  VALIDATE_STATUS = 'VALIDATE_STATUS',
}

export interface EstimatorCandidateTypeHttp {
  attributes: {
    label: string;
    quantity: number;
    position: number;
    pec_rate: string;
  };
  id: string;
  type: 'estimator_candidate_types';
  relationships: {
    training_course_estimator: {
      data: {
        type: 'training_course_estimators';
        id: string;
      };
    };
    candidate_type: {
      data?: {
        type: 'candidate_type_repositories';
        id: string;
      };
    };
  };
}

export interface EstimatorCandidateTypeWizard {
  attributes: {
    label: string;
    quantity: number;
    position: number;
    pec_rate: string;
  };
  id: string;
  type: 'estimator_candidate_types';
  relationships: {
    training_course_estimator: {
      data: {
        id: string;
        type: 'training_course_estimators';
      };
    };
    candidate_type: {
      data?: {
        type: 'candidate_type_repositories';
        id: string;
      };
    };
  };
}

export interface EstimatorCandidateType {
  id: string;
  label: string;
  quantity: number;
  position: number;
  pecRate: number;
  candidateTypeId: string;
}

export interface EducationalCostHttp {
  attributes: {
    organization_name: string;
    duration: string;
    amount: string;
    total: string;
    invoice_type: TrainingCourseTrainingInvoiceType;
    createdAt: string;
    updatedAt: string;
  };
  id: string;
  type: 'estimator_educational_costs';
  relationships: {
    training_course_estimator: {
      links: {
        related: string;
        self: string;
      };
    };
  };
}

export interface EducationalCostWizard {
  attributes: {
    organization_name: string;
    duration: string;
    amount: string;
    total: string;
    invoice_type: TrainingCourseTrainingInvoiceType;
  };
  id: string;
  type: 'estimator_educational_costs';
  relationships: {
    training_course_estimator: {
      data: {
        id: string;
        type: 'training_course_estimators';
      };
    };
  };
}

export interface EducationalCost {
  id: string;
  organizationName: string;
  duration: number;
  label: string;
  amount: number;
  invoiceType: TrainingCourseTrainingInvoiceType;
  total: number;
}

export interface TrainingCourseEstimatorHttp {
  type: 'training_course_estimators';
  id: string;
  attributes: {
    name: string;
    status: TrainingCourseEstimatorStatus;
    candidates_number: number;
    positioning_review_duration: string;
    positioning_review_cost: string;
    positioning_review_organization_name: string | null;
    educational_costs_min_duration: string;
    educational_costs_max_duration: string;
    educational_costs_total_duration: string;
    educational_costs_amount_per_candidate: string;
    educational_costs_amount_per_hour: string;
    educational_costs_practical_duration: string;
    has_additional_costs: number;
    additional_costs_total_amount: string;
    mission_average_rate: string;
    training_course_average_rate: string;
    classic_trainee_number: number;
    classic_trainee_pec: string;
    local_mission_trainee_number: number;
    local_mission_trainee_pec: string;
    handicapped_trainee_number: number;
    handicapped_trainee_pec: string;
    refugee_trainee_number: number;
    refugee_trainee_pec: string;
    skilled_trainee_number: number;
    skilled_trainee_pec: string;
    poec_trainee_number: number;
    poec_trainee_pec: string;
    afpr_trainee_number: number;
    afpr_trainee_pec: string;
    senior_trainee_number: number;
    a2i_trainee_number: number;
    cdii_trainee_number: number;
    average_pec_rate: string;
    is_national_need: number;
    cpf_amount: string;
    ddc_duration_in_months: string;
    ddc_total_mission_duration_in_months: string;
    ddc_training_duration_per_month: string;
    ddc_complementary_training_duration_in_months: string;
    ddc_training_duration_in_days: string;
    ddc_training_duration_in_hours: string;
    ddc_is_valid_mission_duration: number;
    ddc_training_mission_ratio: string;
    has_external_co_funding: number;
    external_co_funding_supported_amount: string;
    has_financial_aid: number;
    financial_aid_supported_amount: string;
    customer_name: null;
    customer_management_rate: string;
    customer_delegation_rate: string;
    delegation_rate: string;
    rfa_rate: string;
    sourcing_amount: string;
    relief_type: string;
    re_invoicing_cost_rate: string;
    re_invoicing_cost_amount: string;
    re_invoicing_wage_amount: string;
    re_invoicing_wage_rate: string;
    re_invoicing_extra_cost_rate: string;
    re_invoicing_extra_cost_amount: string;
    engineering_cost: string;
    recruitment_cost: string;
    management_fees: string;
    total_invoice_amount: string;
    additional_delegation_in_months: string;
    average_coefficient_on_mission_hours: string;
    delegation_billing_amount: string;
    delegation_billing_post_training_amount: string;
    delegation_billing_post_training_hours: string;
    average_coefficient_on_mission_and_training_hours: string;
    coefficient_delegation_evolution: string;
    total_training_expenses_per_trainee: string;
    total_training_expenses_per_group: string;
    total_training_revenue_per_trainee: string;
    total_training_revenue_per_group: string;
    opco_refund: string;
    training_assessment_amount: string;
    external_training_amount: string;
    practical_training_amount: string;
    turn_over_per_trainee: string;
    turn_over_per_group: string;
    turn_over_in_months_per_trainee: string;
    turn_over_in_months_per_group: string;
    gross_margin_per_trainee_with_end: string;
    gross_margin_per_trainee_without_end: string;
    gross_margin_target_amount: string;
    gross_margin_in_months_per_trainee_with_end: string;
    gross_margin_in_months_per_trainee_without_end: string;
    gross_margin_rate_with_end: string;
    gross_margin_rate_without_end: string;
    gross_margin_rate_against_extra_cost_with_end: string;
    gross_margin_rate_against_extra_cost_without_end: string;
    total_gross_margin: string;
    training_cost_with_cofi_amount: string;
    training_cost_without_cofi_amount: string;
    total_mission_revenue: string;
    total_training_relief_cost: string;
    total_mission_relief_cost: string;
    etti_agreement_amount: string;
    etti_agreement_post_training_amount: string;
    ifm_gains_amount: string;
    ifm_gains_post_training_amount: string;
    total_post_training_revenue: string;
    total_post_training_relief_cost: string;
    mission_wage_expenses: string;
    mission_rfa_expenses: string;
    mission_total_expenses: string;
    post_training_wage_expenses: string;
    post_training_rfa_expenses: string;
    post_training_total_expenses: string;
    invoice_rate_against_extra_costs: string;
    break_even_with_end_amount: string;
    break_even_without_end_amount: string;
    total_wages_amount: string;
    total_mission_duration_in_months: string;
    educational_costs_amount_group: string;
  };
  relationships: {
    training_course: {
      links: {
        related: string;
        self: string;
      };
    };
    educational_costs: {
      data?: {
        type: 'estimator_educational_costs';
        id: string;
      }[];
    };
    educational_path: {
      data: {
        type: 'training_course_educational_paths';
        id: string;
      };
    };
    candidate_types: {
      data?: {
        type: 'estimator_candidate_types';
        id: string;
      }[];
    };
    min_wage: {
      data?: {
        type: 'min_wage_repositories';
        id: string;
      };
    };
    opco_charge: {
      data?: {
        type: 'opco_charge_repositories';
        id: string;
      };
    };
    validation_type: {
      data?: {
        type: 'validation_type_repositories';
        id: string;
      };
    };
    device_type: {
      data?: {
        type: 'device_type_repositories';
        id: string;
      };
    };
    approver: {
      data?: {
        type: 'manager_repositories';
        id: string;
      };
    };
    co_funders: {
      data?: {
        type: 'co_funder_repositories';
        id: string;
      }[];
    };
  };
  links: {
    self: string;
  };
}
export interface TrainingCourseEstimator {
  id: string;
  name: string;
  candidatesNumber: number;
  organizationName: string;
  positioningReviewDuration: number;
  positioningReviewCost: number;
  educationalCosts: EducationalCost[];
  candidateTypes: EstimatorCandidateType[];
  educationalPath: TrainingCourseEducationalPath;
  educationalCostMinDuration: number;
  educationalCostMaxDuration: number;
  educationalCostTotalDuration: number;
  educationalCostAmountPerCandidate: number;
  educationalCostAmountGroup: number;
  educationalCostAmountPerHour: number;
  educationalCostPraticalDuration: number;
  hasAdditionalCosts: boolean;
  additionalCostsAmount: number;
  missionAverageRate: number;
  trainingCourseAverageRate: number;
  averagePECRate: number;
  deviceType: DeviceType;
  isNationalNeed: boolean;
  seniorTraineeNumber: number;
  A2ITraineeNumber: number;
  CDIITraineeNumber: number;
  CPFAmount: number;
  totalMissionDurationInMonths: number;
  ddcTrainingDurationInMonths: number;
  ddcComplementaryTrainingDuration: number;
  ddcTrainingDurationPerMonth: number;
  ddcTrainingMissionDurationInHours: number;
  ddcTrainingMissionDurationInDays: number;
  ddcTrainingMissionDurationInMonths: number;
  isValidMissionDuration: boolean | null;
  trainingMissionRatio: number | null;
  hasExternalCoFunding: boolean;
  externalCoFundingSupportedAmount: number;
  hasFinancialAid: boolean;
  financialAidSupportedAmount: number;
  customersName: string;
  customersManagementRate: number;
  customerDelegationRate: number;
  delegationRate: number;
  RFARate: number;
  reliefType: RELIEF_TYPE;
  sourcingAmount: number;
  reInvoicingCostRate: number;
  reInvoicingCostAmount: number;
  reInvoicingWageRate: number;
  reInvoicingWageAmount: number;
  reInvoicingExtraCostRate: number;
  reInvoicingExtraCostAmount: number;
  engineeringCostAmount: number;
  recruitmentAmount: number;
  managementFeesAmount: number;
  totalInvoiceAmount: number;
  additionalDelegationInMonths: number;
  minWage: MinWage;
  opcoCharge: OpcoCharge;
  validationType: ValidationType;
  totalWagesAmount: number;
  averageCoefficientOnMissionHours: number;
  delegationBillingAmount: number;
  delegationBillingPostTrainingAmount: number;
  averageCoefficientOnMissionAndTrainingHours: number;
  coefficientDelegationEvolution: number;
  totalTrainingExpensesPerTrainee: number;
  totalTrainingExpensesPerGroup: number;
  totalTrainingRevenuePerTrainee: number;
  totalTrainingRevenueGroup: number;
  opcoRefund: number;
  trainingAssessmentAmount: number;
  externalTrainingAmount: number;
  practicalTrainingAmount: number;
  turnOverPerTrainee: number;
  turnOverPerGroup: number;
  turnOverInMonthsPerTrainee: number;
  turnOverInMonthsPerGroup: number;
  grossMarginPerTraineeWithEnd: number;
  trainingCostWithCofiAmount: number;
  totalMissionRevenue: number;
  totalTrainingReliefCost: number;
  totalMissionReliefCost: number;
  agreementETTIAmount: number;
  agreementETTIPostTrainingAmount: number;
  ifmGainsAmount: number;
  ifmGainsPostTrainingAmount: number;
  totalPostTrainingRevenue: number;
  totalPostTrainingReliefCost: number;
  missionWageExpenses: number;
  missionRFAExpenses: number;
  totalMissionExpenses: number;
  postTrainingWageExpenses: number;
  postTrainingRFAExpenses: number;
  totalPostTrainingExpenses: number;
  grossMarginPerTraineeWithoutEnd: number;
  trainingCostWithoutCOFIAmount: number;
  grossMarginTargetAmount: number;
  grossMarginInMonthsPerTraineeWithEnd: number;
  grossMarginInMonthsPerTraineeWithoutEnd: number;
  grossMarginRateWithEnd: number;
  grossMarginRateWithoutEnd: number;
  grossMarginRateAgainstExtraCostWithEnd: number;
  grossMarginRateAgainstExtraCostWithoutEnd: number;
  invoiceRateAgainstExtraCost: number;
  breakEvenWithEndAmount: number;
  breakEvenWithoutEndAmount: number;
  totalGrossMargin: number;
  status: TrainingCourseEstimatorStatus;
  approver: Manager;
  classicTraineeNumber: number;
  localMissionTraineeNumber: number;
  handicappedTraineeNumber: number;
  refugeeTraineeNumber: number;
  skillTraineeNumber: number;
  POECTraineeNumber: number;
  AFPRTraineeNumber: number;
  coFunders: CoFunder[];
}

export interface TrainingCourseEstimatorWizard {
  type: 'training_course_estimators';
  id: string;
  attributes: {
    name: string;
    status: TrainingCourseEstimatorStatus;
    total_wages_amount: string;
    candidates_number: number;
    positioning_review_duration: string;
    positioning_review_cost: string;
    positioning_review_organization_name: string | null;
    educational_costs_min_duration: string;
    educational_costs_max_duration: string;
    educational_costs_total_duration: string;
    educational_costs_amount_per_candidate: string;
    educational_costs_amount_per_hour: string;
    educational_costs_practical_duration: string;
    has_additional_costs: boolean;
    additional_costs_total_amount: string;
    mission_average_rate: string;
    training_course_average_rate: string;
    senior_trainee_number: number;
    a2i_trainee_number: number;
    cdii_trainee_number: number;
    average_pec_rate: string;
    is_national_need: boolean;
    cpf_amount: string;
    ddc_duration_in_months: string;
    ddc_total_mission_duration_in_months: string;
    ddc_training_duration_per_month: string;
    ddc_complementary_training_duration_in_months: string;
    ddc_training_duration_in_days: string;
    ddc_training_duration_in_hours: string;
    ddc_is_valid_mission_duration: boolean;
    ddc_training_mission_ratio: string;
    has_external_co_funding: boolean;
    external_co_funding_supported_amount: string;
    has_financial_aid: boolean;
    financial_aid_supported_amount: string;
    customer_name: string | null;
    customer_management_rate: string;
    customer_delegation_rate: string;
    delegation_rate: string;
    rfa_rate: string;
    sourcing_amount: string;
    relief_type: string;
    re_invoicing_cost_rate: string;
    re_invoicing_cost_amount: string;
    re_invoicing_wage_amount: string;
    re_invoicing_wage_rate: string;
    re_invoicing_extra_cost_rate: string;
    re_invoicing_extra_cost_amount: string;
    engineering_cost: string;
    recruitment_cost: string;
    management_fees: string;
    total_invoice_amount: string;
    additional_delegation_in_months: string;
    average_coefficient_on_mission_hours: string;
    delegation_billing_amount: string;
    delegation_billing_post_training_amount: string;
    delegation_billing_post_training_hours: string;
    average_coefficient_on_mission_and_training_hours: string;
    coefficient_delegation_evolution: string;
    total_training_expenses_per_trainee: string;
    total_training_expenses_per_group: string;
    total_training_revenue_per_trainee: string;
    total_training_revenue_per_group: string;
    opco_refund: string;
    training_assessment_amount: string;
    external_training_amount: string;
    practical_training_amount: string;
    turn_over_per_trainee: string;
    turn_over_per_group: string;
    turn_over_in_months_per_trainee: string;
    turn_over_in_months_per_group: string;
    gross_margin_per_trainee_with_end: string;
    gross_margin_per_trainee_without_end: string;
    gross_margin_target_amount: string;
    gross_margin_in_months_per_trainee_with_end: string;
    gross_margin_in_months_per_trainee_without_end: string;
    gross_margin_rate_with_end: string;
    gross_margin_rate_without_end: string;
    gross_margin_rate_against_extra_cost_with_end: string;
    gross_margin_rate_against_extra_cost_without_end: string;
    total_gross_margin: string;
    training_cost_with_cofi_amount: string;
    training_cost_without_cofi_amount: string;
    total_mission_revenue: string;
    total_training_relief_cost: string;
    total_mission_relief_cost: string;
    etti_agreement_amount: string;
    etti_agreement_post_training_amount: string;
    ifm_gains_amount: string;
    ifm_gains_post_training_amount: string;
    total_post_training_revenue: string;
    total_post_training_relief_cost: string;
    mission_wage_expenses: string;
    mission_rfa_expenses: string;
    mission_total_expenses: string;
    post_training_wage_expenses: string;
    post_training_rfa_expenses: string;
    post_training_total_expenses: string;
    invoice_rate_against_extra_costs: string;
    break_even_with_end_amount: string;
    break_even_without_end_amount: string;
    total_mission_duration_in_months: string;
    educational_costs_amount_group: string;
    classic_trainee_number: number;
    classic_trainee_pec: string;
    local_mission_trainee_number: number;
    local_mission_trainee_pec: string;
    handicapped_trainee_number: number;
    handicapped_trainee_pec: string;
    refugee_trainee_number: number;
    refugee_trainee_pec: string;
    skilled_trainee_number: number;
    skilled_trainee_pec: string;
    poec_trainee_number: number;
    poec_trainee_pec: string;
    afpr_trainee_number: number;
    afpr_trainee_pec: string;
  };
  relationships: {
    training_course: {
      data: {
        type: 'training_courses';
        id: string;
      };
    };
    educational_path?: {
      data: {
        type: 'training_course_educational_paths';
        id: string;
      };
    };
    min_wage: {
      data: {
        type: 'min_wage_repositories';
        id: string;
      };
    };
    opco_charge: {
      data: {
        type: 'opco_charge_repositories';
        id: string;
      };
    };
    validation_type?: {
      data: {
        type: 'validation_type_repositories';
        id: string;
      };
    };
    device_type?: {
      data?: {
        type: 'device_type_repositories';
        id: string;
      };
    };
    co_funders: {
      data?: {
        type: 'co_funder_repositories';
        id: string;
      }[];
    };
  };
}

import { Group, GroupHttp } from '@data/models/group.interface';
import { Dayjs } from 'dayjs';

export const USER_STORAGE = 'USER_STORAGE';

export interface UserHttp {
  type: 'manager_repositories';
  id: string;
  attributes: {
    id: number;
    avatar: string;
    createdAt: string;
    email: string;
    first_name: string;
    hierarchy: number;
    initials: string;
    last_connection: string | null;
    last_name: string;
    other_role: number | null;
    position: number;
    role: number;
    job: string;
    permissions: string[];
    groups: GroupHttp[];
    service: string;
    title: string;
    manager_repository_id: string;
    regions: string[];
    updatedAt: string;
    emulated_by: string | null;
  };
}

export interface User {
  id: string;
  avatar: string;
  email: string;
  fullName: string;
  firstName: string;
  hierarchy: number;
  initials: string;
  lastConnection: Dayjs | null;
  lastName: string;
  otherRole: number | null;
  role: number;
  job: string;
  permissions: string[];
  groups: Group[];
  division: Group | null;
  service: string;
  title: string;
  managerId: string;
  isAuthenticated: boolean;
  regions: string[];
  createdAt: Dayjs;
  updatedAt: Dayjs;
  emulatedBy: string | null;
}

import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface Props {
  columns: number;
  rows: number;
}

const TableSkeleton = ({ columns, rows }: Props): JSX.Element => {
  return (
    <TableContainer component={Paper} sx={{ mt: 5 }}>
      <Table>
        <TableHead>
          <TableRow>
            {Array.from(Array(columns).keys()).map((i) => (
              <TableCell key={i}>
                <Skeleton variant="rectangular" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(Array(rows).keys()).map((i) => (
            <TableRow key={i}>
              {Array.from(Array(columns).keys()).map((i) => (
                <TableCell key={i}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;

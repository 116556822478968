/* eslint-disable no-unsafe-optional-chaining */
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toSortParams } from '@data/dto/api.dto';
import { toCoFunder } from '@data/dto/co-funder.dto';
import { Meta, PaginateResult, SortParams } from '@data/models/api.interface';
import {
  CoFunder,
  CoFunderHttp,
  CoFunderWizard,
} from '@data/models/co-funder.interface';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import { FilterParams } from '@data/models/filter.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class CoFunderService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly coFunderEndpoint = '/co-funder-repositories';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listCoFunders(
    filters: FilterParams,
  ): Promise<PaginateResult<CoFunder[]>> {
    const { data: response, error } = await this.API?.get<{
      data: CoFunderHttp[];
      meta: Meta;
    }>(this.coFunderEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items: response?.data?.map(toCoFunder) || [],
      totalSize: response?.meta.page.total || 0,
    };
  }

  public async upsertCoFunder({
    id,
    ...coFunderWizard
  }: CoFunderWizard): Promise<CoFunder | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<CoFunderWizard, 'id'> },
        { data: CoFunderHttp }
      >(this.coFunderEndpoint, { data: coFunderWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toCoFunder(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: CoFunderWizard },
      { data: CoFunderHttp }
    >(`${this.coFunderEndpoint}/${id}`, {
      data: { ...coFunderWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }
    return toCoFunder(data?.data || {});
  }

  public async deleteCoFunder(id: string): Promise<CoFunder | void> {
    if (!id) {
      throw new Error('Id is missing');
    }

    const { data, error } = await this.API?.delete<{ data: CoFunderHttp }>(
      `${this.coFunderEndpoint}/${id}`,
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toCoFunder(data?.data || {});
  }

  public async sortCoFunder(
    sortParams: DatatableReorderParams<CoFunder>,
  ): Promise<CoFunder | void> {
    const {
      current: { id },
    } = sortParams;

    if (!id) {
      throw new Error('Id is missing');
    }
    const { error } = await this.API?.post<{ data: SortParams }, {}>(
      `${this.coFunderEndpoint}/${id}/sort`,
      {
        data: toSortParams('co_funder_repositories', sortParams),
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return sortParams.current;
  }
}

import * as React from 'react';
import Konami from 'react-konami-code';

import useStore from '@stores/store';
import { useShallow } from 'zustand/react/shallow';

import { Title, Wrapper } from './Minesweeper/AppStyle';
import {
  BoardContainer,
  SettingsContainer,
  StatusContainer,
} from './Minesweeper/containers';

const KonamiComponent = (): JSX.Element => {
  const { openModal } = useStore(
    useShallow((state) => ({
      openModal: state.openModal,
    })),
  );
  const easterEgg = () =>
    openModal({
      title: 'Démineur',
      component: (
        <Wrapper>
          <Title>Minesweeper by Digital Factory</Title>
          <SettingsContainer />
          <StatusContainer />
          <BoardContainer />
        </Wrapper>
      ),
    });
  return (
    <Konami action={easterEgg}>
      <div style={{ display: 'none' }}>
        Congratulation <br />
        You find the konomi code !! <br />
        Now, press keys to play : ↑ ↑ ↓ ↓ ← → ← → B A
      </div>
    </Konami>
  );
};

export default KonamiComponent;

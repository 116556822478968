import { TrainingActionEstimatorCandidate } from '@data/models/training-action-estimator-candidate.interface';
import { TrainingAction } from '@data/models/training-action.interface';
import compact from 'lodash/compact';
import { StateCreator } from 'zustand';

const isTrainingActionEstimatorCandidate = (
  estimatorCandidate:
    | TrainingActionEstimatorCandidate
    | TrainingActionEstimatorCandidate[]
    | undefined,
): estimatorCandidate is TrainingActionEstimatorCandidate => {
  return !Array.isArray(estimatorCandidate);
};

export interface TrainingActionState {
  showTrainingActions: TrainingAction[];
  trainingActionEstimatorCandidates: TrainingActionEstimatorCandidate[];
  addShowTrainingAction: (trainingAction: TrainingAction) => void;
  updateShowTrainingAction: (trainingAction: TrainingAction) => void;
  revertTrainingAction: (trainingAction: TrainingAction) => void;
  findTrainingActionByRef: (reference: string) => TrainingAction | undefined;
  setTrainingActionEstimatorCandidate: (
    estimatorCandidates: TrainingActionEstimatorCandidate[],
  ) => void;
  updateTrainingActionEstimatorCandidate: (
    estimatorCandidate: TrainingActionEstimatorCandidate,
  ) => void;
}

export const createTrainingActionSlice: StateCreator<
  TrainingActionState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  TrainingActionState
> = (set, get) => ({
  showTrainingActions: [],
  trainingActionEstimatorCandidates: [],
  addShowTrainingAction: (trainingAction: TrainingAction) =>
    set((state) => ({
      showTrainingActions: [...state.showTrainingActions, trainingAction],
    })),
  updateShowTrainingAction: (trainingAction: TrainingAction) => {
    set((state) => ({
      showTrainingActions: state.showTrainingActions.map(
        (ta) => (ta.id === trainingAction.id && trainingAction) || ta,
      ),
    }));
  },
  revertTrainingAction: (trainingAction: TrainingAction) => {
    set((state) => ({
      showTrainingActions: compact(
        state.showTrainingActions.map(
          (ta) => (ta.id !== trainingAction.id && ta) || undefined,
        ),
      ),
    }));
  },
  findTrainingActionByRef: (reference: string) =>
    get().showTrainingActions.find(
      ({ trainingCourseRef }) => trainingCourseRef === reference,
    ),
  setTrainingActionEstimatorCandidate: (
    estimatorCandidates: TrainingActionEstimatorCandidate[],
  ) => {
    if (isTrainingActionEstimatorCandidate(estimatorCandidates)) {
      return;
    }
    set(() => ({ trainingActionEstimatorCandidates: estimatorCandidates }));
  },
  updateTrainingActionEstimatorCandidate: (
    estimatorCandidate: TrainingActionEstimatorCandidate,
  ) => {
    set((state) => {
      const updatedCandidates = state.trainingActionEstimatorCandidates.map(
        (candidate) =>
          candidate.id === estimatorCandidate.id
            ? estimatorCandidate
            : candidate,
      );

      if (
        !updatedCandidates.find(
          (candidate) => candidate.id === estimatorCandidate.id,
        )
      ) {
        updatedCandidates.push(estimatorCandidate);
      }

      return { trainingActionEstimatorCandidates: updatedCandidates };
    });
  },
});

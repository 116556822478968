import dayjs from '@_plugins/dayjs';
import { toApiDate } from '@data/dto/api.dto';
import {
  ResultTypes,
  TrainingActionCandidateTraining,
  TrainingActionCandidateTrainingHttp,
  TrainingActionCandidateTrainingWizard,
} from '@data/models/training-action-candidate-training.interface';
import { enumFromStringValue } from '@helpers/enum.helper';

// eslint-disable-next-line complexity
export const toTrainingActionCandidateTraining = ({
  attributes,
  id,
  relationships,
}: Partial<TrainingActionCandidateTrainingHttp>): TrainingActionCandidateTraining => {
  return {
    id: id || '',
    label: attributes?.label || '',
    resultType: enumFromStringValue(ResultTypes, attributes?.result_type || ''),
    validatedAt: attributes?.validated_at
      ? dayjs(attributes?.validated_at)
      : null,
    createdAt: attributes?.createdAt ? dayjs(attributes?.createdAt) : null,
    updatedAt: attributes?.updatedAt
      ? dayjs(attributes?.updatedAt || '')
      : null,
    trainingCourseTrainingId:
      relationships?.training_course_training?.data?.id || '',
    trainingActionCandidateId:
      relationships?.training_action_candidate?.data?.id || '',
  };
};

export const toTrainingActionCandidateTrainingWizard = ({
  id,
  label,
  resultType,
  validatedAt,
  trainingActionCandidateId,
  trainingCourseTrainingId,
}: TrainingActionCandidateTraining): TrainingActionCandidateTrainingWizard => {
  return {
    type: 'candidate_trainings',
    id,
    attributes: {
      label,
      result_type: resultType as string,
      validated_at: toApiDate(validatedAt),
    },
    relationships: {
      training_course_training: {
        data: {
          type: 'training_course_trainings',
          id: trainingCourseTrainingId || '',
        },
      },
      training_action_candidate: {
        data: {
          type: 'training_action_candidates',
          id: trainingActionCandidateId || '',
        },
      },
    },
  };
};

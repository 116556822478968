import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { IconProvider } from '@groupeactual/ui-kit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import useStore from '@stores/store';
import { useShallow } from 'zustand/react/shallow';

const Modal = (): JSX.Element => {
  const { modal, closeModal } = useStore(
    useShallow((state) => ({
      modal: state.modal,
      closeModal: state.closeModal,
    })),
  );

  if (modal.type === 'dialog') {
    return (
      <Dialog
        open={modal.show}
        onClose={() => closeModal()}
        {...modal.modalProps}
      >
        <DialogTitle>{modal.title}</DialogTitle>
        <DialogContent dividers>{modal.component}</DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer
      disableEnforceFocus
      anchor="right"
      open={modal.show}
      onClose={() => closeModal()}
    >
      <Card
        elevation={0}
        sx={{
          height: '100%',
          minWidth: 500,
          overflowY: 'scroll',
        }}
      >
        {modal.withHeader && (
          <CardHeader
            action={
              <IconButton aria-label="settings" onClick={() => closeModal()}>
                <IconProvider icon={faClose} />
              </IconButton>
            }
            title={modal.title}
            subheader=""
          />
        )}
        <CardContent
          sx={{
            height: (modal.withHeader && 'calc(100% - 80px)') || '100%',
          }}
        >
          {modal.component}
        </CardContent>
      </Card>
    </Drawer>
  );
};

export default Modal;

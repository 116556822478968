import { Document } from '@data/models/document.interface';
import { StateCreator } from 'zustand';

export interface DocumentToQualifyState {
  documentsToQualify: Document[];
  setDocumentsToQualify: (documents: Document[]) => void;
}

export const createDocumentToQualifySlice: StateCreator<
  DocumentToQualifyState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  DocumentToQualifyState
> = (set) => ({
  documentsToQualify: [],
  setDocumentsToQualify: (documentsToQualify: Document[]) =>
    set(() => ({ documentsToQualify })),
});

/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toSortParams } from '@data/dto/api.dto';
import { toCandidateType } from '@data/dto/candidate-type.dto';
import { Meta, PaginateResult, SortParams } from '@data/models/api.interface';
import {
  CandidateType,
  CandidateTypeHttp,
  CandidateTypeWizard,
} from '@data/models/candidate-type.interface';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import { FilterParams } from '@data/models/filter.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class CandidateTypeService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly candidateTypeEndpoint = '/candidate-type-repositories';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listCandidateTypes(
    filters: FilterParams,
  ): Promise<PaginateResult<CandidateType[]>> {
    const { data: response, error } = await this.API?.get<{
      data: CandidateTypeHttp[];
      meta: Meta;
    }>(this.candidateTypeEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items: response?.data?.map(toCandidateType) || [],
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async upsertCandidateType({
    id,
    ...candidateTypeWizard
  }: CandidateTypeWizard): Promise<CandidateType | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<CandidateTypeWizard, 'id'> },
        { data: CandidateTypeHttp }
      >(this.candidateTypeEndpoint, { data: candidateTypeWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toCandidateType(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: CandidateTypeWizard },
      { data: CandidateTypeHttp }
    >(`${this.candidateTypeEndpoint}/${id}`, {
      data: { ...candidateTypeWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toCandidateType(data?.data || {});
  }

  public async deleteCandidateType(id: string): Promise<CandidateType | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: CandidateTypeHttp;
    }>(`${this.candidateTypeEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toCandidateType(data?.data || {});
  }

  public async sortCandidateType(
    sortParams: DatatableReorderParams<CandidateType>,
  ): Promise<CandidateType | void> {
    const {
      current: { id },
    } = sortParams;

    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.sorting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { error } = await this.API?.post<{ data: SortParams }, {}>(
      `${this.candidateTypeEndpoint}/${id}/sort`,
      {
        data: toSortParams('candidate_type_repositories', sortParams),
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return sortParams.current;
  }
}

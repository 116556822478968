/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toSortParams } from '@data/dto/api.dto';
import { toRegion, toRegionFilterParams } from '@data/dto/region.dto';
import { Meta, PaginateResult, SortParams } from '@data/models/api.interface';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import { FilterParams } from '@data/models/filter.interface';
import { ManagerHttp } from '@data/models/manager.interface';
import {
  Region,
  RegionHttp,
  RegionWizard,
} from '@data/models/region.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class RegionService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly regionEndpoint = '/region-repositories';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listRegions(
    filters: FilterParams,
  ): Promise<PaginateResult<Region[]>> {
    const { data: response, error } = await this.API?.get<{
      data: RegionHttp[];
      meta: Meta;
      included: ManagerHttp[];
    }>(
      this.regionEndpoint,
      toRegionFilterParams({ ...filters, include: ['manager'] }),
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items:
        response?.data?.map((region) =>
          toRegion(region, response.included || []),
        ) || [],
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async upsertRegion({
    id,
    ...regionWizard
  }: RegionWizard): Promise<Region | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<RegionWizard, 'id'> },
        { data: RegionHttp }
      >(this.regionEndpoint, { data: regionWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }
      return toRegion(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: RegionWizard },
      { data: RegionHttp }
    >(`${this.regionEndpoint}/${id}`, { data: { ...regionWizard, id } });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }
    return toRegion(data?.data || {});
  }

  public async deleteRegion(id: string): Promise<Region | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{ data: RegionHttp }>(
      `${this.regionEndpoint}/${id}`,
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toRegion(data?.data || {});
  }

  public async sortRegion(
    sortParams: DatatableReorderParams<Region>,
  ): Promise<Region | void> {
    const {
      current: { id },
    } = sortParams;

    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.sorting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { error } = await this.API?.post<{ data: SortParams }, {}>(
      `${this.regionEndpoint}/${id}/sort`,
      {
        data: toSortParams('region_repositories', sortParams),
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return sortParams.current;
  }
}

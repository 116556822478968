import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface Props {
  step: number;
}

const StepWizard = (): JSX.Element => {
  const Component = (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <Skeleton variant="circular" width={20} height={20} />
      </Grid>
      <Grid item>
        <Box maxWidth={100}>
          <Skeleton variant="text" height={30} />
        </Box>
      </Grid>
    </Grid>
  );
  return <Tab label={Component} />;
};

const TrainCourseStepWizardSkeleton = ({ step }: Props): JSX.Element => {
  return (
    <AppBar position="sticky" elevation={0} color="transparent">
      <Tabs centered value={step - 1} indicatorColor="primary">
        <StepWizard />
        <StepWizard />
        <StepWizard />
        <StepWizard />
      </Tabs>
    </AppBar>
  );
};

export default TrainCourseStepWizardSkeleton;

import { Dayjs } from 'dayjs';

import {
  DocumentVersion,
  DocumentVersionHttp,
} from './document-version.interface';

export type DocumentIncluded = {
  document_versions: DocumentVersionHttp[];
};

export enum DOCUMENT_TYPE {
  TYPE_UNDEFINED = 0,
  TYPE_CONVOCATION = 1,
  TYPE_QUOTATION = 2,
  TYPE_PROGRAM = 3,
  TYPE_ATTENDANCE = 4,
  TYPE_ACHIEVEMENT_CERTIFICATE = 5,
  TYPE_INVOICE = 6,
  TYPE_TRAINING_CERTIFICATE = 7,
  TYPE_CERFAS = 11,
  TYPE_YOUTH_MISSION_COMMITMENT_CHARTER = 12,
  TYPE_TRIPARTITE_AGREEMENT = 13,
  TYPE_AID_APPLICATION_FORM = 14,
  TYPE_DRF = 15,
  TYPE_GENERATION_STANDARD_CONTRACTS = 16,
  TYPE_PARTS_TRANSMISSION = 17,
  TYPE_DR = 18,
  TYPE_COFINANCING_REQUESTS = 19,
  TYPE_COFUNDING_AGREEMENT = 20,
  TYPE_PROOF_OF_REFUND = 21,
  TYPE_EPSP_CHARTER = 24,
  TYPE_REQUEST_ABSENCE = 25,
  TYPE_TRAINING_CONTRACT = 26,
  TYPE_MISSION_CONTRACT = 27,
  TYPE_PAYSLIPS = 28,
  TYPE_CV = 29,
  TYPE_POLE_EMPLOI_CERTIFICATE = 30,
  TYPE_ELIGIBILITY_PROOF = 31,
  TYPE_DUE_PROOF_OF_SENDING = 32,
  TYPE_DUE_ACKNOWLEDGMENT_OF_RECEIPT = 33,
  TYPE_NATIONAL_TRAINING_CONTRACT = 34,
  TYPE_TIME_STATEMENT = 35,
  TYPE_NATIONAL_PAYSLIP = 36,
  TYPE_COMPETENCE_DIAGNOSTIC = 37,
  TYPE_OTHER = 38,
  TYPE_TRAINING_AGREEMENT = 39,
  TYPE_DRF_AGREEMENT = 40,
  TYPE_OPCO_AGREEMENT = 41,
  TYPE_CHAT = 42,
}

export enum DOCUMENT_STATUS {
  UNDEFINED = 0,
  TODO = 1,
  IN_PROGRESS = 2,
}

export interface DocumentHttp {
  type: 'documents';
  id: string;
  attributes: {
    name: string;
    document_type: DOCUMENT_TYPE;
    status: DOCUMENT_STATUS;
    createdAt: string;
    updatedAt: string;
    subject: string;
    transmitter: string;
    updated_by: string;
    version_count: number;
    recipient: string;
    reference: string;
    comment: string;
    btp_card_number?: string;
    habilitation_name?: string;
    habilitation_category?: string;
    habilitation_code?: string;
    lucie_habilitation_id?: string;
    habilitation_id?: string;
    habilitation_end_date?: string;
    is_agency_charge?: boolean;
    is_locked?: boolean;
  };
  relationships: {
    training_course: {
      data?: {
        type: 'training_courses';
        id: string;
      };
    };
    training_course_training: {
      data?: {
        type: 'training_course_trainings';
        id: string;
      };
    };
    versions: {
      data?: Array<{
        type: 'document_versions';
        id: string;
      }>;
    };
  };
}

export interface Document {
  id: string;
  name: string;
  documentType: DOCUMENT_TYPE;
  documentTypeLabel: string;
  status: DOCUMENT_STATUS;
  statusLabel: string;
  statusColor: string;
  subject: string;
  transmitter: string;
  updatedBy: string;
  recipient: string;
  reference: string;
  comment: string;
  versionCount: number;
  versions: DocumentVersion[];
  btpCardNumber?: string;
  habilitationName?: string;
  habilitationCategory?: string;
  habilitationCode?: string;
  lucieHabilitationId?: string;
  habilitationId?: string;
  habilitationEndDate?: Dayjs;
  isAgencyCharge?: boolean;
  trainingCourseTrainingId: string;
  isLocked?: boolean;
  createdAt: Dayjs;
  updatedAt: Dayjs;
}

export interface DocumentWizard {
  type: 'documents';
  id: string;
  attributes: {
    name: string;
    document_type: DOCUMENT_TYPE;
    subject: string;
    comment: string;
    btp_card_number?: string;
    habilitation_name?: string;
    habilitation_category?: string;
    habilitation_code?: string;
    lucie_habilitation_id?: string;
    habilitation_id?: string;
    habilitation_end_date?: string;
    is_agency_charge?: boolean;
    is_locked?: boolean;
  };
  relationships: {
    training_course: {
      data?: {
        type: 'training_courses';
        id: string;
      };
    };
    training_course_training?: {
      data?: {
        type: 'training_course_trainings';
        id: string;
      };
    };
  };
}

export enum DOCUMENT_TYPE_CATEGORY {
  TRAINING_ORGANIZATION = 'training_organization',
  GENERATED_DOCUMENTS = 'generated_documents',
  GEDEXTRA = 'gedextra',
  OPCO = 'opco',
  INTERNAL = 'internal',
  NATIONAL_AGENCY = 'national_agency',
  EXTERNAL = 'external',
  OTHER = 'other',
}

export interface DocumentType {
  value: DOCUMENT_TYPE;
  label: string;
  category: DOCUMENT_TYPE_CATEGORY | '';
}

import { faCommentAlt } from '@fortawesome/pro-regular-svg-icons';
import {
  faEnvelope,
  faEuroSign,
  faFolderPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProvider } from '@groupeactual/ui-kit';
import Avatar from '@mui/material/Avatar';

interface Props {
  type: number;
}

const NotificationIcon = ({ type }: Props): JSX.Element => {
  const Icon = () => {
    switch (type) {
      case 1:
      case 2:
        return <IconProvider icon={faFolderPlus} color="redActual" />;
      case 3:
        return <IconProvider icon={faEuroSign} color="redActual" />;
      case 4:
      case 5:
        return <IconProvider icon={faCommentAlt} color="redActual" />;
      default:
        return <IconProvider icon={faEnvelope} color="redActual" />;
    }
  };

  return (
    <Avatar
      sizes="2"
      variant="square"
      sx={{
        backgroundColor: '#feeef0',
      }}
    >
      <Icon />
    </Avatar>
  );
};

export default NotificationIcon;

import { generatePath } from 'react-router-dom';

import { EditAction, EditStep } from '@data/models/training-course.interface';

export const getTrainingCourseUrl = (
  ref: string,
  step: string | undefined = undefined,
  action: EditAction | undefined = undefined,
  id: string | undefined = undefined,
  openCommentDrawer: boolean | undefined = undefined,
  openChat: boolean | undefined = undefined,
): string => {
  if (!ref) {
    return '';
  }

  if (action === EditAction.ACTION) {
    return generatePath('/manager/training-actions/:ref/:step', {
      step: step || 'summary',
      ref: ref.replace('ERPF#', ''),
    });
  }

  if (action === EditAction.CONVERSION) {
    return generatePath('/manager/training-actions/:ref/conversion', {
      ref: ref.replace('ERPF#', ''),
    });
  }

  const finalStep = (step !== EditStep.CONTEXT && step) || undefined;

  return `/manager/training-course/${ref.replace('ERPF#', '')}${
    (finalStep && `/${finalStep}`) || ''
  }${(action && `/${action}`) || ''}${(id && `/${id}`) || ''}${
    (openCommentDrawer && '?openCommentDrawer=1') || ''
  }${(openChat && '?openChat=1') || ''}`;
};

import {
  Steps,
  TrainingCourseState,
  TrainingCourseStateHttp,
} from '@data/models/training-course-state.interface';

import { toBoolean } from './api.dto';

export const toTrainingCourseState = (
  state: Partial<TrainingCourseStateHttp>,
): TrainingCourseState => {
  return {
    checked: toBoolean(state.attributes?.checked),
    id: state.id || '',
    state: state.attributes?.state as Steps,
  };
};

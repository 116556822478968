/* eslint-disable no-unsafe-optional-chaining */
import { container } from '@_plugins/ioc';
import ScudApi from '@data/api/scud.api';
import { toGroup } from '@data/dto/group.dto';
import { Meta, PaginateResult } from '@data/models/api.interface';
import { FilterParams } from '@data/models/filter.interface';
import { Group, GroupHttp } from '@data/models/group.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class GroupService {
  private API = container.get<ScudApi>(ScudApi);
  private errorService = container.get<ErrorService>(ErrorService);
  private groupRepositoriesEndpoint = '/group-repositories';

  public async listGroups(
    filters: FilterParams,
  ): Promise<PaginateResult<Group[]>> {
    const { data: response, error } = await this.API?.get<{
      data: GroupHttp[];
      meta: Meta;
    }>(this.groupRepositoriesEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      totalSize: response?.data?.length || 0,
      items: response?.data?.map(toGroup) || [],
    };
  }
}

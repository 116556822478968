import { useEffect, useState } from 'react';

import DateRangeWidget from '@components/Form/Widgets/DateRangeWidget';
import { Delegation } from '@data/models/delegation.interface';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';

interface Props {
  label: string;
  error?: { fromDate: string; toDate: string };
  values?: Pick<Delegation, 'fromDate' | 'toDate'>;
}

const SelectDateDelegation = ({ label, error, values }: Props) => {
  const [dates, setDates] = useState<DateRange<Dayjs>>([null, null]);
  const [value, setValue] = useState<DateRange<Dayjs>>([null, null]);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values) {
      setDates([dayjs(values.fromDate), dayjs(values.toDate)]);
    }
  }, [values]);

  return (
    <DateRangeWidget
      label={label}
      value={dates ? dates : value}
      onChange={([fromDate, toDate]: DateRange<Dayjs>) => {
        if (!fromDate || !toDate) {
          return;
        }
        setValue([fromDate, toDate]);
        setDates([fromDate, toDate]);
        setFieldValue('fromDate', fromDate);
        setFieldValue('toDate', toDate);
      }}
      startText={'Du'}
      endText={'Au'}
      error={error?.fromDate || error?.toDate}
    />
  );
};

export default SelectDateDelegation;

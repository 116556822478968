import day from '@_plugins/dayjs';
import dayjs from '@_plugins/dayjs';
import { toFinancingType } from '@data/dto/financing-type.dto';
import { FinancingTypeHttp } from '@data/models/financing-type.interface';
import {
  RefundRequest,
  RefundRequestHttp,
  RefundRequestWizard,
} from '@data/models/refund-request.interface';
import { TrainingActionCandidateHttp } from '@data/models/training-action-candidate.interface';

import { toApiDate, toApiFloat } from './api.dto';

export const toRefundRequestIncluded = (
  included: RefundRequestRawIncluded = [],
): RefundRequestIncluded => {
  return {
    trainingActionCandidates: included.filter(
      ({ type }) => type === 'training_action_candidates',
    ) as TrainingActionCandidateHttp[],
    financingType: included.filter(
      ({ type }) => type === 'financing_type_repositories',
    ) as FinancingTypeHttp[],
  };
};

export type RefundRequestRawIncluded = Array<
  TrainingActionCandidateHttp | FinancingTypeHttp
>;

export type RefundRequestIncluded = {
  trainingActionCandidates: TrainingActionCandidateHttp[];
  financingType: FinancingTypeHttp[];
};

// eslint-disable-next-line complexity
export const toRefundRequest = (
  refundRequest: Partial<RefundRequestHttp>,
  included: Partial<RefundRequestIncluded>,
): RefundRequest => {
  const financingTypeChildrenHttp = included.financingType?.find(
    ({ id }) =>
      id === refundRequest.relationships?.financing_type_repository?.data?.id,
  );

  const financingTypeParentHttp = included.financingType?.find(
    (financingType) =>
      financingType.id ===
      financingTypeChildrenHttp?.relationships.parent?.data?.id,
  );

  const trainingActionCandidateId = included.trainingActionCandidates?.find(
    ({ id }) =>
      id === refundRequest.relationships?.training_action_candidate?.data?.id,
  )?.id;

  return {
    id: refundRequest.id || '',
    requestAmount: refundRequest.attributes?.request_amount || '',
    preTaxRefundAmount: refundRequest.attributes?.pre_tax_refund_amount || '',
    vatRefundAmount: refundRequest.attributes?.vat_refund_amount || '',
    totalRefundAmount: refundRequest.attributes?.total_refund_amount || '',
    requestReference: refundRequest.attributes?.request_reference || '',
    refundReference: refundRequest.attributes?.refund_reference || '',
    refundDate:
      (refundRequest.attributes?.refund_date &&
        dayjs(refundRequest.attributes?.refund_date)) ||
      undefined,
    requestDate:
      (refundRequest.attributes?.request_date &&
        dayjs(refundRequest.attributes?.request_date)) ||
      undefined,
    type: toFinancingType(financingTypeChildrenHttp || {}, {}),
    parentType: toFinancingType(financingTypeParentHttp || {}, {}),
    trainingActionCandidateId: trainingActionCandidateId || '',
    createdAt: day(refundRequest.attributes?.createdAt),
    updatedAt: day(refundRequest.attributes?.updatedAt),
  };
};

export const toRefundRequestWizard = (
  refundRequest: Partial<RefundRequest>,
): RefundRequestWizard => {
  return {
    type: 'refund_requests',
    id: refundRequest.id || '',
    attributes: {
      request_amount: toApiFloat(refundRequest.requestAmount),
      pre_tax_refund_amount: toApiFloat(refundRequest.preTaxRefundAmount),
      vat_refund_amount: toApiFloat(refundRequest.vatRefundAmount),
      total_refund_amount: toApiFloat(refundRequest.totalRefundAmount),
      request_reference: refundRequest.requestReference || '',
      refund_reference: refundRequest.refundReference || '',
      request_date: toApiDate(refundRequest.requestDate),
      refund_date: toApiDate(refundRequest.refundDate),
    },
    relationships: {
      financing_type_repository: {
        data: {
          type: 'financing_type_repositories',
          id: refundRequest.type?.id || '',
        },
      },
      training_action_candidate: {
        data: {
          type: 'training_action_candidates',
          id: refundRequest.trainingActionCandidateId || '',
        },
      },
    },
  };
};

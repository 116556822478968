/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toSortParams } from '@data/dto/api.dto';
import { toCustomerType } from '@data/dto/customer-type.dto';
import { Meta, PaginateResult, SortParams } from '@data/models/api.interface';
import {
  CustomerType,
  CustomerTypeHttp,
  CustomerTypeWizard,
} from '@data/models/customer-type.interface';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import { FilterParams } from '@data/models/filter.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class CustomerTypeService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly customerTypeEndpoint = '/customer-type-repositories';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listCustomerTypes(
    filters: FilterParams,
  ): Promise<PaginateResult<CustomerType[]>> {
    const { data: response, error } = await this.API?.get<{
      data: CustomerTypeHttp[];
      meta?: Meta;
    }>(this.customerTypeEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items: response?.data?.map(toCustomerType) || [],
      totalSize: response?.meta?.page?.total || 0,
    };
  }

  public async upsertCustomerType({
    id,
    ...customerTypeWizard
  }: CustomerTypeWizard): Promise<CustomerType | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<CustomerTypeWizard, 'id'> },
        { data: CustomerTypeHttp }
      >(this.customerTypeEndpoint, { data: customerTypeWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toCustomerType(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: CustomerTypeWizard },
      { data: CustomerTypeHttp }
    >(`${this.customerTypeEndpoint}/${id}`, {
      data: { ...customerTypeWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toCustomerType(data?.data || {});
  }

  public async deleteCustomerType(id: string): Promise<CustomerType | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: CustomerTypeHttp;
    }>(`${this.customerTypeEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toCustomerType(data?.data || {});
  }

  public async sortCustomerType(
    sortParams: DatatableReorderParams<CustomerType>,
  ): Promise<CustomerType | void> {
    const {
      current: { id },
    } = sortParams;

    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.sorting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { error } = await this.API?.post<{ data: SortParams }, {}>(
      `${this.customerTypeEndpoint}/${id}/sort`,
      {
        data: toSortParams('customer_type_repositories', sortParams),
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return sortParams.current;
  }
}

/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toCityWizard } from '@data/dto/city.dto';
import {
  toTrainingCourseEducationalPath,
  toTrainingCourseEducationalPathIncluded,
} from '@data/dto/training-course-educational-path.dto';
import {
  fromTrainingCourseTrainingHttp,
  toTrainingCourseTrainingHttp,
} from '@data/dto/training-course-training.dto';
import { Meta, PaginateResult } from '@data/models/api.interface';
import { FilterParams } from '@data/models/filter.interface';
import {
  TrainingCourseEducationalPath,
  TrainingCourseEducationalPathHttp,
  TrainingCourseEducationalPathRawIncluded,
  TrainingCourseEducationalPathWizard,
} from '@data/models/training-course-educational-path.interface';
import {
  TrainingCourseTraining,
  TrainingCourseTrainingHttp,
} from '@data/models/training-course-training.interface';
import { TrainingCourse } from '@data/models/training-course.interface';
import CityService from '@data/services/city.service';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';
import compact from 'lodash/compact';
import omit from 'lodash/omit';

import { TrainingCourseTrainingRawIncluded } from '../models/training-course-training.interface';

@injectable()
export default class TrainingCourseEducationalPathService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly trainingCourseEducationalPathEndpoint =
    '/training-course-educational-paths';
  private errorService = container.get<ErrorService>(ErrorService);
  private cityService = container.get<CityService>(CityService);

  public async listTrainingCourseEducationalPaths(
    filters: FilterParams,
    { id }: TrainingCourse,
  ): Promise<PaginateResult<TrainingCourseEducationalPath[]>> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingCourseEducationalPathHttp[];
      meta: Meta;
      included: TrainingCourseEducationalPathRawIncluded;
    }>(`/training-courses/${String(id)}/comments`, {
      // ??
      ...filters,
      include: [
        'training_course',
        'training_course_training',
        'training_course_estimator',
      ],
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const included = toTrainingCourseEducationalPathIncluded(
      response?.included || [],
    );

    const items =
      response?.data?.map((educationalPath) =>
        toTrainingCourseEducationalPath(educationalPath, included),
      ) || [];

    return {
      items: items.sort(
        ({ createdAt: a }, { createdAt: b }) => a.unix() - b.unix(),
      ),
      totalSize: response?.meta?.page?.total || 0,
    };
  }

  public async upsertTrainingCourseEducationalPath({
    id,
    ...trainingCourseEducationalPathWizard
  }: TrainingCourseEducationalPathWizard): Promise<TrainingCourseEducationalPath | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<TrainingCourseEducationalPathWizard, 'id'> },
        {
          data: TrainingCourseEducationalPathHttp;
          included: TrainingCourseEducationalPathRawIncluded;
        }
      >(`${this.trainingCourseEducationalPathEndpoint}`, {
        data: trainingCourseEducationalPathWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      const included = toTrainingCourseEducationalPathIncluded(
        data?.included || [],
      );

      return toTrainingCourseEducationalPath(data?.data || {}, included);
    }

    const { data, error } = await this.API?.patch<
      { data: TrainingCourseEducationalPathWizard },
      {
        data: TrainingCourseEducationalPathHttp;
        included: TrainingCourseEducationalPathRawIncluded;
      }
    >(`${this.trainingCourseEducationalPathEndpoint}/${id}`, {
      data: { ...trainingCourseEducationalPathWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toTrainingCourseEducationalPathIncluded(
      data?.included || [],
    );

    return toTrainingCourseEducationalPath(data?.data || {}, included);
  }

  public async upsertTrainingCourseTrainings(
    trainings: TrainingCourseTraining[],
    educationalPath: TrainingCourseEducationalPath,
    trainingCourseId: string,
    isDraft = false,
  ): Promise<TrainingCourseTraining[] | null> {
    const results: Array<TrainingCourseTraining | null> = await Promise.all(
      trainings.map(async ({ id, ...training }) => {
        if (training.expectedCity?.code) {
          const expectedCityUpdated = await this.cityService.upsertCity(
            toCityWizard(training.expectedCity),
          );

          if (!expectedCityUpdated) {
            return null;
          }

          training.expectedCity = expectedCityUpdated;
        }

        if (training.city?.code) {
          const cityUpdated = await this.cityService.upsertCity(
            toCityWizard(training.city),
          );

          if (!cityUpdated) {
            return null;
          }

          training.city = cityUpdated;
        }

        if (!id) {
          const { data } = await this.API?.post<
            { data: Omit<TrainingCourseTrainingHttp, 'id'> },
            {
              data: TrainingCourseTrainingHttp;
              included: TrainingCourseTrainingRawIncluded;
            }
          >(
            `/training-course-trainings${
              (isDraft && '/draft') || ''
            }?include=training_options`.trim(),
            {
              data: omit(
                toTrainingCourseTrainingHttp(
                  training,
                  trainingCourseId,
                  educationalPath.id,
                ),
                'id',
              ),
            },
          );

          if (!data) {
            return null;
          }

          return {
            ...fromTrainingCourseTrainingHttp(data.data, [], data.included),
            uuid: training.uuid,
          };
        }

        const { data } = await this.API?.patch<
          { data: TrainingCourseTrainingHttp },
          {
            data: TrainingCourseTrainingHttp;
            included: TrainingCourseTrainingRawIncluded;
          }
        >(
          `/training-course-trainings/${id}${
            (isDraft && '/draft') || ''
          }?include=training_options`.trim(),
          {
            data: {
              ...toTrainingCourseTrainingHttp(
                training,
                trainingCourseId,
                educationalPath.id,
              ),
              id,
            },
          },
        );

        if (!data) {
          return null;
        }
        return {
          ...fromTrainingCourseTrainingHttp(data.data, [], data.included),
          uuid: training.uuid,
        };
      }),
    );

    if (results.some((training) => !training)) {
      return null;
    }

    return compact(results);
  }

  public async deleteTrainingCourseTraining({
    id,
  }: TrainingCourseTraining): Promise<void> {
    this.API?.delete(`/training-course-trainings/${id}`);
    return;
  }

  public async deleteTrainingCourseEducationalPath(
    id: string,
  ): Promise<TrainingCourseEducationalPath | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: TrainingCourseEducationalPathHttp;
    }>(`${this.trainingCourseEducationalPathEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingCourseEducationalPath(data?.data || {}, {});
  }
}

import { Dayjs } from 'dayjs';

import { DeviceType, DeviceTypeHttp } from './device-type.interface';

export type TrainingActionDeviceMapRawIncluded = Array<
  TrainingActionDeviceMapHttp | DeviceTypeHttp
>;

export type TrainingActionDeviceMapIncluded = {
  trainingActionDeviceMaps: TrainingActionDeviceMapHttp[];
  deviceTypes: DeviceTypeHttp[];
};

export enum TrainingActionDeviceMapType {
  AGENCY = 'agency',
  ORGANIZATION = 'training.organization',
  OPCO = 'opco',
  TRAINING_START = 'training.start',
  TRAINING = 'training',
  TRAINING_END = 'training.end',
  TRAINING_COMPLETE = 'training.complete',
  PARTNERS = 'partners',
}

export interface TrainingActionDeviceMapHttp {
  type: 'training_action_device_maps';
  id: string;
  attributes: {
    checked: boolean | number;
    label: string;
    received_at: string | null;
    refund_at: string | null;
    sent_at: string | null;
    validated_at: string | null;
    has_reference: boolean;
    reference: string | null;
    createdAt: string;
    updatedAt: string;
    type: TrainingActionDeviceMapType;
  };
  relationships: {
    training_action?: {
      data: {
        type: 'training_actions';
        id: string;
      };
    };
    children: {
      data?: {
        type: 'training_action_device_maps';
        id: string;
      }[];
    };
    device_type?: {
      data: {
        type: 'device_type_repositories';
        id: string;
      };
    };
  };
}
export interface TrainingActionDeviceMap {
  id: string;
  label: string;
  isChecked: boolean;
  receivedAt: Dayjs | null;
  refundAt: Dayjs | null;
  sentAt: Dayjs | null;
  validatedAt: Dayjs | null;
  hasReference: boolean;
  reference: string | null;
  updatedAt: Dayjs;
  createdAt: Dayjs;
  deviceType: DeviceType;
  children: TrainingActionDeviceMap[];
  type: TrainingActionDeviceMapType;
}

export interface TrainingActionDeviceMapWizard {
  type: 'training_action_device_maps';
  id: string;
  attributes: {
    checked: boolean;
    label: string;
    received_at: string;
    refund_at: string;
    sent_at: string;
    validated_at: string;
    has_reference: boolean;
    reference: string;
    // type: TrainingActionDeviceMapType;
  };
  relationships: {
    training_action?: {
      data: {
        type: 'training_actions';
        id: string;
      };
    };
    device_type?: {
      data: {
        type: 'device_type_repositories';
        id: string;
      };
    };
  };
}

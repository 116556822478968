import TrainCourseStepWizardSkeleton from '@components/Skeleton/TrainCourseStepWizardSkeleton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const ConfirmationSkeleton = (): JSX.Element => {
  return (
    <Box mt={5}>
      <TrainCourseStepWizardSkeleton step={3} />
      <Card>
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box mt={8} mb={4} justifyContent="center" display="flex">
                <Skeleton variant="circular" width={120} height={120} />
              </Box>
            </Grid>
            <Grid item>
              <Box justifyContent="center" display="flex">
                <Skeleton width={588} height={32} />
              </Box>
            </Grid>
            <Grid item>
              <Box justifyContent="center" display="flex" mb={8}>
                <Skeleton width={466} height={32} />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Box mb={5} width="100%">
            <Grid container justifyContent="center">
              <Grid item lg={4} xs={12}>
                <Box display="flex" justifyContent="center">
                  <Skeleton variant="rectangular" width={325} height={24} />
                </Box>
              </Grid>
              <Grid item lg={4} xs={12}>
                <Box display="flex" justifyContent="center">
                  <Skeleton variant="rectangular" width={144} height={24} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ConfirmationSkeleton;

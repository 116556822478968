import dayjs from '@_plugins/dayjs';
import { toApiDate } from '@data/dto/api.dto';
import {
  CustomerType,
  CustomerTypeHttp,
} from '@data/models/customer-type.interface';
import { Customer } from '@data/models/customer.interface';
import {
  TrainingCourseCustomer,
  TrainingCourseCustomerHttp,
} from '@data/models/training-course-customer.interface';

import { toApiFloat, toBoolean } from './api.dto';
import { toCustomerType } from './customer-type.dto';

export const toTrainingCourseCustomer = (
  customer: Partial<Customer>,
  customerType: CustomerType = toCustomerType({}),
): TrainingCourseCustomer => ({
  customerType,
  id: '',
  name: customer.name || '',
  SIRET: customer.SIRET || '',
  registrationNumber: customer.registrationNumber || '',
  agreementNumber: customer.agreementNumber || '',
  agreementDate: customer.agreementEndDate || undefined,
  isRFA: false,
  RFARate: '',
  managementRate: '',
  delegationRate: '',
  hasNegotiationMarge: false,
  missionDuration: '',
  missionDurationInHours: '',
});

// eslint-disable-next-line complexity
export const fromTrainingCourseCustomerHttp = (
  { attributes, id, relationships }: Partial<TrainingCourseCustomerHttp>,
  customerTypes: CustomerTypeHttp[] = [],
): TrainingCourseCustomer => {
  const customerTypeHttp =
    customerTypes.find(
      ({ id }) => relationships?.customer_type.data?.id === id,
    ) || {};

  return {
    id: id || '',
    customerType: toCustomerType(customerTypeHttp),
    name: attributes?.name || '',
    SIRET: attributes?.siret || '',
    registrationNumber: attributes?.registration_number || '',
    agreementNumber: attributes?.agreement_number || '',
    agreementDate:
      (attributes?.agreement_end_date &&
        dayjs(attributes?.agreement_end_date)) ||
      undefined,
    isRFA: toBoolean(attributes?.is_rfa),
    RFARate: attributes?.rfa_rate || '',
    managementRate: attributes?.management_rate || '',
    delegationRate: attributes?.delegation_rate || '',
    hasNegotiationMarge: toBoolean(attributes?.has_negotiation_marge),
    missionDuration: attributes?.mission_duration_in_months || '',
    missionDurationInHours: attributes?.mission_duration_in_hours || '151.67',
  };
};

export const toTrainingCourseCustomerHttp = (
  customer: Partial<TrainingCourseCustomer>,
  trainingCourseId: string | null,
): TrainingCourseCustomerHttp => {
  const customerHttp: TrainingCourseCustomerHttp = {
    type: 'training_course_customers',
    id: customer.id || '',
    attributes: {
      registration_number: customer.registrationNumber || '',
      is_rfa: customer.isRFA || false,
      has_negotiation_marge: customer.hasNegotiationMarge || false,
      name: customer.name || '',
      siret: customer.SIRET || '',
      mission_duration_in_months: toApiFloat(customer.missionDuration, 3),
      mission_duration_in_hours: toApiFloat(customer.missionDurationInHours, 3),
      agreement_number: customer.agreementNumber ?? undefined,
      agreement_end_date: customer.agreementDate
        ? toApiDate(customer.agreementDate)
        : undefined,
      rfa_rate: customer.isRFA ? toApiFloat(customer.RFARate, 3) : undefined,
    },
    relationships: {
      training_course: {
        data: {
          id: trainingCourseId || '',
          type: 'training_courses',
        },
      },
      customer_type: {
        data: {
          id: customer.customerType?.id || '',
          type: 'customer_type_repositories',
        },
      },
    },
  };

  if (customer.managementRate) {
    customerHttp.attributes.management_rate = toApiFloat(
      customer.managementRate,
      3,
    );
  }
  if (customer.delegationRate) {
    customerHttp.attributes.delegation_rate = toApiFloat(
      customer.delegationRate,
      3,
    );
  }

  return customerHttp;
};

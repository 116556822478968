import { ReactElement } from 'react';

import { ModalProps } from '@mui/material/Modal';
import { StateCreator } from 'zustand';

interface ModalStore {
  show: boolean;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ReactElement<any> | null;
  modalProps?: Partial<ModalProps>;
  className?: string;
  type?: 'drawer' | 'dialog';
  withHeader?: boolean;
  customCloseModal?: () => void;
}

export interface ModalState {
  modal: ModalStore;
  openModal: (modal: Omit<ModalStore, 'show'>) => void;
  closeModal: () => void;
}

export const createModalSlice: StateCreator<
  ModalState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  ModalState
> = (set) => ({
  modal: {
    show: false,
    title: '',
    component: null,
  },
  openModal: (modal: Omit<ModalStore, 'show'>) =>
    set((state) => ({
      modal: {
        ...state.modal,
        ...modal,
        show: true,
        withHeader: true,
        type: modal.type || 'drawer',
      },
    })),
  closeModal: () =>
    set((state) => {
      const { customCloseModal, ...restModal } = state.modal;
      if (customCloseModal) {
        customCloseModal();
      }
      return {
        modal: { ...restModal, show: false, component: null },
      };
    }),
});

/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toTrainingActionEstimatorCandidate } from '@data/dto/training-action-estimator-candidate.dto';
import { toTrainingActionIncluded } from '@data/dto/training-action.dto';
import { Meta, PaginateResult } from '@data/models/api.interface';
import { FilterParams } from '@data/models/filter.interface';
import {
  TrainingActionEstimatorCandidate,
  TrainingActionEstimatorCandidateHttp,
  TrainingActionEstimatorCandidateWizard,
} from '@data/models/training-action-estimator-candidate.interface';
import { TrainingActionRawIncluded } from '@data/models/training-action.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class TrainingActionEstimatorCandidateService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly estimatorCandidateEndpoint = '/estimator-candidates';

  private errorService = container.get<ErrorService>(ErrorService);

  private included = [
    'training_course_estimator',
    'training_action_invoices',
    'training_action_invoices.training_course_training',
    'training_course_estimator.device_type',
    'training_course_estimator.educational_path',
    'training_action_candidate.candidate_trainings',
    'training_action_candidate.candidate_trainings.training_action_candidate',
    'training_course_estimator.educational_path.training_course_trainings',
    'training_course_estimator.educational_path.training_course_trainings.candidate_trainings',
    'training_course_estimator.educational_path.training_course_trainings.periods',
  ];

  public async listTrainingActionEstimatorCandidates(
    filters: FilterParams,
  ): Promise<PaginateResult<TrainingActionEstimatorCandidate[]>> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingActionEstimatorCandidateHttp[];
      meta?: Meta;
      included: TrainingActionRawIncluded;
    }>(this.estimatorCandidateEndpoint, {
      ...filters,
      include: this.included,
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }
    const included = toTrainingActionIncluded(response?.included || []);

    const items =
      response?.data?.map((estimatorCandidate) =>
        toTrainingActionEstimatorCandidate(estimatorCandidate, included),
      ) || [];
    return {
      items,
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async upsertTrainingActionEstimatorCandidate({
    id,
    ...estimatorCandidateWizard
  }: TrainingActionEstimatorCandidateWizard): Promise<TrainingActionEstimatorCandidate | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        {
          data: Omit<TrainingActionEstimatorCandidateWizard, 'id'>;
        },
        {
          data: TrainingActionEstimatorCandidateHttp;
          included: TrainingActionRawIncluded;
        }
      >(
        `${this.estimatorCandidateEndpoint}?include=${this.included.join(',')}`,
        {
          data: estimatorCandidateWizard,
        },
      );

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      const included = toTrainingActionIncluded(data?.included || []);

      return toTrainingActionEstimatorCandidate(data?.data || {}, included);
    }

    const { data, error } = await this.API?.patch<
      { data: TrainingActionEstimatorCandidateWizard },
      {
        data: TrainingActionEstimatorCandidateHttp;
        included: TrainingActionRawIncluded;
      }
    >(
      `${this.estimatorCandidateEndpoint}/${id}?include=${this.included.join(
        ',',
      )}`,
      {
        data: { ...estimatorCandidateWizard, id },
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toTrainingActionIncluded(data?.included || []);

    return toTrainingActionEstimatorCandidate(data?.data || {}, included);
  }

  public async deleteTrainingActionEstimatorCandidate(
    id: string,
  ): Promise<TrainingActionEstimatorCandidate | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: TrainingActionEstimatorCandidateHttp;
      included: TrainingActionRawIncluded;
    }>(
      `${this.estimatorCandidateEndpoint}/${id}?include=training_course_training,device_type_repository,training_course_candidate`,
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toTrainingActionIncluded(data?.included || []);

    return toTrainingActionEstimatorCandidate(data?.data || {}, included);
  }
}

/* eslint-disable no-unsafe-optional-chaining */
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toCandidate, toCandidates } from '@data/dto/candidate.dto';
import { Meta, PaginateResult } from '@data/models/api.interface';
import { Candidate, CandidateHttp } from '@data/models/candidate.interface';
import { FilterParams } from '@data/models/filter.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class CandidateService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly candidateEndpoint = '/candidates';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listCandidates(
    filters: FilterParams,
  ): Promise<PaginateResult<Candidate[]>> {
    const { data: response, error } = await this.API?.get<{
      data: CandidateHttp[];
      meta: Meta;
    }>(this.candidateEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const candidates = response?.data?.flatMap(toCandidates) || [];

    return {
      items: candidates,
      totalSize: candidates.length,
    };
  }

  public async getCandidate(id: string): Promise<Candidate | null> {
    const { data: response, error } = await this.API?.get<{
      data: CandidateHttp;
    }>(`${this.candidateEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }

    if (!response?.data) {
      return null;
    }

    return toCandidate(response.data);
  }
}

import { toTrainingCourseEducationalPath } from '@data/dto/training-course-educational-path.dto';
import { CandidateTypeHttp } from '@data/models/candidate-type.interface';
import { DeviceTypeHttp } from '@data/models/device-type.interface';
import { ProfessionHttp } from '@data/models/profession.interface';
import { TrainingActionCandidatePeriodHttp } from '@data/models/training-action-candidate-period.interface';
import { TrainingActionCandidateTrainingHttp } from '@data/models/training-action-candidate-training.interface';
import { TrainingActionCandidateHttp } from '@data/models/training-action-candidate.interface';
import { TrainingActionEstimatorCandidateHttp } from '@data/models/training-action-estimator-candidate.interface';
import { TrainingActionTrainingCandidateInvoiceHttp } from '@data/models/training-action-training-candidate-invoice.interface';
import {
  TrainingAction,
  TrainingActionHttp,
  TrainingActionIncluded,
  TrainingActionRawIncluded,
  TrainingActionWizard,
} from '@data/models/training-action.interface';
import { TrainingCourseEducationalPathHttp } from '@data/models/training-course-educational-path.interface';
import { TrainingCourseTrainingHttp } from '@data/models/training-course-training.interface';
import { ValidationTypeHttp } from '@data/models/validation-type.interface';

import { TrainingCourseEstimatorHttp } from '../models/training-course-estimator.interface';
import { toTrainingActionCandidate } from './training-action-candidate.dto';

export const toTrainingActionIncluded = (
  included: TrainingActionRawIncluded = [],
): TrainingActionIncluded => {
  return {
    estimatorCandidates: included.filter(
      ({ type }) => type === 'estimator_candidates',
    ) as TrainingActionEstimatorCandidateHttp[],
    candidates: included.filter(
      ({ type }) => type === 'training_action_candidates',
    ) as TrainingActionCandidateHttp[],
    candidateTypes: included.filter(
      ({ type }) => type === 'candidate_type_repositories',
    ) as CandidateTypeHttp[],
    trainings: included.filter(
      ({ type }) => type === 'training_course_trainings',
    ) as TrainingCourseTrainingHttp[],
    deviceTypes: included.filter(
      ({ type }) => type === 'device_type_repositories',
    ) as DeviceTypeHttp[],
    validationTypes: included.filter(
      ({ type }) => type === 'validation_type_repositories',
    ) as ValidationTypeHttp[],
    candidatePeriods: included.filter(
      ({ type }) => type === 'training_action_candidate_periods',
    ) as TrainingActionCandidatePeriodHttp[],
    professions: included.filter(
      ({ type }) => type === 'profession_repositories',
    ) as ProfessionHttp[],
    invoices: included.filter(
      ({ type }) => type === 'training_action_invoices',
    ) as TrainingActionTrainingCandidateInvoiceHttp[],
    estimators: included.filter(
      ({ type }) => type === 'training_course_estimators',
    ) as TrainingCourseEstimatorHttp[],
    educationalPaths: included.filter(
      ({ type }) => type === 'training_course_educational_paths',
    ) as TrainingCourseEducationalPathHttp[],
    candidateTrainings: included.filter(
      ({ type }) => type === 'candidate_trainings',
    ) as TrainingActionCandidateTrainingHttp[],
  };
};

export const toTrainingAction = (
  { id, relationships }: Partial<TrainingActionHttp>,
  included: Partial<TrainingActionIncluded> = {},
  trainingCourseReference = '',
): TrainingAction => {
  const trainingActionCandidateID =
    relationships?.training_action_candidates?.data?.map(({ id }) => id) || [];

  const trainingActionCandidatesHttp =
    included.candidates?.filter(({ id }) =>
      trainingActionCandidateID.includes(id),
    ) || [];

  const educationPathHttp = included.educationalPaths?.find(
    ({ id }) => id === relationships?.educational_path?.data?.id || '',
  );

  return {
    id: id || '',
    trainingCourseRef: trainingCourseReference,
    candidates: trainingActionCandidatesHttp.map((http) =>
      toTrainingActionCandidate(http, included),
    ),
    educationalPath: toTrainingCourseEducationalPath(
      educationPathHttp || {},
      included,
    ),
  };
};

export const toTrainingActionWizard = (
  trainingAction: TrainingAction,
): TrainingActionWizard => {
  return {
    type: 'training_actions',
    id: trainingAction.id,
    attributes: {},
    relationships: {
      training_action_candidates: {
        data: trainingAction.candidates.map((candidate) => ({
          id: candidate.id || '',
          type: 'training_action_candidates',
        })),
      },
      educational_path: {
        data: {
          type: 'training_course_educational_paths',
          id: trainingAction.educationalPath.id || '',
        },
      },
    },
  };
};

import { PecRate } from '@data/models/pec-rate.interface';
import { StateCreator } from 'zustand';

export interface PecRateState {
  pecRates: PecRate[];
  setPecRates: (managers: PecRate[]) => void;
}

export const createPecRateSlice: StateCreator<
  PecRateState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  PecRateState
> = (set) => ({
  pecRates: [],
  setPecRates: (pecRates: PecRate[]) => set(() => ({ pecRates })),
});

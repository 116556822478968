/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toSortParams } from '@data/dto/api.dto';
import { toPecRate, toPecRateIncluded } from '@data/dto/pec-rate.dto';
import { Meta, PaginateResult, SortParams } from '@data/models/api.interface';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import { FilterParams } from '@data/models/filter.interface';
import {
  PecRate,
  PecRateHttp,
  PecRateRawIncluded,
  PecRateWizard,
} from '@data/models/pec-rate.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class PecRateService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly pecRateEndpoint = '/pec-rate-repositories';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listPecRates(
    filters: FilterParams,
  ): Promise<PaginateResult<PecRate[]>> {
    const { data: response, error } = await this.API?.get<{
      data: PecRateHttp[];
      meta: Meta;
      included: PecRateRawIncluded;
    }>(this.pecRateEndpoint, {
      ...filters,
      include: ['device_type', 'candidate_types'],
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const included = toPecRateIncluded(response?.included || []);

    return {
      items:
        response?.data?.map((pecRate) => toPecRate(pecRate, included)) || [],
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async upsertPecRate({
    id,
    ...pecRateWizard
  }: PecRateWizard): Promise<PecRate | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<PecRateWizard, 'id'> },
        { data: PecRateHttp; included: PecRateRawIncluded }
      >(`${this.pecRateEndpoint}?include=device_type,candidate_types`, {
        data: pecRateWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      const included = toPecRateIncluded(data?.included || []);
      return toPecRate(data?.data || {}, included);
    }

    const { data, error } = await this.API?.patch<
      { data: PecRateWizard },
      { data: PecRateHttp; included: PecRateRawIncluded }
    >(`${this.pecRateEndpoint}/${id}?include=device_type,candidate_types`, {
      data: { ...pecRateWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toPecRateIncluded(data?.included || []);
    return toPecRate(data?.data || {}, included);
  }

  public async deletePecRate(id: string): Promise<PecRate | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: PecRateHttp;
      included: PecRateRawIncluded;
    }>(`${this.pecRateEndpoint}/${id}?include=device_type,candidate_types`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toPecRateIncluded(data?.included || []);
    return toPecRate(data?.data || {}, included);
  }

  public async sortPecRate(
    sortParams: DatatableReorderParams<PecRate>,
  ): Promise<PecRate | void> {
    const {
      current: { id },
    } = sortParams;

    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.sorting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { error } = await this.API?.post<{ data: SortParams }, {}>(
      `${this.pecRateEndpoint}/${id}/sort`,
      {
        data: toSortParams('pec_rate_repositories', sortParams),
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return sortParams.current;
  }
}

import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { IconProvider } from '@groupeactual/ui-kit';
import { InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import {
  DateRange,
  DateRangePicker,
  DateRangePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dayjs } from 'dayjs';

interface Props extends Omit<DateRangePickerProps<Dayjs>, 'onChange'> {
  onChange: (value: DateRange<Dayjs>) => void;
  value: DateRange<Dayjs>;
  startText?: string;
  endText?: string;
  error?: string; // Todo ; Handle errors
}
const DateRangeWidget = ({
  value,
  onChange,
  startText,
  endText,
  error = '',
}: Props): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <DateRangePicker<Dayjs>
        value={value}
        format="DD/MM/YYYY"
        localeText={{
          start: startText,
          end: endText,
        }}
        onChange={onChange}
        slotProps={{
          fieldSeparator: {
            children: 'Au',
          },
          textField: {
            variant: 'standard',
            size: 'small',
            error: Boolean(''),
            helperText: error ?? '',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconProvider icon={faCalendar} />
                </InputAdornment>
              ),
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DateRangeWidget;

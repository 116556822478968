import { TrainingCourseEstimator } from '@data/models/training-course-estimator.interface';
import { Dayjs } from 'dayjs';

import { TrainingActionCandidatePeriod } from './training-action-candidate-period.interface';
import { TrainingActionTrainingCandidateInvoice } from './training-action-training-candidate-invoice.interface';

export enum TrainingActionEstimatorCandidateStatuses {
  'TO_DO' = 'to_do',
}
export interface TrainingActionEstimatorCandidateHttp {
  type: 'estimator_candidates';
  id: string;
  attributes: {
    id: string;
    status: TrainingActionEstimatorCandidateStatuses;
    from_date: string;
    to_date: string;
    positioning_report_expected_duration: string;
    positioning_report_real_duration: string;
    positioning_report_paid_duration: string;
    external_training_expected_duration: string;
    external_training_real_duration: string;
    external_training_paid_duration: string;
    practical_training_expected_duration: string;
    practical_training_real_duration: string;
    practical_training_paid_duration: string;
    absence_training_real_duration: string;
    total_expected_duration: string;
    total_real_duration: string;
    total_paid_duration: string;
    wage_expected_cost: string;
    wage_real_cost: string;
    additional_expected_cost: string;
    additional_real_cost: string;
    educational_expected_cost: string;
    educational_real_cost: string;
    total_expected_cost: string;
    total_real_cost: string;
    transport_expected_fees: string;
    transport_real_fees: string;
    transport_anael_fees: string;
    accommodation_expected_fees: string;
    accommodation_real_fees: string;
    accommodation_anael_fees: string;
    meal_expected_fees: string;
    meal_real_fees: string;
    meal_anael_fees: string;
    total_expected_fees: string;
    total_real_fees: string;
    total_anael_fees: string;
    opco_forecast_amount: string;
    opco_financeable_amount: string;
    opco_requested_amount: string;
    opco_received_amount: string;
    miscellaneous_services_forecast_amount: string;
    miscellaneous_services_financeable_amount: string;
    miscellaneous_services_requested_amount: string;
    miscellaneous_services_received_amount: string;
    cofi_forecast_amount: string;
    cofi_financeable_amount: string;
    cofi_requested_amount: string;
    cofi_received_amount: string;
    various_aids_forecast_amount: string;
    various_aids_financeable_amount: string;
    various_aids_requested_amount: string;
    various_aids_received_amount: string;
    remaining_forecast_amount: string;
    remaining_financeable_amount: string;
    remaining_requested_amount: string;
    remaining_received_amount: string;
    createdAt: string;
    updatedAt: string;
  };
  relationships: {
    training_action?: {
      data?: {
        type: 'training_actions';
        id: string;
      };
    };
    training_course_estimator?: {
      data?: {
        type: 'training_course_estimators';
        id: string;
      };
    };
    training_course_candidate?: {
      data?: {
        type: 'training_action_candidates';
        id: string;
      };
    };
    training_action_candidate?: {
      data?: {
        type: 'training_action_candidates';
        id: string;
      };
    };
    training_action_invoices: {
      data?: {
        type: 'training_action_invoices';
        id: string;
      }[];
    };
  };
}

export interface TrainingActionEstimatorCandidate {
  id: string;
  status: TrainingActionEstimatorCandidateStatuses;
  fromDate: Dayjs;
  toDate: Dayjs;
  positioningReportExpectedDuration: string;
  positioningReportRealDuration: string;
  positioningReportPaidDuration: string;
  externalTrainingExpectedDuration: string;
  externalTrainingRealDuration: string;
  externalTrainingPaidDuration: string;
  practicalTrainingExpectedDuration: string;
  practicalTrainingRealDuration: string;
  practicalTrainingPaidDuration: string;
  absenceTrainingRealDuration: string;
  totalExpectedDuration: string;
  totalRealDuration: string;
  totalPaidDuration: string;
  wageExpectedCost: string;
  wageRealCost: string;
  additionalExpectedCost: string;
  additionalRealCost: string;
  educationalExpectedCost: string;
  educationalRealCost: string;
  totalExpectedCost: string;
  totalRealCost: string;
  estimator: TrainingCourseEstimator | null;
  candidatePeriods: TrainingActionCandidatePeriod[];
  invoices: TrainingActionTrainingCandidateInvoice[];
  transportExpectedFees: string;
  transportRealFees: string;
  transportAnaelFees: string;
  accommodationExpectedFees: string;
  accommodationRealFees: string;
  accommodationAnaelFees: string;
  mealExpectedFees: string;
  mealRealFees: string;
  mealAnaelFees: string;
  totalExpectedFees: string;
  totalRealFees: string;
  totalAnaelFees: string;
  opcoForecastAmount: string;
  opcoFinanceableAmount: string;
  opcoRequestedAmount: string;
  opcoReceivedAmount: string;
  miscellaneousServicesForecastAmount: string;
  miscellaneousServicesFinanceableAmount: string;
  miscellaneousServicesRequestedAmount: string;
  miscellaneousServicesReceivedAmount: string;
  cofiForecastAmount: string;
  cofiFinanceableAmount: string;
  cofiRequestedAmount: string;
  cofiReceivedAmount: string;
  variousAidsForecastAmount: string;
  variousAidsFinanceableAmount: string;
  variousAidsRequestedAmount: string;
  variousAidsReceivedAmount: string;
  remainingForecastAmount: string;
  remainingFinanceableAmount: string;
  remainingRequestedAmount: string;
  remainingReceivedAmount: string;
  trainingCourseRate: string;
  trainingActionCandidateId: string;
}

export interface TrainingActionEstimatorCandidateWizard {
  type: 'estimator_candidates';
  id: string;
  attributes: {
    status: TrainingActionEstimatorCandidateStatuses;
    from_date: string;
    to_date: string;
    positioning_report_expected_duration: string;
    positioning_report_real_duration: string;
    positioning_report_paid_duration: string;
    external_training_expected_duration: string;
    external_training_real_duration: string;
    external_training_paid_duration: string;
    practical_training_expected_duration: string;
    practical_training_real_duration: string;
    practical_training_paid_duration: string;
    absence_training_real_duration: string;
    total_expected_duration: string;
    total_real_duration: string;
    total_paid_duration: string;
    wage_expected_cost: string;
    wage_real_cost: string;
    additional_expected_cost: string;
    additional_real_cost: string;
    educational_expected_cost: string;
    educational_real_cost: string;
    total_expected_cost: string;
    total_real_cost: string;
    transport_expected_fees: string;
    transport_real_fees: string;
    transport_anael_fees: string;
    accommodation_expected_fees: string;
    accommodation_real_fees: string;
    accommodation_anael_fees: string;
    meal_expected_fees: string;
    meal_real_fees: string;
    meal_anael_fees: string;
    total_expected_fees: string;
    total_real_fees: string;
    total_anael_fees: string;
    opco_forecast_amount: string;
    opco_financeable_amount: string;
    opco_requested_amount: string;
    opco_received_amount: string;
    miscellaneous_services_forecast_amount: string;
    miscellaneous_services_financeable_amount: string;
    miscellaneous_services_requested_amount: string;
    miscellaneous_services_received_amount: string;
    cofi_forecast_amount: string;
    cofi_financeable_amount: string;
    cofi_requested_amount: string;
    cofi_received_amount: string;
    various_aids_forecast_amount: string;
    various_aids_financeable_amount: string;
    various_aids_requested_amount: string;
    various_aids_received_amount: string;
    remaining_forecast_amount: string;
    remaining_financeable_amount: string;
    remaining_requested_amount: string;
    remaining_received_amount: string;
  };
  relationships: {
    training_action: {
      data?: {
        type: 'training_actions';
        id: string;
      };
    };
    training_course_estimator?: {
      data: {
        type: 'training_course_estimators';
        id: string;
      };
    };
    training_action_candidate?: {
      data: {
        type: 'training_action_candidates';
        id: string;
      } | null;
    };
    training_course_candidate?: {
      data: {
        type: 'training_course_candidates';
        id: string;
      };
    };
  };
}

import i18n from '@_plugins/i18n';
import { fromPecRatesToEstimatorCandidateType } from '@data/dto/training-course-estimator.dto';
import { DeviceTypeType } from '@data/models/device-type.interface';
import { EstimatorConfiguration } from '@data/models/estimator-configuration.interface';
import { PecRate } from '@data/models/pec-rate.interface';
import { RELIEF_TYPE } from '@data/models/relief-type.interface';
import { TrainingCourseCandidate } from '@data/models/training-course-candidate.interface';
import {
  EstimatorCandidateType,
  TrainingCourseEstimator,
} from '@data/models/training-course-estimator.interface';
import { TrainingCourseTrainingInvoiceType } from '@data/models/training-course-training.interface';
import { GraduateEducation } from '@data/models/validation-type.interface';
import flatten from 'lodash/flatten';
import round from 'lodash/round';

export const toInt = (value: number): number => {
  return Math.round(value);
};

export const toFloat = (value: number): number => {
  return Math.round(value * 100) / 100;
};

export const toFormattedFloat = (value: number, format = 'fr'): string => {
  if (isNaN(value) || value === Infinity) {
    return '-';
  }
  return (Math.round(value * 100) / 100).toLocaleString(format);
};

export const handleDivision = (dividend: number, divider: number): number =>
  (round(divider, 6) !== 0 && dividend / divider) || 0;

export const getEducationalCostLabel = (
  invoiceType?: TrainingCourseTrainingInvoiceType,
  cost?: number,
): string => {
  if (!invoiceType || !cost) {
    return '';
  }

  switch (invoiceType) {
    case TrainingCourseTrainingInvoiceType.DAILY_COST:
      return `${cost} € ${i18n.t(
        'training-course.training.invoice-type.daily_cost',
      )}`;
    case TrainingCourseTrainingInvoiceType.HOUR_COST:
      return `${cost} € ${i18n.t(
        'training-course.training.invoice-type.hour_cost',
      )}`;
    case TrainingCourseTrainingInvoiceType.GROUP_COST:
      return `${cost} € ${i18n.t(
        'training-course.training.invoice-type.group_cost',
      )}`;
    case TrainingCourseTrainingInvoiceType.INDIVIDUAL_COST:
      return `${cost} € ${i18n.t(
        'training-course.training.invoice-type.individual_cost',
      )}`;
    default:
      return '';
  }
};

export const getTotalWagesAmount = (
  estimator: TrainingCourseEstimator,
  config: EstimatorConfiguration,
): number => {
  switch (estimator.deviceType.initials) {
    case DeviceTypeType.POEC:
      return 0;
    default:
      return (
        estimator.trainingCourseAverageRate *
        parseFloat(config.wagesRate) *
        parseFloat(estimator.opcoCharge?.rate) *
        (parseFloat(
          estimator.educationalPath.positioningReviewDuration || '0',
        ) +
          estimator.educationalCostTotalDuration +
          parseFloat(
            estimator.educationalPath.practicalTrainingDuration || '0',
          ))
      );
  }
};

export const getTotalTrainingReliefCost = (
  estimator: TrainingCourseEstimator,
  config: EstimatorConfiguration,
  educationalPathDuration: number,
  trainingCourseAverageRate: number,
): number => {
  if (educationalPathDuration === 0) {
    return 0;
  }
  switch (estimator.reliefType) {
    case RELIEF_TYPE.LODEOM:
      return (
        handleDivision(parseFloat(config.tParameter) * 1.3, 0.9) *
        (handleDivision(
          2.2 * parseFloat(estimator.minWage.rate) * educationalPathDuration,
          trainingCourseAverageRate * educationalPathDuration,
        ) -
          1) *
        educationalPathDuration *
        trainingCourseAverageRate
      );
    default:
      return (
        handleDivision(parseFloat(config.tParameter), 0.6) *
        (handleDivision(
          1.6 * parseFloat(estimator.minWage.rate) * educationalPathDuration,
          trainingCourseAverageRate * educationalPathDuration,
        ) -
          1) *
        1.1 *
        educationalPathDuration *
        trainingCourseAverageRate
      );
  }
};

export const getTotalMissionReliefCost = (
  estimator: TrainingCourseEstimator,
  config: EstimatorConfiguration,
  missionDurationRecommended: number,
  missionAverageRate: number,
): number => {
  if (missionDurationRecommended === 0) {
    return 0;
  }
  switch (estimator.reliefType) {
    case RELIEF_TYPE.LODEOM:
      return (
        handleDivision(parseFloat(config.tParameter) * 1.3, 0.9) *
        (handleDivision(
          2.2 * parseFloat(estimator.minWage.rate) * missionDurationRecommended,
          missionAverageRate * missionDurationRecommended,
        ) -
          1) *
        missionDurationRecommended *
        missionAverageRate
      );
    // default case is FILLON
    default:
      return (
        handleDivision(parseFloat(config.tParameter), 0.6) *
        (handleDivision(
          1.6 * parseFloat(estimator.minWage.rate) * missionDurationRecommended,
          missionAverageRate * missionDurationRecommended,
        ) -
          1) *
        1.1 *
        missionDurationRecommended *
        missionAverageRate
      );
  }
};

export const getTotalPostTrainingReliefCost = (
  estimator: TrainingCourseEstimator,
  config: EstimatorConfiguration,
  complementMissionDurationRecommended: number,
  missionAverageRate: number,
): number => {
  if (complementMissionDurationRecommended === 0) {
    return 0;
  }
  switch (estimator.reliefType) {
    case RELIEF_TYPE.LODEOM:
      return (
        handleDivision(parseFloat(config.tParameter) * 1.3, 0.9) *
        (handleDivision(
          2.2 *
            parseFloat(estimator.minWage.rate) *
            complementMissionDurationRecommended,
          missionAverageRate * complementMissionDurationRecommended,
        ) -
          1) *
        complementMissionDurationRecommended *
        missionAverageRate
      );
    // default case is FILLON
    default:
      return (
        handleDivision(parseFloat(config.tParameter), 0.6) *
        (handleDivision(
          1.6 *
            parseFloat(estimator.minWage.rate) *
            complementMissionDurationRecommended,
          missionAverageRate * complementMissionDurationRecommended,
        ) -
          1) *
        1.1 *
        complementMissionDurationRecommended *
        missionAverageRate
      );
  }
};

export const getPECAverage = ({
  candidateTypes,
  candidatesNumber,
}: TrainingCourseEstimator): number => {
  const total = candidateTypes.reduce((acc, { quantity, pecRate }) => {
    return acc + quantity * pecRate;
  }, 0);

  return total / candidatesNumber;
};

export const getIsValidMissionDuration = ({
  deviceType,
  ddcTrainingMissionDurationInHours,
  trainingMissionRatio,
  validationType,
}: TrainingCourseEstimator): boolean | null => {
  const durationMin = parseFloat(deviceType.durationMin || '0.00');
  const durationMax = parseFloat(deviceType.durationMax || '999999.00');

  if (deviceType.initials === DeviceTypeType.CPro) {
    if (!trainingMissionRatio) {
      return false;
    }

    if (
      [GraduateEducation.Qualification, GraduateEducation.RNCP].includes(
        validationType.label as GraduateEducation,
      )
    ) {
      return (
        ddcTrainingMissionDurationInHours > durationMin &&
        trainingMissionRatio >= 0.5
      );
    }

    return (
      ddcTrainingMissionDurationInHours > durationMin &&
      trainingMissionRatio >= 0.15 &&
      trainingMissionRatio <= 0.25
    );
  }

  return (
    ddcTrainingMissionDurationInHours >= durationMin &&
    ddcTrainingMissionDurationInHours <= durationMax
  );
};

export const getTrainingMissionRatio = ({
  deviceType,
  ddcTrainingDurationInMonths,
  ddcTrainingDurationPerMonth,
  educationalCostTotalDuration,
}: TrainingCourseEstimator): number | null => {
  switch (deviceType.initials) {
    case DeviceTypeType.CPro:
      return (
        educationalCostTotalDuration /
        (ddcTrainingDurationInMonths * ddcTrainingDurationPerMonth)
      );
    default:
      return null;
  }
};

export const computeEducationCostTotal = (
  invoiceType: TrainingCourseTrainingInvoiceType,
  candidatesNumber: number,
  duration: number,
  cost: number,
): number => {
  switch (invoiceType) {
    case TrainingCourseTrainingInvoiceType.HOUR_COST:
      return duration * cost * candidatesNumber;
    case TrainingCourseTrainingInvoiceType.INDIVIDUAL_COST:
      return cost * candidatesNumber;
    case TrainingCourseTrainingInvoiceType.DAILY_COST:
      return (cost * duration) / 7;
    default:
      return cost;
  }
};

export const getPracticalTrainingAmount = (
  estimator: TrainingCourseEstimator,
): number => {
  switch (estimator.deviceType.initials) {
    case DeviceTypeType.POEC:
      return 0;
    default:
      return (
        parseFloat(estimator.educationalPath.practicalTrainingDuration || '0') *
        estimator.averagePECRate
      );
  }
};

export const getOpcoRefund = ({
  deviceType,
  trainingAssessmentAmount,
  externalTrainingAmount,
  practicalTrainingAmount,
}: TrainingCourseEstimator): number => {
  switch (deviceType.planInitials) {
    case DeviceTypeType.PLAN:
      return 0;
    default:
      return (
        (trainingAssessmentAmount || 0) +
        (externalTrainingAmount || 0) +
        (practicalTrainingAmount || 0)
      );
  }
};

export const computeEstimatorValues = (
  estimator: TrainingCourseEstimator,
  config: EstimatorConfiguration,
  candidates: TrainingCourseCandidate[],
): TrainingCourseEstimator => {
  const educationalCosts = estimator.educationalCosts.map(
    (educationalCost) => ({
      ...educationalCost,
      total: computeEducationCostTotal(
        educationalCost.invoiceType,
        estimator.candidatesNumber,
        educationalCost.duration,
        educationalCost.amount,
      ),
    }),
  );

  const educationalCostTotalDuration = educationalCosts.reduce(
    (acc, { duration }) => duration + acc,
    0,
  );

  const educationalCostAmountGroup = educationalCosts.reduce(
    (acc, { total }) => total + acc,
    0,
  );

  const missionAverageRate =
    [...Array(estimator.candidatesNumber || 0).keys()].reduce(
      (acc, cur) =>
        acc +
        ((candidates[cur] && parseFloat(candidates[cur].missionRate)) ||
          parseFloat(
            estimator?.minWage?.rate || estimator?.minWage?.rate || '10.57',
          ) ||
          0),
      0,
    ) / estimator.candidatesNumber;

  const educationalPathDuration =
    educationalCostTotalDuration +
    parseFloat(estimator.educationalPath.practicalTrainingDuration || '0') +
    parseFloat(estimator.educationalPath.positioningReviewDuration || '0');

  const trainingCourseAverageRate =
    [...Array(estimator.candidatesNumber || 0).keys()].reduce(
      (acc, cur) =>
        acc +
        ((candidates[cur] && parseFloat(candidates[cur].trainingCourseRate)) ||
          parseFloat(estimator?.minWage?.rate) ||
          0),
      0,
    ) / estimator.candidatesNumber;
  const averagePECRate = getPECAverage(estimator);

  const ddcTrainingDurationInMonths =
    (estimator.ddcTrainingDurationInMonths &&
      estimator.ddcTrainingDurationInMonths) ||
    estimator.totalMissionDurationInMonths +
      (educationalCostTotalDuration +
        parseFloat(
          estimator.educationalPath.practicalTrainingDuration || '0',
        )) /
        estimator.ddcTrainingDurationPerMonth;
  const missionDurationRecommended =
    ddcTrainingDurationInMonths *
    parseFloat(config.defaultMissionDurationPerMonth);

  const complementMissionDurationRecommended =
    estimator.additionalDelegationInMonths *
    parseFloat(config.defaultMissionDurationPerMonth);

  const ddcTrainingMissionDurationInHours = round(
    ddcTrainingDurationInMonths * estimator.ddcTrainingDurationPerMonth -
      educationalCostTotalDuration -
      parseFloat(estimator.educationalPath.practicalTrainingDuration || '0'),
    6,
  );

  const educationalCostAmountPerHour =
    educationalCostAmountGroup /
    educationalCostTotalDuration /
    estimator.candidatesNumber;

  const educationalCostAmountPerCandidate =
    educationalCostAmountPerHour * educationalCostTotalDuration;

  const ddcTrainingMissionDurationInMonths =
    ddcTrainingMissionDurationInHours / estimator.ddcTrainingDurationPerMonth;

  const trainingMissionRatio = getTrainingMissionRatio({
    ...estimator,
    ddcTrainingDurationInMonths,
    educationalCostTotalDuration,
  });

  const reInvoicingCostAmount =
    (estimator.reInvoicingCostRate * estimator.additionalCostsAmount) / 100;

  const totalWagesAmount = getTotalWagesAmount(
    {
      ...estimator,
      educationalCostTotalDuration,
      trainingCourseAverageRate,
    },
    config,
  );

  const reInvoicingWageAmount =
    (estimator.reInvoicingWageRate * totalWagesAmount) / 100;

  const reInvoicingExtraCostAmount =
    (estimator.reInvoicingExtraCostRate *
      estimator.totalTrainingRevenuePerTrainee) /
    100;

  const delegationBillingAmount =
    ddcTrainingMissionDurationInHours *
    missionAverageRate *
    estimator.delegationRate;

  const delegationBillingPostTrainingAmount =
    estimator.additionalDelegationInMonths *
    estimator.ddcTrainingDurationPerMonth *
    missionAverageRate *
    estimator.customerDelegationRate;

  const totalInvoiceAmount =
    estimator.sourcingAmount +
    reInvoicingCostAmount +
    reInvoicingWageAmount +
    estimator.reInvoicingExtraCostAmount +
    (estimator.engineeringCostAmount || 0) +
    (estimator.recruitmentAmount || 0) +
    (estimator.managementFeesAmount || 0);

  const averageCoefficientOnMissionHours =
    (totalInvoiceAmount +
      delegationBillingAmount +
      delegationBillingPostTrainingAmount) /
    (missionAverageRate * ddcTrainingMissionDurationInHours +
      estimator.additionalDelegationInMonths *
        estimator.ddcTrainingDurationPerMonth *
        missionAverageRate);

  const averageCoefficientOnMissionAndTrainingHours =
    (totalInvoiceAmount +
      delegationBillingAmount +
      delegationBillingPostTrainingAmount) /
    (missionAverageRate * ddcTrainingMissionDurationInHours +
      (educationalCostTotalDuration +
        parseFloat(
          estimator.educationalPath.practicalTrainingDuration || '0',
        )) *
        trainingCourseAverageRate +
      missionAverageRate *
        estimator.ddcTrainingDurationPerMonth *
        estimator.additionalDelegationInMonths);

  const coefficientDelegationEvolution =
    ((estimator.delegationRate - estimator.customerDelegationRate) /
      estimator.customerDelegationRate) *
    100;

  const totalTrainingExpensesPerTrainee =
    estimator.positioningReviewCost +
    educationalCostAmountPerCandidate +
    totalWagesAmount +
    estimator.additionalCostsAmount;

  const totalTrainingExpensesPerGroup =
    totalTrainingExpensesPerTrainee * estimator.candidatesNumber;
  const trainingAssessmentAmount =
    parseFloat(estimator.educationalPath.positioningReviewDuration || '0') *
    averagePECRate;

  const externalTrainingAmount = educationalCostTotalDuration * averagePECRate;

  const practicalTrainingAmount = getPracticalTrainingAmount({
    ...estimator,
    averagePECRate,
  });
  const opcoRefund = getOpcoRefund({
    ...estimator,
    trainingAssessmentAmount,
    externalTrainingAmount,
    practicalTrainingAmount,
  });

  const totalTrainingRevenuePerTrainee =
    totalTrainingExpensesPerTrainee -
    opcoRefund -
    estimator.externalCoFundingSupportedAmount -
    reInvoicingCostAmount -
    reInvoicingWageAmount;

  const totalTrainingRevenueGroup =
    totalTrainingRevenuePerTrainee * estimator.candidatesNumber;

  const turnOverPerTrainee =
    missionAverageRate *
      estimator.delegationRate *
      ddcTrainingMissionDurationInHours +
    missionAverageRate *
      estimator.ddcTrainingDurationPerMonth *
      estimator.additionalDelegationInMonths *
      estimator.customerDelegationRate;

  const turnOverPerGroup = turnOverPerTrainee * estimator.candidatesNumber;

  const ddcTrainingMissionDurationInDays =
    (ddcTrainingMissionDurationInHours /
      estimator.ddcTrainingDurationPerMonth) *
    parseFloat(config.daysWorkedPerMonth);

  const turnOverInMonthsPerTrainee =
    toFloat(turnOverPerTrainee) /
    (ddcTrainingMissionDurationInDays / parseFloat(config.daysWorkedPerMonth));

  const turnOverInMonthsPerGroup =
    turnOverInMonthsPerTrainee * estimator.candidatesNumber;

  const totalTrainingReliefCost = getTotalTrainingReliefCost(
    estimator,
    config,
    educationalPathDuration,
    trainingCourseAverageRate,
  );

  const totalMissionReliefCost = getTotalMissionReliefCost(
    estimator,
    config,
    missionDurationRecommended,
    missionAverageRate,
  );

  const totalPostTrainingReliefCost = getTotalPostTrainingReliefCost(
    estimator,
    config,
    complementMissionDurationRecommended,
    missionAverageRate,
  );

  const trainingCostWithCofiAmount =
    opcoRefund +
    totalTrainingReliefCost +
    estimator.externalCoFundingSupportedAmount +
    estimator.financialAidSupportedAmount;

  const agreementETTIAmount =
    (estimator.A2ITraineeNumber &&
      (parseFloat(config.a2iGrant) / parseFloat(config.hoursWorkedInYear)) *
        (ddcTrainingDurationInMonths * estimator.ddcTrainingDurationPerMonth) *
        (estimator.A2ITraineeNumber / estimator.candidatesNumber)) ||
    0;

  const agreementETTIPostTrainingAmount =
    (estimator.A2ITraineeNumber &&
      (parseFloat(config.a2iGrant) / parseFloat(config.hoursWorkedInYear)) *
        (estimator.additionalDelegationInMonths *
          estimator.ddcTrainingDurationPerMonth) *
        (estimator.A2ITraineeNumber / estimator.candidatesNumber)) ||
    0;

  const missionWageExpenses =
    missionAverageRate *
    parseFloat(config.wagesRate) *
    parseFloat(config.wagesRate) *
    parseFloat(estimator.opcoCharge?.rate || '0') *
    ddcTrainingMissionDurationInHours;

  const ifmGainsAmount =
    (missionWageExpenses - missionWageExpenses / 1.1) *
    (candidates.filter(({ isCdii }) => isCdii).length / candidates.length);

  const totalMissionRevenue =
    delegationBillingAmount +
    totalInvoiceAmount +
    totalMissionReliefCost +
    agreementETTIAmount +
    ifmGainsAmount;

  const missionRFAExpenses =
    missionAverageRate *
    estimator.delegationRate *
    ddcTrainingMissionDurationInHours *
    (estimator.RFARate / 100);

  const totalMissionExpenses = missionWageExpenses + missionRFAExpenses;

  const postTrainingWageExpenses =
    missionAverageRate *
    parseFloat(config.wagesRate) *
    parseFloat(config.wagesRate) *
    parseFloat(estimator.opcoCharge?.rate || '0') *
    estimator.additionalDelegationInMonths *
    estimator.ddcTrainingDurationPerMonth;

  const ifmGainsPostTrainingAmount =
    (postTrainingWageExpenses - postTrainingWageExpenses / 1.1) *
    (candidates.filter(({ isCdii }) => isCdii).length / candidates.length);

  const totalPostTrainingRevenue =
    delegationBillingPostTrainingAmount +
    totalPostTrainingReliefCost +
    agreementETTIPostTrainingAmount +
    ifmGainsPostTrainingAmount;

  const postTrainingRFAExpenses =
    missionAverageRate *
    estimator.customerDelegationRate *
    estimator.additionalDelegationInMonths *
    estimator.ddcTrainingDurationPerMonth *
    (estimator.RFARate / 100);

  const totalPostTrainingExpenses =
    postTrainingWageExpenses + postTrainingRFAExpenses;

  const grossMarginPerTraineeWithEnd =
    trainingCostWithCofiAmount +
    totalMissionRevenue +
    totalPostTrainingRevenue -
    (totalTrainingExpensesPerTrainee +
      totalMissionExpenses +
      totalPostTrainingExpenses);

  const trainingCostWithoutCOFIAmount = opcoRefund + totalTrainingReliefCost;

  const grossMarginPerTraineeWithoutEnd =
    trainingCostWithoutCOFIAmount +
    totalMissionRevenue +
    totalPostTrainingRevenue -
    (totalTrainingExpensesPerTrainee +
      totalMissionExpenses +
      totalPostTrainingExpenses);

  const grossMarginTargetAmount = totalTrainingRevenuePerTrainee * 0.5;

  const grossMarginInMonthsPerTraineeWithEnd = handleDivision(
    grossMarginPerTraineeWithEnd,
    handleDivision(
      ddcTrainingMissionDurationInDays,
      parseFloat(config.daysWorkedPerMonth),
    ),
  );

  const grossMarginInMonthsPerTraineeWithoutEnd = handleDivision(
    grossMarginPerTraineeWithoutEnd,
    handleDivision(
      ddcTrainingMissionDurationInDays,
      parseFloat(config.daysWorkedPerMonth),
    ),
  );

  const grossMarginRateWithEnd =
    grossMarginPerTraineeWithEnd /
    (totalTrainingExpensesPerTrainee +
      totalMissionExpenses +
      totalPostTrainingExpenses);

  const grossMarginRateWithoutEnd =
    grossMarginPerTraineeWithoutEnd /
    (totalTrainingExpensesPerTrainee +
      totalMissionExpenses +
      totalPostTrainingExpenses);

  const grossMarginRateAgainstExtraCostWithEnd =
    grossMarginPerTraineeWithEnd / totalTrainingRevenuePerTrainee;

  const grossMarginRateAgainstExtraCostWithoutEnd =
    grossMarginPerTraineeWithoutEnd / totalTrainingRevenuePerTrainee;

  const invoiceRateAgainstExtraCost =
    (totalInvoiceAmount +
      delegationBillingAmount +
      delegationBillingPostTrainingAmount) /
    totalTrainingRevenuePerTrainee;

  const breakEvenWithEndAmount = handleDivision(
    totalTrainingExpensesPerTrainee +
      totalMissionExpenses +
      totalPostTrainingExpenses -
      trainingCostWithCofiAmount,
    ddcTrainingMissionDurationInHours * missionAverageRate +
      estimator.additionalDelegationInMonths *
        estimator.ddcTrainingDurationPerMonth *
        missionAverageRate,
  );

  const breakEvenWithoutEndAmount = handleDivision(
    totalTrainingExpensesPerTrainee +
      totalMissionExpenses +
      totalPostTrainingExpenses -
      trainingCostWithoutCOFIAmount,
    ddcTrainingMissionDurationInHours * missionAverageRate +
      estimator.additionalDelegationInMonths *
        estimator.ddcTrainingDurationPerMonth *
        missionAverageRate,
  );

  const totalGrossMargin =
    +totalPostTrainingRevenue - totalPostTrainingExpenses;

  const estimatorUpdated = {
    ...estimator,
    missionAverageRate,
    trainingCourseAverageRate,
    educationalCostAmountGroup,
    averagePECRate,
    reInvoicingCostAmount,
    totalWagesAmount,
    educationalCosts,
    educationalCostTotalDuration,
    reInvoicingWageAmount,
    reInvoicingExtraCostAmount,
    delegationBillingAmount,
    delegationBillingPostTrainingAmount,
    trainingCostWithCofiAmount,
    totalInvoiceAmount,
    averageCoefficientOnMissionHours,
    averageCoefficientOnMissionAndTrainingHours,
    coefficientDelegationEvolution,
    totalTrainingExpensesPerTrainee,
    totalTrainingExpensesPerGroup,
    trainingAssessmentAmount,
    externalTrainingAmount,
    practicalTrainingAmount,
    opcoRefund,
    totalTrainingRevenuePerTrainee,
    totalTrainingRevenueGroup,
    turnOverPerTrainee,
    turnOverPerGroup,
    turnOverInMonthsPerTrainee,
    turnOverInMonthsPerGroup,
    ddcTrainingMissionDurationInDays,
    educationalCostAmountPerCandidate,
    educationalCostAmountPerHour,
    ddcTrainingMissionDurationInMonths,
    totalTrainingReliefCost,
    grossMarginPerTraineeWithEnd,
    totalMissionRevenue,
    totalMissionReliefCost,
    agreementETTIAmount,
    agreementETTIPostTrainingAmount,
    ifmGainsAmount,
    ifmGainsPostTrainingAmount,
    totalPostTrainingRevenue,
    totalPostTrainingReliefCost,
    missionWageExpenses,
    missionRFAExpenses,
    totalMissionExpenses,
    postTrainingWageExpenses,
    postTrainingRFAExpenses,
    totalPostTrainingExpenses,
    trainingCostWithoutCOFIAmount,
    grossMarginPerTraineeWithoutEnd,
    grossMarginTargetAmount,
    grossMarginInMonthsPerTraineeWithEnd,
    grossMarginInMonthsPerTraineeWithoutEnd,
    grossMarginRateWithEnd,
    grossMarginRateWithoutEnd,
    grossMarginRateAgainstExtraCostWithEnd,
    grossMarginRateAgainstExtraCostWithoutEnd,
    invoiceRateAgainstExtraCost,
    breakEvenWithEndAmount,
    breakEvenWithoutEndAmount,
    totalGrossMargin,
    ddcTrainingDurationInMonths,
    ddcTrainingMissionDurationInHours,

    isValidMissionDuration: getIsValidMissionDuration({
      ...estimator,
      ddcTrainingMissionDurationInHours,
      educationalCostTotalDuration,
      trainingMissionRatio,
    }),
    trainingMissionRatio: trainingMissionRatio || null,
  };
  return estimatorUpdated;
};

export const fromPecRatestoCandidateTypes = (
  pecRates: PecRate[],
  estimator: TrainingCourseEstimator,
  candidates: TrainingCourseCandidate[],
  estimatorConfig: EstimatorConfiguration,
  isTeleoperatorTraining: boolean,
): EstimatorCandidateType[] => {
  const pecRateCandidateTypes = flatten(
    pecRates.map(({ candidateTypes }) => candidateTypes),
  );

  const candidateTypes = candidates
    .map(({ candidateType }) =>
      fromPecRatesToEstimatorCandidateType(
        pecRates,
        pecRateCandidateTypes,
        candidateType,
        estimator.externalCoFundingSupportedAmount > 0,
      ),
    )
    .sort((a, b) => a.position - b.position)
    .reduce((acc: EstimatorCandidateType[], curr: EstimatorCandidateType) => {
      const prev = acc.pop();
      if (!prev) {
        return [curr];
      }
      if (prev.label === curr.label) {
        prev.quantity += curr.quantity;
        return [...acc, prev];
      }
      return [...acc, prev, curr];
    }, [] as EstimatorCandidateType[]);

  if (isTeleoperatorTraining) {
    candidateTypes.forEach(
      (type, i) =>
        (type.pecRate = parseFloat(
          (i === 0 && estimatorConfig.teleoperatorPecRate) ||
            estimatorConfig.additionalTeleoperatorPecRate,
        )),
    );
  }

  return candidateTypes;
};

export const displayRespectEducTrainingCol = (str: string) => {
  const matchList = ['CAPi', 'PRO-A', 'CPro'];
  return matchList.includes(str.trim());
};

export const calculateEducTrainingRatio = (
  e: TrainingCourseEstimator,
): string => {
  const formationExt = e.educationalCostTotalDuration;
  const formationPratique = e.educationalCostPraticalDuration;
  const bilanDuration = e.positioningReviewDuration;

  const missionDurationInMonths = e.ddcTrainingDurationInMonths;
  const complement = e.additionalDelegationInMonths;
  const hoursPerMonth = e.ddcTrainingDurationPerMonth;

  const totalFormationDurationInHours =
    bilanDuration + formationPratique + formationExt;
  const totalMissionDurationInHours =
    (missionDurationInMonths + complement) * hoursPerMonth;

  const educTrainingRatio = toFloat(
    (totalFormationDurationInHours / totalMissionDurationInHours) * 100,
  );

  const matchList = ['Diplôme', 'Titre RNCP', 'CQP'];
  const cProRule = matchList.includes(e.validationType.label.trim())
    ? educTrainingRatio >= 15 && educTrainingRatio <= 50
    : educTrainingRatio >= 15 && educTrainingRatio <= 25;

  switch (e.deviceType.initials) {
    case 'CAPi':
      return educTrainingRatio >= 25 ? 'OK' : 'KO';
    case 'CPro':
      return cProRule ? 'OK' : 'KO';
    case 'PRO-A':
      return educTrainingRatio >= 15 && educTrainingRatio <= 25 ? 'OK' : 'KO';
    default:
      return '-';
  }
};

import { API } from '@data/models/api.interface';
import axios, { AxiosRequestConfig } from 'axios';
import { HttpErrorResponse } from 'data/models/error.interface';
import { FilterParams } from 'data/models/filter.interface';
import { injectable } from 'inversify';

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const httpErrorResponse: HttpErrorResponse = error?.response?.data;
    return { error: httpErrorResponse };
  },
);

@injectable()
export default class HolidayApi implements API {
  public get<T extends object>(
    url: string,
    qs?: FilterParams,
  ): Promise<{ data?: T; error?: HttpErrorResponse }> {
    const { filter, ...params } = qs || {};

    const apiParams: {
      limit: number | undefined;
    } = {
      ['limit']: params.itemsPerPage,
    };

    return HolidayApi.send(url, {
      params: Object.keys(filter || {}).reduce((acc, cur) => {
        if (!filter || !cur) {
          return acc;
        }

        const value = filter[cur];

        if (!value) {
          return acc;
        }

        acc[cur] = filter[cur];
        return acc;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, apiParams as any),
      method: 'GET',
    });
  }

  public patch<T extends object, U extends object>(
    url: string,
    body: Partial<T>,
  ): Promise<{ data?: U; error?: HttpErrorResponse }> {
    return HolidayApi.send(url, {
      data: body,
      method: 'PATCH',
    });
  }

  public post<T extends object, U extends object>(
    url: string,
    body: Partial<T>,
  ): Promise<{ data?: U; error?: HttpErrorResponse }> {
    return HolidayApi.send(url, {
      data: body,
      method: 'POST',
    });
  }

  public delete<T extends object>(
    url: string,
  ): Promise<{ data?: T; error?: HttpErrorResponse }> {
    return HolidayApi.send(url, {
      method: 'DELETE',
    });
  }

  private static async send(
    url: string,
    config: AxiosRequestConfig,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    return axios(url, {
      responseType: 'json',
      baseURL: 'https://calendrier.api.gouv.fr/jours-feries',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...config,
    });
  }
}

/* eslint-disable no-unsafe-optional-chaining */
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toChat, toChatIncluded } from '@data/dto/chat.dto';
import { Meta, PaginateResult } from '@data/models/api.interface';
import {
  Chat,
  ChatHttp,
  ChatRawIncluded,
  ChatWizard,
} from '@data/models/chat.interface';
import { FilterParams } from '@data/models/filter.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class ChatService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly chatEndpoint = '/chats';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listChats(
    filters: FilterParams,
  ): Promise<PaginateResult<Chat[]>> {
    const { data: response, error } = await this.API?.get<{
      data: ChatHttp[];
      meta: Meta;
      included: ChatRawIncluded;
    }>(this.chatEndpoint, {
      ...filters,
      include: ['document', 'document.versions'],
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }
    const included = toChatIncluded(response?.included);
    return {
      items: response?.data?.map((chat) => toChat(chat, included)) || [],
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async upsertChat({
    id,
    ...chatWizard
  }: ChatWizard): Promise<Chat | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<ChatWizard, 'id'> },
        { data: ChatHttp; included: ChatRawIncluded }
      >(this.chatEndpoint, { data: chatWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }
      const included = toChatIncluded(data?.included);
      return toChat(data?.data || {}, included);
    }

    const { data, error } = await this.API?.patch<
      { data: ChatWizard },
      { data: ChatHttp; included: ChatRawIncluded }
    >(`${this.chatEndpoint}/${id}`, {
      data: { ...chatWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }
    const included = toChatIncluded(data?.included);
    return toChat(data?.data || {}, included);
  }
}

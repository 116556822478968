/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import {
  toTrainingCourseComment,
  toTrainingCourseCommentIncluded,
} from '@data/dto/training-course-comment.dto';
import { Meta, PaginateResult } from '@data/models/api.interface';
import { FilterParams } from '@data/models/filter.interface';
import {
  TrainingCourseComment,
  TrainingCourseCommentHttp,
  TrainingCourseCommentRawIncluded,
  TrainingCourseCommentWizard,
} from '@data/models/training-course-comment.interface';
import { TrainingCourse } from '@data/models/training-course.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class TrainingCourseCommentService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly trainingCourseCommentEndpoint = '/training-course-comments';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listTrainingCourseComments(
    filters: FilterParams,
    { id }: TrainingCourse,
  ): Promise<PaginateResult<TrainingCourseComment[]>> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingCourseCommentHttp[];
      meta: Meta;
      included: TrainingCourseCommentRawIncluded;
    }>(`/training-courses/${String(id)}/comments`, {
      ...filters,
      include: [
        'manager_repository',
        'children',
        'children.manager_repository',
      ],
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const included = toTrainingCourseCommentIncluded(response?.included || []);

    const items =
      response?.data?.map((comment) =>
        toTrainingCourseComment(comment, included),
      ) || [];

    return {
      items: items.sort(
        ({ createdAt: a }, { createdAt: b }) => a.unix() - b.unix(),
      ),
      totalSize: response?.meta?.page?.total || 0,
    };
  }

  public async upsertTrainingCourseComment({
    id,
    ...trainingCourseCommentWizard
  }: TrainingCourseCommentWizard): Promise<TrainingCourseComment | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<TrainingCourseCommentWizard, 'id'> },
        {
          data: TrainingCourseCommentHttp;
          included: TrainingCourseCommentRawIncluded;
        }
      >(`${this.trainingCourseCommentEndpoint}?include=manager_repository`, {
        data: trainingCourseCommentWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      const included = toTrainingCourseCommentIncluded(data?.included || []);

      return toTrainingCourseComment(data?.data || {}, included);
    }

    const { data, error } = await this.API?.patch<
      { data: TrainingCourseCommentWizard },
      {
        data: TrainingCourseCommentHttp;
        included: TrainingCourseCommentRawIncluded;
      }
    >(
      `${this.trainingCourseCommentEndpoint}/${id}?include=manager_repository`,
      {
        data: { ...trainingCourseCommentWizard, id },
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toTrainingCourseCommentIncluded(data?.included || []);

    return toTrainingCourseComment(data?.data || {}, included);
  }

  public async deleteTrainingCourseComment(
    id: string,
  ): Promise<TrainingCourseComment | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: TrainingCourseCommentHttp;
    }>(`${this.trainingCourseCommentEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingCourseComment(data?.data || {}, {});
  }
}

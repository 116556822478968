import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import TrainCourseStepWizardSkeleton from './TrainCourseStepWizardSkeleton';

const TrainingCourseGroupSkeleton = (): JSX.Element => {
  return (
    <Box mt={5}>
      <TrainCourseStepWizardSkeleton step={1} />

      <Card>
        <CardContent>
          <Box my={3}>
            <Skeleton variant="rectangular" height={30} width={300} />
          </Box>

          <Alert
            severity="warning"
            variant="outlined"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'orangeWarning',
              borderColor: 'orangeWarning',
              '& .MuiAlert-icon': {
                color: 'orangeWarning',
              },
            }}
          >
            <Box height={40}></Box>
          </Alert>

          <Box my={3} maxWidth={300}>
            <Skeleton variant="rectangular" height={10} />
          </Box>

          <Box mt={3}>
            <Card variant="outlined">
              <CardContent>
                <Grid container>
                  <Grid item md={11} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          width="100%"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Skeleton variant="rectangular" height={30} />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Skeleton variant="rectangular" height={30} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <Skeleton variant="rectangular" height={30} />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Skeleton variant="rectangular" height={30} />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Skeleton variant="rectangular" height={30} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={1} xs={1}>
                    <Box
                      display="flex"
                      flexGrow={1}
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      <Skeleton variant="circular" width={20} height={20} />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>

          <Box mt={3}>
            <Grid container justifyContent="flex-end">
              <Skeleton variant="rectangular" width={80} height={30} />
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TrainingCourseGroupSkeleton;

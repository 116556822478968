import React from 'react';
import { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Logo from '@assets/images/logo-erpf.svg';
import TrainingCourseSelect from '@components/Form/Widgets/Selects/TrainingCourseSelect';
import NotificationModal from '@components/NotificationModal/NotificationModal';
import { TrainingCourse } from '@data/models/training-course.interface';
import {
  faBookAlt,
  faLink,
  faUsersCog,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBell,
  faEllipsisV,
  faSearch,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProvider } from '@groupeactual/ui-kit';
import { getTrainingCourseUrl } from '@helpers/router.helper';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import NotificationBadger from '@pages/Badger/Badger';
import useStore from '@stores/store';
import { useShallow } from 'zustand/react/shallow';

// internal
import NavigationTop from './NavigationTop';

const ProfileMenu = ({ t: trans }: WithTranslation): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { openModal, userNotifications } = useStore(
    useShallow((state) => ({
      openModal: state.openModal,
      userNotifications: state.userNotifications,
    })),
  );

  const navigate = useNavigate();

  const listItemStyle = {
    color: 'greyXDark',
    '&:hover': {
      backgroundColor: 'blueHoverEquivalence',
      color: 'blueClickable',
      fontWeight: 500,
    },
  };

  return (
    <Box position="relative" zIndex="mobileStepper">
      <AppBar
        position="relative"
        sx={{ backgroundColor: 'greyXLight' }}
        elevation={0}
      >
        <NotificationBadger />
        <Container maxWidth="xl">
          <Toolbar sx={{ padding: '0 !important' }}>
            <MuiLink component={Link} underline="none" to="/" color="greyXDark">
              <Box>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  position="relative"
                >
                  <Grid item>
                    <Logo />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      fontSize="1.5rem"
                      lineHeight="1"
                      color="greyDark"
                    >
                      Alec
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      lineHeight="1"
                      color="greyDark"
                      fontWeight="400"
                    >
                      ERP Formation
                    </Typography>
                    {process.env.VITE_APP_ENVIRONMENT &&
                      process.env.VITE_APP_ENVIRONMENT !== 'production' && (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            position: 'absolute',
                            transform: 'rotate(-5deg)',
                            right: -55,
                            color: 'redActual',
                            top: 37,
                          }}
                        >
                          {process.env.VITE_APP_ENVIRONMENT}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              </Box>
            </MuiLink>
            <Box flexGrow={1} pl={3} display="flex" justifyContent="flex-end">
              <Box
                mr={1}
                width={375}
                height={44}
                sx={{
                  '.MuiOutlinedInput-root': {
                    padding: '0 !important',
                  },
                  '.MuiOutlinedInput-root .MuiSvgIcon-root': {
                    marginLeft: 3,
                  },
                  '.MuiOutlinedInput-input': {
                    marginLeft: 1,
                  },
                }}
              >
                {/* Adapt style to match the design, waiting for DS */}
                <TrainingCourseSelect
                  clearAfterChange
                  startAdornment={
                    <Box
                      ml={2}
                      mt={0.5}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          ml: '0px !important',
                        },
                      }}
                    >
                      <IconProvider icon={faSearch} />
                    </Box>
                  }
                  label=""
                  placeholder={trans(
                    'training-course.quick-search.placeholder',
                  )}
                  onChange={(trainingCourse: TrainingCourse | null) => {
                    if (!trainingCourse) {
                      return;
                    }

                    navigate(getTrainingCourseUrl(trainingCourse.reference));
                  }}
                />
              </Box>
            </Box>
            <IconButton
              size="small"
              sx={{
                mr: 1,
                backgroundColor: 'background.paper',
                padding: 1,
                borderRadius: '4px',
                border: '1px solid',
                borderColor: 'greyLightDefaultBorder',
                height: 44,
                width: 44,
              }}
              onClick={() =>
                openModal({
                  title: '',
                  withHeader: false,
                  component: <NotificationModal />,
                })
              }
            >
              <Badge
                badgeContent={
                  userNotifications.filter(({ is_read }) => !is_read).length
                }
                max={99}
                color="secondary"
                sx={{
                  '.MuiBadge-standard': {
                    height: '18px',
                    padding: '0 3px',
                    fontSize: '11px',
                  },
                }}
              >
                <IconProvider icon={faBell} />
              </Badge>
            </IconButton>
            <IconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
              }}
              size="small"
              sx={{
                backgroundColor: 'background.paper',
                padding: 1,
                my: 'auto',
                borderRadius: '4px',
                border: '1px solid',
                borderColor: 'greyLightDefaultBorder',
                height: 44,
                width: 44,
              }}
            >
              <IconProvider icon={faEllipsisV} />
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => {
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <ListItem
                component={Link}
                to={`/manager/notion/${String(
                  process.env.VITE_APP_NOTION_LOG_PAGE_ID,
                )}/${String(process.env.VITE_APP_NOTION_MAJ_PAGE_ID)}`}
                sx={listItemStyle}
                onClick={() => setAnchorEl(null)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 25,
                  }}
                >
                  <IconProvider icon={faBookAlt} />
                </ListItemIcon>
                <ListItemText
                  primary={trans('dashboard.navigation.update')}
                  disableTypography
                />
              </ListItem>
              <Divider />
              <ListItem
                component={Link}
                to={'/delegation'}
                sx={listItemStyle}
                onClick={() => setAnchorEl(null)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 25,
                  }}
                >
                  <IconProvider icon={faUsersCog} />
                </ListItemIcon>
                <ListItemText
                  primary={trans('dashboard.navigation.delegation')}
                  disableTypography
                />
              </ListItem>
              <Divider />
              <ListItem
                component="a"
                href={process.env.VITE_APP_QUICK_ACCESS_FORHUM_URL}
                sx={listItemStyle}
                target="_blank"
                onClick={() => setAnchorEl(null)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 25,
                  }}
                >
                  <IconProvider icon={faLink} />
                </ListItemIcon>
                <ListItemText
                  primary={trans('dashboard.navigation.actual')}
                  disableTypography
                />
              </ListItem>
              <ListItem
                component="a"
                href={process.env.VITE_APP_QUICK_ACCESS_LUCIE_URL}
                sx={listItemStyle}
                target="_blank"
                onClick={() => setAnchorEl(null)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 25,
                  }}
                >
                  <IconProvider icon={faLink} />
                </ListItemIcon>
                <ListItemText
                  primary={trans('dashboard.navigation.lucie')}
                  disableTypography
                />
              </ListItem>
              <ListItem
                component="a"
                href={process.env.VITE_APP_QUICK_ACCESS_AKTO_URL}
                sx={{
                  color: 'greyXDark',
                  '&:hover': {
                    backgroundColor: 'blueHoverEquivalence',
                    color: 'blueClickable',
                    fontWeight: 500,
                  },
                }}
                target="_blank"
                onClick={() => setAnchorEl(null)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 25,
                  }}
                >
                  <IconProvider icon={faLink} />
                </ListItemIcon>
                <ListItemText
                  primary={trans('dashboard.navigation.akto')}
                  disableTypography
                />
              </ListItem>
            </Popover>
          </Toolbar>
        </Container>
      </AppBar>
      <NavigationTop />
    </Box>
  );
};

export default withTranslation()(ProfileMenu);

import { UserNotification } from '@data/models/user-notification.interface';
import { StateCreator } from 'zustand';

const isUserNotification = (
  userNotification: UserNotification | UserNotification[] | undefined,
): userNotification is UserNotification => {
  return !Array.isArray(userNotification);
};

export interface NotificationState {
  userNotifications: UserNotification[];
  setNotifications: (userNotifications: UserNotification[]) => void;
  updateNotification: (userNotification: UserNotification) => void;
}

export const createNotificationSlice: StateCreator<
  NotificationState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  NotificationState
> = (set) => ({
  userNotifications: [],
  setNotifications: (userNotifications: UserNotification[]) => {
    if (!isUserNotification(userNotifications)) {
      set(() => ({ userNotifications }));
    }
  },
  updateNotification: (userNotification: UserNotification) => {
    if (!isUserNotification(userNotification)) {
      return;
    }
    set((state) => ({
      userNotifications: state.userNotifications.map((notification) =>
        notification.id === userNotification.id
          ? userNotification
          : notification,
      ),
    }));
  },
});

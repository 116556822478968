import { WithTranslation, withTranslation } from 'react-i18next';

// internal
import UserNotificationService from '@data/services/user-notification.service';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { Button, IconProvider } from '@groupeactual/ui-kit';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import useStore from '@stores/store';
import { useInjection } from 'context/Ioc.context';
import { useShallow } from 'zustand/react/shallow';

import Notification from './Notification';

interface Props {
  limit?: number;
  withLink?: boolean;
  hideReadNotifications?: boolean;
}

const NotificationList = ({
  t: trans,
  limit,
}: WithTranslation & Props): JSX.Element => {
  const notificationService = useInjection<UserNotificationService>(
    UserNotificationService,
  );
  const { userNotifications, setNotifications } = useStore(
    useShallow((state) => ({
      userNotifications: state.userNotifications,
      setNotifications: state.setNotifications,
    })),
  );

  const onMarkAllAsRead = () => {
    notificationService.markAllAsRead().then();
    setNotifications(
      userNotifications.map((notification) => ({
        ...notification,
        is_read: true,
      })),
    );
  };

  const displayNotifications = userNotifications.filter(
    ({ is_read }) => !is_read,
  );

  return (
    (displayNotifications.length && (
      <List
        component="ul"
        subheader={
          <Box
            display="flex"
            sx={{
              borderBottom: '1px solid #E3E3E3',
            }}
            pb={1}
          >
            <Typography variant="h6" fontSize="16px" color="greyDark">
              {trans('dashboard.notification.notifications')}
            </Typography>
            <Box flexGrow={1} />
            <Button variant="secondary" onClick={onMarkAllAsRead}>
              {trans('dashboard.notification.mark-all-as-read')}
            </Button>
          </Box>
        }
      >
        <Box pb={1}>
          {displayNotifications
            .filter(({ is_read }) => !is_read)
            .slice(0, limit || userNotifications.length)
            .map((notification, i) => (
              <Notification userNotification={notification} t={trans} key={i} />
            ))}
        </Box>
      </List>
    )) || (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <IconProvider icon={faThumbsUp} color="#feeef0" size="xxxl" />
        <Box my={2} />
        <Typography variant="body1" color="redActual">
          {trans('dashboard.notification.no_notification')}
        </Typography>
      </Box>
    )
  );
};

export default withTranslation()(NotificationList);

import { Document, DocumentHttp } from '@data/models/document.interface';
import { Dayjs } from 'dayjs';

import { DocumentVersionHttp } from './document-version.interface';

export enum MESSAGE_ORIGIN {
  LUCIE = 'lucie',
  ERPF = 'erpf',
}
export type ChatRawIncluded = Array<DocumentHttp | DocumentVersionHttp>;

export type ChatIncluded = {
  documents: DocumentHttp[];
  document_versions: DocumentVersionHttp[];
};

export interface ChatHttp {
  type: 'chats';
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    full_name: string;
    email: string;
    content: string;
    origin: MESSAGE_ORIGIN;
    createdAt: string;
    updatedAt: string;
  };
  relationships: {
    document?: {
      data: {
        type: 'documents';
        id: string;
      };
    };
  };
}

export interface Chat {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  document?: Document;
  email: string;
  content: string;
  origin: MESSAGE_ORIGIN;
  createdAt: Dayjs;
  updatedAt: Dayjs;
}

export interface ChatWizard {
  type: 'chats';
  id: string;
  attributes: {
    content: string;
    origin: string;
  };
  relationships: {
    training_course: {
      data?: {
        type: 'training_courses';
        id: string;
      };
    };
    document?: {
      data: {
        type: 'documents';
        id: string;
      };
    };
  };
}

import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Footer = (): JSX.Element => {
  return (
    <Box py={2}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Typography color="textSecondary" align="center">
            © {new Date().getFullYear()} Actual Group. Informations
            confidentielles à vocation interne uniquement.{' '}
            <Box pl={1} component="span">
              Développé avec
              <Box component="span" display="inline" mx={1}>
                ❤
              </Box>
              par la Digital Factory
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTranslation()(Footer);

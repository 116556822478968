import { useEffect } from 'react';

import BadgeTitle from '@components/BadgeTitle/BadgeTitle';
import useStore from '@stores/store';
import { useShallow } from 'zustand/react/shallow';

const badgeTitle = new BadgeTitle({
  size: 0.65,
});

const NotificationBadger = (): JSX.Element => {
  const { userNotifications } = useStore(
    useShallow((state) => ({
      userNotifications: state.userNotifications,
    })),
  );
  const unreadNotifications = userNotifications.filter(
    ({ is_read }) => !is_read,
  ).length;

  useEffect(() => {
    badgeTitle.value = unreadNotifications;
  }, [unreadNotifications]);
  return <></>;
};

export default NotificationBadger;

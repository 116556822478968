import TableSkeleton from '@components/Skeleton/TableSkeleton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const DashboardSkeleton = (): JSX.Element => {
  return (
    <Box mt={5}>
      <Skeleton variant="rectangular" width={200} height={32} />

      <Box my={3}>
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <Skeleton variant="rectangular" height={555} />
          </Grid>
          <Grid item sm={8}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Skeleton variant="rectangular" height={140} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant="rectangular" height={140} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant="rectangular" height={140} />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Skeleton variant="rectangular" height={394} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" flexDirection="column">
        <TableSkeleton rows={10} columns={8} />
      </Box>
    </Box>
  );
};

export default DashboardSkeleton;

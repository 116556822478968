import { StateCreator } from 'zustand';

interface NewComment {
  trainingCourse: string;
  comment: string;
  parent: string;
}

const isNewComment = (
  comment: NewComment | NewComment[] | undefined,
): comment is NewComment => {
  return !Array.isArray(comment);
};

export interface NewCommentState {
  newComments: NewComment[];
  setNewComments: (newComments: NewComment[]) => void;
  upsertNewComment: (newComments: NewComment) => void;
  findNewCommentByTrainingCourseAndParent: (
    trainingCourseId: string,
    parentId: string,
  ) => NewComment | undefined;
}

export const createNewCommentSlice: StateCreator<
  NewCommentState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  NewCommentState
> = (set, get) => ({
  newComments: [],
  setNewComments: (newComments: NewComment[]) => {
    if (!isNewComment(newComments)) {
      set(() => ({ newComments: newComments || [] }));
    }
  },
  upsertNewComment: (newComment: NewComment) => {
    if (!isNewComment(newComment)) {
      return;
    }

    const index = get().newComments.findIndex(
      ({ trainingCourse, parent }) =>
        newComment.trainingCourse === trainingCourse &&
        newComment.parent === parent,
    );

    if (index >= 0) {
      set((state) => ({
        newComments: state.newComments.map((comment) =>
          comment.trainingCourse === newComment.trainingCourse &&
          comment.parent === newComment.parent
            ? newComment
            : comment,
        ),
      }));
    }
    set((state) => ({ newComments: [...state.newComments, newComment] }));
  },
  findNewCommentByTrainingCourseAndParent: (
    trainingCourseId: string,
    parentId: string,
  ) =>
    get().newComments.find(
      ({ trainingCourse, parent }) =>
        trainingCourse === trainingCourseId && parent === parentId,
    ),
});

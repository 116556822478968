/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toSortParams } from '@data/dto/api.dto';
import {
  toFinancingType,
  toFinancingTypeIncluded,
} from '@data/dto/financing-type.dto';
import { Meta, PaginateResult, SortParams } from '@data/models/api.interface';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import { FilterParams } from '@data/models/filter.interface';
import { FinancingType } from '@data/models/financing-type.interface';
import { FinancingTypeRawIncluded } from '@data/models/financing-type.interface';
import {
  FinancingTypeHttp,
  FinancingTypeWizard,
} from '@data/models/financing-type.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class FinancingTypeService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly financingTypeEndpoint = '/financing-type-repositories';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listFinancingType(
    filters: FilterParams,
  ): Promise<PaginateResult<FinancingType[]>> {
    const { data: response, error } = await this.API?.get<{
      data: FinancingType[];
      meta?: Meta;
      included: FinancingTypeRawIncluded;
    }>(this.financingTypeEndpoint, {
      ...filters,
      include: ['children'],
      filter: { ['parent']: 'false' },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const included = toFinancingTypeIncluded(response?.included);

    const items =
      response?.data?.map((financingType) =>
        toFinancingType(financingType, included),
      ) || [];

    return {
      items: items.filter(({ financingType }) => financingType === null),
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async upsertFinancingType({
    id,
    ...financingTypeWizard
  }: FinancingTypeWizard): Promise<FinancingType | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<FinancingTypeWizard, 'id'> },
        {
          data: FinancingTypeHttp;
          included: FinancingTypeRawIncluded;
        }
      >(this.financingTypeEndpoint, {
        data: financingTypeWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      const included = toFinancingTypeIncluded(data?.included || []);

      return toFinancingType(data?.data || {}, included);
    }

    const { data, error } = await this.API?.patch<
      { data: FinancingTypeWizard },
      {
        data: FinancingTypeHttp;
        included: FinancingTypeRawIncluded;
      }
    >(`${this.financingTypeEndpoint}/${id}?include=parent`, {
      data: { ...financingTypeWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toFinancingTypeIncluded(data?.included || []);

    return toFinancingType(data?.data || {}, included);
  }

  public async deleteFinancingType(id: string): Promise<FinancingType | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: FinancingTypeHttp;
      included: FinancingTypeRawIncluded;
    }>(`${this.financingTypeEndpoint}/${id}?include=children`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toFinancingTypeIncluded(data?.included || []);

    return toFinancingType(data?.data || {}, included);
  }

  public async sortFinancingType(
    sortParams: DatatableReorderParams<FinancingType>,
  ): Promise<FinancingType | void> {
    const {
      current: { id },
    } = sortParams;

    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.sorting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { error } = await this.API?.post<{ data: SortParams }, {}>(
      `${this.financingTypeEndpoint}/${id}/sort`,
      {
        data: toSortParams('financing_type_repositories', sortParams),
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return sortParams.current;
  }
}

// libraries
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const EditSkeleton = (): JSX.Element => {
  return (
    <Box mt={5}>
      <Grid container spacing={3} direction="column">
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Skeleton height={40} width={40} variant="circular" />
            </Grid>
            <Grid item>
              <Skeleton width={330} height={32} variant="rectangular" />
            </Grid>
            <Grid item>
              <Skeleton width={134} height={32} variant="rectangular" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Skeleton height={255} variant="rectangular" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton height={255} variant="rectangular" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton height={255} variant="rectangular" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton height={255} variant="rectangular" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box width={600} m="auto">
            <Skeleton height={24} variant="rectangular" />
          </Box>
        </Grid>
        <Grid item>
          <Card>
            <CardContent>
              <Box height={72} />
              <Divider />
              <Box mt={4}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Skeleton height={370} variant="rectangular" />
                  </Grid>
                  <Grid item xs={9}>
                    <Box px={3}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Avatar>
                            <Skeleton
                              height={40}
                              width={40}
                              variant="circular"
                            />
                          </Avatar>
                        </Grid>
                        <Grid item>
                          <Skeleton
                            width={300}
                            height={32}
                            variant="rectangular"
                          />
                        </Grid>
                      </Grid>
                      <Box my={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={5}>
                            <Skeleton
                              width={318}
                              height={48}
                              variant="rectangular"
                            />
                          </Grid>
                          <Grid item xs={1} />
                          <Grid item xs={5}>
                            <Skeleton
                              width={318}
                              height={48}
                              variant="rectangular"
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <Skeleton
                              width={318}
                              height={48}
                              variant="rectangular"
                            />
                          </Grid>
                          <Grid item xs={1} />
                          <Grid item xs={5}>
                            <Skeleton
                              width={318}
                              height={48}
                              variant="rectangular"
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <Skeleton
                              width={318}
                              height={48}
                              variant="rectangular"
                            />
                          </Grid>
                          <Grid item xs={1} />
                          <Grid item xs={5}>
                            <Skeleton
                              width={318}
                              height={48}
                              variant="rectangular"
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid container alignItems="flex-end">
                        <Grid item xs={8}>
                          <Box
                            display="flex"
                            height="100%"
                            alignContent="center"
                          >
                            <Box mr={2} height={20}>
                              <Skeleton width={16} variant="rectangular" />
                            </Box>
                            <Skeleton width={154} />
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Skeleton height={48} variant="rectangular" />
                        </Grid>
                      </Grid>
                      <Box mt={2}>
                        <Skeleton height={222} variant="rectangular" />
                      </Box>

                      <Box my={2}>
                        <Skeleton height={32} />
                      </Box>
                      <Box
                        display="flex"
                        height="100%"
                        alignContent="center"
                        mt={3}
                        mb={2}
                      >
                        <Box mr={2} height={20}>
                          <Skeleton width={16} variant="rectangular" />
                        </Box>
                        <Skeleton width={100} />
                      </Box>

                      <Grid container spacing={8} justifyContent="flex-start">
                        <Grid item md={6} xs={12}>
                          <Skeleton height={76} variant="rectangular" />
                          <Skeleton height={76} variant="rectangular" />

                          <Box my={3}>
                            <Skeleton height={48} variant="rectangular" />
                          </Box>
                          <Box my={3}>
                            <Skeleton height={48} variant="rectangular" />
                          </Box>
                          <Box maxWidth={300}>
                            <Skeleton height={48} variant="rectangular" />
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Skeleton height={460} variant="rectangular" />
                        </Grid>
                      </Grid>

                      <Box mt={3}>
                        <Grid container alignItems="flex-end">
                          <Grid item xs={8}>
                            <Box
                              display="flex"
                              height="100%"
                              alignContent="center"
                            >
                              <Box mr={2} height={20}>
                                <Skeleton width={16} variant="rectangular" />
                              </Box>
                              <Skeleton width={154} />
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Skeleton height={48} variant="rectangular" />
                          </Grid>
                        </Grid>

                        <Box mt={2}>
                          <Skeleton height={72} variant="rectangular" />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditSkeleton;

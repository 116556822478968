/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import FileAPI from '@data/api/file.api';
// api
import LaravelAPI from '@data/api/laravel.api';
import { toDocument } from '@data/dto/document.dto';
import { Meta, PaginateResult } from '@data/models/api.interface';
import { DocumentVersion } from '@data/models/document-version.interface';
import {
  Document,
  DocumentHttp,
  DocumentWizard,
} from '@data/models/document.interface';
// models
import { FilterParams } from '@data/models/filter.interface';
// services
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class DocumentService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private FileAPI = container.get<FileAPI>(FileAPI);

  private readonly documentEndpoint = '/documents';
  private readonly documentVersionEndpoint = '/document-versions';

  private errorService = container.get<ErrorService>(ErrorService);

  public async deleteDocument(id: string): Promise<Document | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: DocumentHttp;
    }>(`${this.documentEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toDocument(data?.data || {});
  }

  public async listDocuments(
    filters: FilterParams,
  ): Promise<PaginateResult<Document[]>> {
    const { data: response, error } = await this.API?.get<{
      data: DocumentHttp[];
      meta?: Meta;
    }>(this.documentEndpoint, {
      ...filters,
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const items = response?.data?.map((document) => toDocument(document)) || [];

    return {
      items,
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async findDocument(id: string): Promise<Document | null> {
    const { data: response, error } = await this.API?.get<{
      data: DocumentHttp;
      meta?: Meta;
    }>(`${this.documentEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }

    return toDocument(response?.data || {});
  }

  public async upsertDocument({
    id,
    ...documentWizard
  }: DocumentWizard): Promise<Document | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<DocumentWizard, 'id'> },
        { data: DocumentHttp }
      >(this.documentEndpoint, { data: documentWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toDocument(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: DocumentWizard },
      { data: DocumentHttp }
    >(`${this.documentEndpoint}/${id}`, {
      data: { ...documentWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }
    return toDocument(data?.data || {});
  }

  public async uploadToLucie({ id }: Document): Promise<Document | void> {
    const { data, error } = await this.API?.patch<
      { data: DocumentWizard },
      { data: DocumentHttp }
    >(`${this.documentEndpoint}/${id}/upload-to-lucie`, {});

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }
    return toDocument(data?.data || {});
  }

  public getDownloadLastVersionLink({ id }: Document): string {
    return `${String(
      process.env.VITE_APP_LARAVEL_URL,
    )}documents/${id}/download-last-version`;
  }

  public async getDocumentVersionPublicLink({ id }: DocumentVersion): Promise<{
    url: string;
  }> {
    const { data, error } = await this.API.get<{
      data: { public_link: string };
    }>(`${this.documentVersionEndpoint}/${id}/public-link`);

    if (error) {
      this.errorService.handleHttpError(error);
      return { url: '' };
    }

    return {
      url: data?.data?.public_link || '',
    };
  }

  public async downloadLastVersionFile({ id }: Document): Promise<{
    name: string;
    content: Blob | undefined;
    type: string;
  } | void> {
    const { data, headers } = await this.FileAPI.get<Blob>(
      `${this.documentEndpoint}/${id}/download-last-version`,
    );

    if (!headers) {
      return {
        type: '',
        name: '',
        content: new Blob(),
      };
    }

    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(headers['content-disposition']);
    return {
      type: headers['content-type'],
      name: matches?.[1].replace(/['"]/g, '') || '',
      content: data,
    };
  }

  public async downloadZip(documents: Document[]): Promise<{
    name: string;
    content: Blob | undefined;
    type: string;
  } | void> {
    const { data, headers } = await this.FileAPI.get<Blob>(
      `${this.documentEndpoint}/download-zip?documentIds=${documents
        .map(({ id }) => id)
        .join(',')}`,
    );

    if (!headers) {
      return {
        type: '',
        name: '',
        content: new Blob(),
      };
    }

    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(headers['content-disposition']);
    return {
      type: headers['content-type'],
      name: matches?.[1].replace(/['"]/g, '') || '',
      content: data,
    };
  }
}

import React, { useRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import Datatable from '@components/Datatable/Datatable';
import ActionsContainer from '@components/Layout/ActionsContainer';
import { toFilterParams } from '@data/dto/filter.dto';
import { PaginateResult } from '@data/models/api.interface';
import {
  DatatableParams,
  DatatableRef,
} from '@data/models/datatable.interface';
import { Delegation } from '@data/models/delegation.interface';
import DelegationService from '@data/services/delegation.service';
import { faCalendar, faClock } from '@fortawesome/pro-regular-svg-icons';
import { faPencil, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { IconProvider, Text, Tooltip } from '@groupeactual/ui-kit';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import useStore from '@stores/store';
import { useInjection } from 'context/Ioc.context';
import dayjs from 'dayjs';
import { useShallow } from 'zustand/react/shallow';

const tooltipStyles = {
  maxWidth: '350px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  marginTop: '-2px',
};

const DelegationTable = ({ t: trans }: WithTranslation): JSX.Element => {
  const navigate = useNavigate();
  const datatableRef = useRef<DatatableRef>();
  const service = useInjection<DelegationService>(DelegationService);

  const { managers, addToast, openModal } = useStore(
    useShallow((state) => ({
      managers: state.managers,
      addToast: state.addToast,
      openModal: state.openModal,
    })),
  );

  const computeStatus = (status: number) => {
    switch (status) {
      case 1:
        return (
          <Box>
            <Text
              variant="body2Regular"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <IconProvider
                icon={faClock}
                sx={{
                  color: 'orange',
                  marginRight: '10px',
                  marginTop: '2px',
                }}
              />
              A venir
            </Text>
          </Box>
        );

      case 2:
        return (
          <Box>
            <Text
              variant="body2Regular"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <IconProvider
                icon={faCalendar}
                sx={{
                  color: 'green',
                  marginRight: '10px',
                  marginTop: '2px',
                }}
              />
              En cours
            </Text>
          </Box>
        );

      default:
        return 'Status..';
    }
  };

  const computeManagerName = (id: string): string => {
    const manager = managers.filter((m) => {
      return m.id === id;
    });
    return manager.length ? manager[0].fullName : id;
  };

  const listDelegations = async (
    params: DatatableParams = {
      skip: 0,
      limit: 0,
      sortInfo: null,
      filterValue: null,
    },
  ): Promise<PaginateResult<Delegation[]>> =>
    service.listDelegations(toFilterParams(params));

  const onDelete = async (delegation: Delegation): Promise<void> => {
    service.deleteDelegation(delegation.id).then((deletedDelegation) => {
      if (!deletedDelegation) {
        return;
      }
      datatableRef?.current?.reload();
      addToast({
        title: trans('action.delete.confirm'),
        message: trans('delegation.action_deleted'),
        level: 'success',
      });
    });
  };

  const columns: TypeColumn[] = [
    {
      name: 'manager',
      width: 180,
      header: trans('delegation.delegate'),
      type: 'string',
      render: ({ data: { manager } }: { data: Delegation }): string =>
        manager ? computeManagerName(manager) : '',
    },
    {
      name: 'delegationManager',
      width: 180,
      header: trans('delegation.representative'),
      type: 'string',
      render: ({
        data: { delegationManager },
      }: {
        data: Delegation;
      }): string =>
        delegationManager ? computeManagerName(delegationManager) : '',
    },
    {
      name: 'dates',
      header: trans('delegation.period'),
      type: 'date',
      width: 220,
      render: ({ data: { fromDate, toDate } }: { data: Delegation }): string =>
        fromDate && toDate
          ? `Du ${dayjs(fromDate).format('DD/MM/YYYY')} au ${dayjs(
              toDate,
            ).format('DD/MM/YYYY')}`
          : '',
    },
    {
      name: 'reason',
      header: trans('training-course.list.motif'),
      type: 'string',
      render: ({ data: { reason } }: { data: Delegation }) =>
        reason ? (
          <Tooltip placement="bottom" title={reason}>
            <Text variant="body2Regular" sx={tooltipStyles}>
              {reason}
            </Text>
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      name: 'regions',
      header: trans('delegation.regions'),
      defaultFlex: 1,
      type: 'string',
      render: ({ data: { regions } }: { data: Delegation }) =>
        regions.length > 0 ? (
          <Tooltip
            placement="bottom"
            title={regions.map((r) => r.name).join(', ')}
          >
            <Text variant="body2Regular" sx={tooltipStyles}>
              {regions.map((r) => r.name).join(', ')}
            </Text>
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      name: 'statut',
      header: trans('activity.props.status'),
      type: 'string',
      render: ({ data: { status } }: { data: Delegation }) =>
        status ? computeStatus(status) : '',
    },
    {
      name: 'action',
      sortable: false,
      header: 'Actions',
      defaultWidth: 170,

      render: ({ data: delegation }: { data: Delegation }): JSX.Element => {
        return (
          <ActionsContainer>
            <IconButton
              size="small"
              color="primary"
              onClick={() => navigate(`/delegation/${delegation.id}`)}
            >
              <IconProvider icon={faPencil} />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                openModal({
                  title: trans('action.delete.title'),
                  type: 'dialog',
                  component: (
                    <ConfirmDialog
                      message={trans('delegation.action_confirm_delete')}
                      onConfirm={() => onDelete(delegation)}
                    />
                  ),
                })
              }
            >
              <IconProvider
                icon={faTrashAlt}
                sx={{
                  color: 'redActual',
                  marginTop: '2px',
                  marginLeft: '6px',
                }}
              />
            </IconButton>
          </ActionsContainer>
        );
      },
    },
  ];

  return (
    <div>
      {managers ? (
        <Card
          variant="outlined"
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardContent
            sx={{
              flex: 1,
              minHeight: '30vh',
              overflow: 'auto',
            }}
          >
            <Datatable
              style={{ minHeight: '50vh' }}
              columns={columns}
              ref={datatableRef}
              onChange={listDelegations}
              defaultLimit={50}
              defaultSortInfo={{
                dir: 1,
                name: 'createdAt',
                id: 'createdAt',
              }}
            />
          </CardContent>
        </Card>
      ) : (
        <Text>{trans('delegation.no_delegation')}</Text>
      )}
    </div>
  );
};

export default withTranslation()(DelegationTable);

/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toSortParams } from '@data/dto/api.dto';
import { toProjectOrigin } from '@data/dto/project-origin.dto';
import { Meta, PaginateResult, SortParams } from '@data/models/api.interface';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import { FilterParams } from '@data/models/filter.interface';
import {
  ProjectOrigin,
  ProjectOriginHttp,
  ProjectOriginWizard,
} from '@data/models/project-origin.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class ProjectOriginService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly projectOriginEndpoint = '/project-origin-repositories';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listProjectOrigins(
    filters: FilterParams,
  ): Promise<PaginateResult<ProjectOrigin[]>> {
    const { data: response, error } = await this.API?.get<{
      data: ProjectOriginHttp[];
      meta: Meta;
    }>(this.projectOriginEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items: response?.data?.map(toProjectOrigin) || [],
      totalSize: response?.meta.page.total || 0,
    };
  }

  public async upsertProjectOrigin({
    id,
    ...projectOriginWizard
  }: ProjectOriginWizard): Promise<ProjectOrigin | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<ProjectOriginWizard, 'id'> },
        { data: ProjectOriginHttp }
      >(this.projectOriginEndpoint, { data: projectOriginWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }
      return toProjectOrigin(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: ProjectOriginWizard },
      { data: ProjectOriginHttp }
    >(`${this.projectOriginEndpoint}/${id}`, {
      data: { ...projectOriginWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toProjectOrigin(data?.data || {});
  }

  public async deleteProjectOrigin(id: string): Promise<ProjectOrigin | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: ProjectOriginHttp;
    }>(`${this.projectOriginEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toProjectOrigin(data?.data || {});
  }

  public async sortProjectOrigin(
    sortParams: DatatableReorderParams<ProjectOrigin>,
  ): Promise<ProjectOrigin | void> {
    const {
      current: { id },
    } = sortParams;

    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.sorting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { error } = await this.API?.post<{ data: SortParams }, {}>(
      `${this.projectOriginEndpoint}/${id}/sort`,
      {
        data: toSortParams('project_origin_repositories', sortParams),
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return sortParams.current;
  }
}

import { CandidateTypeHttp } from '@data/models/candidate-type.interface';
import {
  TrainingCourseEducationalPath,
  TrainingCourseEducationalPathHttp,
} from '@data/models/training-course-educational-path.interface';

import { DeviceTypeHttp } from './device-type.interface';
import { ProfessionHttp } from './profession.interface';
import { TrainingActionCandidatePeriodHttp } from './training-action-candidate-period.interface';
import { TrainingActionCandidateTrainingHttp } from './training-action-candidate-training.interface';
import {
  TrainingActionCandidate,
  TrainingActionCandidateHttp,
} from './training-action-candidate.interface';
import { TrainingActionEstimatorCandidateHttp } from './training-action-estimator-candidate.interface';
import { TrainingActionTrainingCandidateInvoiceHttp } from './training-action-training-candidate-invoice.interface';
import { TrainingCourseEstimatorHttp } from './training-course-estimator.interface';
import { TrainingCourseTrainingHttp } from './training-course-training.interface';
import { ValidationTypeHttp } from './validation-type.interface';

export enum TRAINING_ACTION_STATUSES {
  WAITING_FOR_RETOUR_AE = 'waiting_for_retour_ae',
  WAITING_FOR_OPCO = 'waiting_for_opco',
  RELAUNCH_OPCO = 'relaunch_opco',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  PAYROLL_CONTROL = 'payroll_control',
  DISPUTE = 'dispute',
  TODO_DR = 'todo_dr',
  SCORING = 'scoring',
  CLOSED = 'closed',
  CANCELLED = 'cancelled',
}

export enum TRAINING_ACTION_STATUSES_SELECT {
  WAITING_FOR_RETOUR_AE = 'waiting_for_retour_ae',
  WAITING_FOR_OPCO = 'waiting_for_opco',
  RELAUNCH_OPCO = 'relaunch_opco',
  IN_PROGRESS = 'in_progress',
  PAYROLL_CONTROL = 'payroll_control',
  DISPUTE = 'dispute',
  TODO_DR = 'todo_dr',
  SCORING = 'scoring',
  CLOSED = 'closed',
  CANCELLED = 'cancelled',
}

export type TrainingActionRawIncluded = Array<
  | TrainingActionEstimatorCandidateHttp
  | TrainingActionCandidateHttp
  | TrainingCourseTrainingHttp
  | TrainingActionCandidatePeriodHttp
  | DeviceTypeHttp
  | ProfessionHttp
  | TrainingActionTrainingCandidateInvoiceHttp
  | ValidationTypeHttp
  | TrainingCourseEstimatorHttp
  | CandidateTypeHttp
  | TrainingCourseEducationalPathHttp
  | TrainingActionCandidateTrainingHttp
>;

export type TrainingActionIncluded = {
  estimatorCandidates: TrainingActionEstimatorCandidateHttp[];
  candidates: TrainingActionCandidateHttp[];
  candidateTypes: CandidateTypeHttp[];
  trainings: TrainingCourseTrainingHttp[];
  deviceTypes: DeviceTypeHttp[];
  candidatePeriods: TrainingActionCandidatePeriodHttp[];
  professions: ProfessionHttp[];
  invoices: TrainingActionTrainingCandidateInvoiceHttp[];
  validationTypes: ValidationTypeHttp[];
  estimators: TrainingCourseEstimatorHttp[];
  educationalPaths: TrainingCourseEducationalPathHttp[];
  candidateTrainings: TrainingActionCandidateTrainingHttp[];
};

export interface TrainingActionHttp {
  type: 'training_actions';
  id: string;
  attributes: {
    createdAt: string;
    updatedAt: string;
  };
  relationships: {
    training_action_candidates: {
      data?: {
        type: 'training_action_candidates';
        id: string;
      }[];
    };
    educational_path: {
      data?: {
        type: 'training_course_educational_paths';
        id: string;
      };
    };
  };
}
export interface TrainingAction {
  id: string;
  trainingCourseRef: string;
  educationalPath: TrainingCourseEducationalPath;
  candidates: TrainingActionCandidate[];
}

export interface TrainingActionWizard {
  type: 'training_actions';
  id: string;
  attributes: {};
  relationships: {
    training_action_candidates: {
      data?: {
        type: 'training_action_candidates';
        id: string;
      }[];
    };
    educational_path: {
      data?: {
        type: 'training_course_educational_paths';
        id: string;
      };
    };
  };
}

import day from '@_plugins/dayjs';
import { toGroup } from '@data/dto/group.dto';
import { User, UserHttp } from '@data/models/user.interface';

// eslint-disable-next-line complexity
export const toUser = (user: Partial<UserHttp>): User => {
  const groups = user.attributes?.groups?.map(toGroup) || [];
  const firstName = user.attributes?.first_name || '';
  const lastName = user.attributes?.last_name || '';
  return {
    groups,
    firstName,
    lastName,
    id: user.attributes?.id?.toString() || '',
    avatar: user.attributes?.avatar || '',
    managerId: user.attributes?.manager_repository_id || '',
    job: user.attributes?.job || '',
    permissions: user.attributes?.permissions || [],
    division: groups.find(({ is_user_division }) => is_user_division) || null,
    email: user.attributes?.email || '',
    fullName: `${firstName} ${lastName.toUpperCase()}`,
    hierarchy: user.attributes?.hierarchy || 0,
    initials: `${firstName.charAt(0)}${lastName.charAt(0)}`,
    lastConnection:
      (user.attributes?.last_connection &&
        day(user.attributes.last_connection)) ||
      null,
    otherRole: user.attributes?.other_role || null,
    role: user.attributes?.role || 0,
    service: user.attributes?.service || '',
    title: user.attributes?.title || '',
    regions: user.attributes?.regions || [],
    createdAt: day(user.attributes?.createdAt),
    updatedAt: day(user.attributes?.updatedAt),
    isAuthenticated: (user.attributes?.id && true) || false,
    emulatedBy: user.attributes?.emulated_by || null,
  };
};

const conversion = {
  'conversion.button.label': 'Transformer le projet en action de formation',
  'conversion.title.main': 'Transformer le dossier en action de formation',
  'conversion.estimator.select': 'Sélectionner un deviseur :',
  'conversion.alert.subtitle':
    'Par défaut, cela s’appliquera à l’ensemble des candidats du dossier. Vous pouvez personnaliser le type de dispositif pour un candidat donné en le recherchant ci-dessous.',
  'conversion.estimator.title': 'Attribution du deviseur',
  'conversion.educational_path.title': 'Choix du parcours pédagogique',
  'conversion.estimator.text':
    'Veuillez attribuer un deviseur, parmi les deviseurs liés au parcours pédagogique sélectionnés, pour chaque candidat',
  'conversion.educational_path.text':
    'Veuillez sélectionner le parcours pédagogique. Celui-ci sera effectif pour tous les candidats',
  'conversion.device-type.select': 'Dispositif',
  'conversion.estimators.select': 'Deviseurs',
  'conversion.estimator.select.name': 'Sélectionner un deviseur',
  'conversion.educational_path.select': 'Parcours pédagogique :',
  'conversion.educational_paths.select.name':
    'Sélectionner un parcours pédagogique',
  'conversion.estimator.general_case': 'Cas général',
  'conversion.estimator.particular_case': 'Cas particuliers',
  'conversion.default.title': 'Attribution par défaut à tous les candidats',
  'conversion.particular_case.title': 'Cas particuliers',
  'conversion.candidate.add': 'Ajouter un candidat',
  'conversion.table.full_name': 'Prénom Nom',
  'conversion.table.actions': 'Actions',
  'conversion.table.training_label': 'Intitulé de formation',
  'conversion.table.training_organization': 'Organisme de formation',
  'conversion.table.device_type': 'Dispositif',
  'conversion.table.no_particular_case': ' Aucun cas particulier',
  'conversion.confirmation.title':
    'Validation du processus d’action de formation',
  'conversion.modal.alert_title':
    "Vous vous apprêtez à valider l'étape d'attribution du type de dispositif.",
  'conversion.modal.alert_subtitle':
    'Une fois cette étape validée, elle ne sera plus accessible.',
  'conversion.modal.confirm': 'Confirmer la transformation',
};

const actions = {
  'training-actions.actions.summary': 'Résumé',
  'training-actions.actions.control': 'Suivi',
  'training-actions.actions.schedule': 'Planning',
};

const summary = {
  'summary.title.main': 'Information dossier',
  'summary.action.previous_step': 'Étapes précédentes',
  'summary.task.received_at': 'Réception',
  'summary.task.validated_at': 'Validation',
  'summary.task.start_training': 'Démarrage formation',
  'summary.task.end_training': 'Fin de formation',
  'summary.cofunder.select': 'Ajouter un partenaire *',
  'summary.action.end': 'Dossier complet',
  'summary.action.done': 'Dossier clôturé !',
  'summary.last_modification': 'Dernière modification : ',
  'summary.interns': 'Stagiaires',
  'summary.all_devices_combined': 'Tous dispositifs confondus : ',
  'summary.training_time_per_person': 'Durée de formation par personne',
  'summary.training_time_per_person.expected': 'Prévue : ',
  'summary.training_time_per_person.done': 'Réalisée : ',
  'summary.total_educational_cost': 'Coûts pédagogique total',
  'summary.total_educational_cost.expected': 'Prévus : ',
  'summary.total_educational_cost.done': 'Réalisés : ',
  'summary.drf.sent_date': "Date d'envoi",
};

const cost = {
  'control.candidate.status': 'Statut',
  'control.candidates.list': 'Liste des candidats ({{count}}) : ',
  'control.device_types.list': 'Liste des dispositifs ({{count}}) : ',
  'control.candidate.type': 'Type de stagiaire',
  'control.candidate.public': 'Public',
  'control.candidate.pec_rate': 'Taux PEC',
  'control.candidate.plan_type': 'Type de plan',
  'control.candidate.contract': 'Code motif contrat',
  'control.candidate.edit': 'Éditer un candidat',
  'control.candidate.delete':
    'Une fois validé, la suppression de {{candidate}} sera définitive.',
  'control.candidate.deleted': 'Le candidat a bien été supprimé',
  'control.candidate.delete.title': 'Supprimer un candidat',
  'control.candidate.estimator.name': 'Deviseur : ',
  'control.training_cost.add_candidates': 'Ajouter des candidats',
  'control.training_cost.save_candidates': 'Enregistrer les candidats',
  'control.training_cost.edit_candidate': 'Modifier le candidat',
  'control.training_cost.edit.candidate': 'Édition de {{candidate}}',
  'control.actions.title': 'Suivi du parcours pédagogique ({{totalTrainings}})',
  'control.tabs.cost': 'Suivi de coût',
  'control.tabs.hour': 'Suivi des heures',
  'control.training_cost.validation_type': 'Type de validation',
  'control.training_cost.duration_and_dates': 'Durées et dates',
  'control.training_cost.changes': 'Changement',
  'control.training_cost.expected_duration': 'Durées prévisionnelles',
  'control.training_cost.real_duration': 'Durées réalisées',
  'control.training_cost.paid_duration': 'Durées payées',
  'control.training_cost.positioning_report': 'Durée bilan positionnement',
  'control.training_cost.external_training_duration':
    'Durées formations externes',
  'control.training_cost.absence_training_duration': "Heures d'absences",
  'control.training_cost.practical_training_duration':
    'Durée formation pratique',
  'control.training_cost.home_training': 'Formation distancielle',
  'control.training_cost.add_period': 'Ajouter une période',
  'control.training_cost.add_external_period': 'Ajouter une période externe',
  'control.training_cost.add_practical_period': 'Ajouter une période pratique',
  'control.training_cost.edit_real_duration': 'Modifier les heures realisées',
  'control.training_cost.delete_period': 'Supprimer la période',
  'control.training_cost.edit_realised_costs': 'Modifier les coûts réalisés',
  'control.training_cost.apply_to_all.edit_real_duration':
    'Modifier la durée sur tous les candidats',
  'control.training_cost.costs': 'Coûts',
  'control.datatable.entitled': 'Intitulé',
  'control.training_cost.rate': 'Taux',
  'control.training_cost.expected_cost': 'Coûts prévisionnels',
  'control.training_cost.real_cost': 'Coûts réalisés',
  'control.training_cost.anael_state': 'État dans Anaël',
  'control.training_cost.wages': 'Coût salaires formation',
  'control.training_cost.fees': 'Coût frais annexes',
  'control.training_cost.transport': 'Transport',
  'control.training_cost.meal': 'Restauration',
  'control.training_cost.accommodation': 'Hébergement',
  'control.training_cost.total_cost': 'Coûts totaux',
  'control.training_cost.total_duration': 'Durées Totales',
  'control.training_cost.training_cost': 'Coût pédagogique HT',
  'control.training_cost.absence': 'Heures d’absences facturées',
  'control.training_cost.add_invoice':
    'Coût pédagogique HT - ajouter une facture',
  'control.training_cost.add_invoice_apply_to_all':
    'Ajouter cette facture à tous les candidats',
  'control.training_status.to_do': 'En attente',
  'control.training_cost.save_period': 'Ajouter cette période',
  'control.training_cost.edit_period': 'Modifier cette période',
  'control.training_cost.period_apply_to_all':
    'Ajouter cette période à tous les candidats',
  'control.training_cost.period_edit_on_all':
    'Modifier cette période sur tous les candidats',
  'control.training_cost.label_period': 'Libellé de la période',
  'control.training_cost.expected_duration_period': 'Nombre d’heures prévues',
  'control.training_cost.real_duration_period': 'Nombre d’heures réalisées',
  'control.training_cost.invoice_reference': 'Référence de la facture',
  'control.training_cost.training': 'Formation',
  'control.training_cost.entitled_training': 'Intitulé de formation',
  'control.training_cost.invoice_hour_rate': 'Taux horaire',
  'control.training_cost.invoice_number_hours': "Nombre d'heure",
  'control.training_cost.invoice_total_amount': 'Montant total',
  'control.training_cost.invoice_type': 'Type de coûts',
  'control.training_cost.invoice_type_individual': 'Coûts individuels',
  'control.training_cost.invoice_type_group': 'Coût groupe',
  'control.training_cost.invoice_is_hour_cost': 'Coût horaire',
  'control.training_cost.save_invoice': 'Ajouter la facture',
  'control.training_cost.save_invoice_all':
    'Ajouter la facture à tous les candidats',
  'control.expected_cost_period': 'Coûts prévisionnels',
  'control.real_cost_period': 'Coûts réalisés',
  'control.update_candidates.warning_title':
    'Modification pour tous les candidats',
  'control.update_candidates.warning_text':
    'Les valeurs des candidats suivant diffèrent des valeurs actuelles. Les données vont être remplacées :',
  'control.period_type': 'Type de formation',
  'control.period_type.external': 'Externe',
  'control.period_type.practical': 'Pratique',
  'control.candidate.period.deleted': 'Période supprimée',
  'control.candidate.period.relevant_period_from': 'Période concernée du :',
  'control.training_cost.session_period':
    '{{label}} du {{fromDate}} au {{toDate}}',
  'control.training_cost.edit_session_period': 'Éditer une periode',
  'control.training_cost.period_one':
    'Mois de {{month}} {{year}} - {{count}} période',
  'control.training_cost.period_other':
    'Mois de {{month}} {{year}} - {{count}} périodes',
  'control.training_cost.edit_invoice': 'Modifier la facture',
  'control.training_cost.delete_invoice': 'Supprimer la facture',
  'control.training_cost.invoice_deleted': 'Facture supprimée',
  'control.training_cost.invoice_edited': 'Facture modifiée',
  'control.training_cost.edit_invoice.apply_to_all':
    'Modifier pour tous les candidats',
  'control.training_cost.edit_invoice.apply_to_one':
    'Modifier pour ce candidat',
  'control.training_cost.invoice_edited.apply_to_all':
    'Facture modifiée sur tous les candidats',
};

const funding = {
  'control.tabs.funding': 'Suivi de financement',
  'control.funding.details': 'Détails du reste à charge',
  'control.funding.scheduled_hours': 'Heures prévues : ',
  'control.funding.worked_hours': 'Heures réalisées : ',
  'control.funding.opco': 'OPCO',
  'control.funding.miscellaneous_service': 'Presta diverses',
  'control.funding.cofi': 'COFI',
  'control.funding.various_aids': 'Aides diverses',
  'control.funding.remains_dependent': 'Reste à charge',
  'control.funding.forecast': 'Prévisionnel',
  'control.funding.requested': 'Demandé',
  'control.funding.remaining_requested': 'À demander',
  'control.funding.financable': 'Finançable',
  'control.funding.received': 'Reçu',
  'control.funding.waiting': 'En attente',
  'control.funding.real': 'Réel',
  'control.funding.additional_cost': 'Surcoût',
  'control.funding.title': 'Suivi des demandes et remboursements',
  'control.funding.add': 'Ajouter une demande',
  'control.funding.pec_rate': 'Taux PEC :',
  'control.funding.requests': 'Demandes',
  'control.funding.refunds': 'Remboursements',
  'control.funding.requests.props.requestReference': 'Réf',
  'control.funding.requests.props.type': 'Type',
  'control.funding.requests.props.createdAt': 'Date',
  'control.funding.requests.props.requestAmount': 'Montant',
  'control.funding.refunds.props.refundReference': 'Réf',
  'control.funding.refunds.props.createdAt': 'Date',
  'control.funding.refunds.props.preTaxRefundAmount': 'Montant HT',
  'control.funding.refunds.props.vatRefundAmount': 'Montant TVA',
  'control.funding.refunds.props.totalRefundAmount': 'Montant TTC',
};

const result = {
  'control.tabs.result': 'Résultat',
  'control.training_result.title': 'Intitulé de formation',
  'control.training_result.result': 'Résultat',
  'control.training_result.validation_date': 'Date de validation',
  'control.training_result.result_type.total_validation': 'Validation totale',
  'control.training_result.result_type.partial_validation':
    'Validation partielle',
  'control.training_result.result_type.failure': 'Échec',
  'control.training_result.result_type.resignation': 'Démission',
  'control.training_result.result_type.pe_ae_break': 'Rupture PE/AE',
  'control.training_result.result_type.pe_eu_break': 'Rupture PE/EU',
  'control.training_result.result_type.ae_break': 'Rupture AE',
  'control.training_result.result_type.eu_break': 'Rupture EU',
  'control.training_result.result_type.undefined': 'Non renseigné',
  'control.training_result.update': 'Formation mise à jour',
  'control.training_result.error':
    'Une erreur est survenue lors de la mise à jour de la formation',
};

const planning = {
  'training-action.edit.validate': 'Valider',
};

export default {
  ...conversion,
  ...actions,
  ...summary,
  ...cost,
  ...funding,
  ...result,
  ...planning,
};

import day from '@_plugins/dayjs';
import dayjs from '@_plugins/dayjs';
import { FilterParams } from '@data/models/filter.interface';
import {
  MANAGER_INTERNAL_ROLES,
  Manager,
  ManagerHttp,
  ManagerWizard,
} from '@data/models/manager.interface';
import { User } from '@data/models/user.interface';

// eslint-disable-next-line complexity
export const toManager = (
  manager: Partial<ManagerHttp>,
  supervisors: ManagerHttp[] = [],
): Manager => {
  const supervisorId = manager.relationships?.supervisor.data?.id;
  const supervisor =
    (supervisorId && supervisors.find(({ id }) => id === supervisorId)) || null;

  return {
    supervisor: (supervisor && toManager(supervisor)) || null,
    id: manager.id || '',
    avatar: manager.attributes?.avatar || '',
    email: manager.attributes?.email || '',
    fullName: `${manager.attributes?.first_name || ''} ${
      manager.attributes?.last_name.toUpperCase() || ''
    }`,
    firstName: manager.attributes?.first_name || '',
    initials: manager.attributes?.initials || '',
    lastConnection:
      (manager.attributes?.last_connection &&
        day(manager.attributes.last_connection)) ||
      null,
    lastName: manager.attributes?.last_name || '',
    service: manager.attributes?.service || '',
    title: manager.attributes?.title || '',
    internalRole:
      manager.attributes?.internal_role || MANAGER_INTERNAL_ROLES.MANAGER,
    hasSignature: manager.attributes?.has_signature || false,
    createdAt: day(manager.attributes?.createdAt),
    updatedAt: day(manager.attributes?.updatedAt),
  };
};
export const fromUser = (user: Partial<User>): Manager => {
  return {
    internalRole: MANAGER_INTERNAL_ROLES.MANAGER,
    supervisor: null,
    id: user.managerId || '',
    avatar: user.avatar || '',
    email: user.email || '',
    fullName: user.fullName || '',
    firstName: user.firstName || '',
    initials: user.initials || '',
    lastConnection: user.lastConnection || day(),
    lastName: user.lastName || '',
    service: user.service || '',
    title: user.title || '',
    hasSignature: false,
    createdAt: user.createdAt || day(),
    updatedAt: user.updatedAt || day(),
  };
};

export const toManagerWizard = (manager: Partial<Manager>): ManagerWizard => {
  const supervisorId = manager?.supervisor?.id;
  return {
    type: 'manager_repositories',
    id: manager.id || '',
    attributes: {
      avatar: manager?.avatar || '',
      email: manager?.email || '',
      first_name: manager?.firstName || '',
      initials: manager?.initials || '',
      last_name: manager?.lastName || '',
      service: manager?.service || '',
      title: manager?.title || '',
      internal_role: manager?.internalRole || MANAGER_INTERNAL_ROLES.MANAGER,
    },
    relationships: {
      supervisor: {
        data:
          (supervisorId && {
            id: supervisorId,
            type: 'manager_repositories',
          }) ||
          null,
      },
    },
  };
};

export const toManagerFilterParams = ({
  orderBy,
  filter,
  ...filterParams
}: FilterParams): FilterParams => {
  const mapPropsToHttp = (prop?: string): string | undefined => {
    switch (prop) {
      case 'lastName':
        return 'last_name';
      case 'firstName':
        return 'first_name';
      default:
        return prop;
    }
  };

  const renameFilter = Object.keys(filter || {}).reduce(
    (acc, cur) => {
      const propName = mapPropsToHttp(cur);
      if (!propName) {
        return acc;
      }
      acc[propName] = (filter || {})[cur] || '';
      return acc;
    },
    {} as {
      [key: string]: string | number | string[] | undefined;
    },
  );

  return {
    ...filterParams,
    orderBy: mapPropsToHttp(orderBy),
    filter: renameFilter,
  };
};

export const fromStoredSearchManager = ({
  id,
  fullName,
}: Pick<Manager, 'id' | 'fullName'>): Manager => {
  return {
    internalRole: MANAGER_INTERNAL_ROLES.MANAGER,
    avatar: '',
    createdAt: dayjs(),
    email: '',
    firstName: '',
    fullName: fullName,
    id: id,
    initials: '',
    lastConnection: dayjs(),
    lastName: '',
    service: '',
    supervisor: toManager({}),
    title: '',
    updatedAt: dayjs(),
    hasSignature: false,
  };
};

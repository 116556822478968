/* eslint-disable no-unsafe-optional-chaining */
import { container } from '@_plugins/ioc';
import AtlasApi from '@data/api/atlas.api';
import { toHabilitation } from '@data/dto/habilitation.dto';
import { Meta, PaginateResult } from '@data/models/api.interface';
import { FilterParams } from '@data/models/filter.interface';
import {
  Habilitation,
  HabilitationHttp,
} from '@data/models/habilitation.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class HabilitationService {
  private API = container.get<AtlasApi>(AtlasApi);
  private errorService = container.get<ErrorService>(ErrorService);
  private atlasReferentielEndpoint = '/search';

  public async listHabilitations(
    filters: FilterParams,
  ): Promise<PaginateResult<Habilitation[]>> {
    const { data: response, error } = await this.API?.get<{
      data: HabilitationHttp[];
      meta: Meta;
    }>(this.atlasReferentielEndpoint, filters);
    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      totalSize: response?.data?.length || 0,
      items: response?.data?.map(toHabilitation) || [],
    };
  }
}

/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toSortParams } from '@data/dto/api.dto';
import {
  toDelegation,
  toDelegationFilterParams,
  toDelegationIncluded,
} from '@data/dto/delegation.dto';
import { Meta, PaginateResult, SortParams } from '@data/models/api.interface';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import {
  Delegation,
  DelegationHttp,
  DelegationRawIncluded,
  DelegationWizard,
} from '@data/models/delegation.interface';
import { FilterParams } from '@data/models/filter.interface';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class DelegationService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private errorService = container.get<ErrorService>(ErrorService);
  private delegationEndpoint = '/delegations';

  public async listDelegations(
    filters: FilterParams,
  ): Promise<PaginateResult<Delegation[]>> {
    const { data: response, error } = await this.API?.get<{
      data: DelegationHttp[];
      included: DelegationRawIncluded;
      meta: Meta;
    }>(
      this.delegationEndpoint,
      toDelegationFilterParams({
        ...filters,
        include: ['regions', 'manager', 'delegation_manager'],
      }),
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const included = toDelegationIncluded(response?.included);
    return {
      items:
        response?.data?.map((delegation) =>
          toDelegation(delegation, included),
        ) || [],
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async upsertDelegation({
    id,
    ...delegationWizard
  }: DelegationWizard): Promise<Delegation | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<DelegationWizard, 'id'> },
        { data: DelegationHttp; included: DelegationRawIncluded }
      >(this.delegationEndpoint, { data: delegationWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }
      const included = toDelegationIncluded(data?.included);
      return toDelegation(data?.data || {}, included);
    }

    const { data, error } = await this.API?.patch<
      { data: DelegationWizard },
      { data: DelegationHttp; included: DelegationRawIncluded }
    >(`${this.delegationEndpoint}/${id}`, {
      data: { ...delegationWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toDelegationIncluded(data?.included);
    return toDelegation(data?.data || {}, included);
  }

  public async deleteDelegation(id: string): Promise<Delegation | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: DelegationHttp;
      included: DelegationRawIncluded;
    }>(`${this.delegationEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }
    const included = toDelegationIncluded(data?.included);
    return toDelegation(data?.data || {}, included);
  }

  public async sortDelegation(
    sortParams: DatatableReorderParams<Delegation>,
  ): Promise<Delegation | void> {
    const {
      current: { id },
    } = sortParams;

    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.sorting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { error } = await this.API?.post<{ data: SortParams }, {}>(
      `${this.delegationEndpoint}/${id}/sort`,
      {
        data: toSortParams('manager_delegation', sortParams),
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return sortParams.current;
  }
}

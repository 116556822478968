/* eslint-disable no-unsafe-optional-chaining */
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import {
  fromStatRegionHttp,
  fromStatTypeHttp,
  toStatMe,
} from '@data/dto/statistic.dto';
import {
  StatMe,
  StatMeHttp,
  StatRegionHttp,
  StatTypeHttp,
} from '@data/models/statistic.interface';
import { ChartData } from 'chart.js';
import ErrorService from 'core/error.service';
import { injectable } from 'inversify';

@injectable()
export default class StatisticService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly statisticEntrypoint = '/statistics';
  private errorService = container.get<ErrorService>(ErrorService);

  public async me(): Promise<StatMe[]> {
    const { data: response, error } = await this.API?.get<{
      data: StatMeHttp[];
    }>(`${this.statisticEntrypoint}?filter[type]=me`);

    if (error) {
      this.errorService.handleHttpError(error);
      return [];
    }

    return toStatMe(response?.data[0] || {});
  }

  public async type(
    period: 'month' | 'year' | 'week' = 'month',
  ): Promise<ChartData<'line', number[], string>> {
    const { data: response, error } = await this.API?.get<{
      data: StatTypeHttp[];
    }>(
      `${this.statisticEntrypoint}?filter[type]=type&filter[period]=${period}`,
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return fromStatTypeHttp({});
    }

    return fromStatTypeHttp(response?.data[0] || {}, period);
  }

  public async region(): Promise<ChartData<'doughnut', number[], string>> {
    const { data: response, error } = await this.API?.get<{
      data: StatRegionHttp[];
    }>(`${this.statisticEntrypoint}?filter[type]=region`);

    if (error) {
      this.errorService.handleHttpError(error);
      return fromStatRegionHttp({});
    }

    return fromStatRegionHttp(response?.data[0] || {});
  }
}

import day from 'dayjs';
import 'dayjs/locale/fr';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import LocalizedFormats from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import UTC from 'dayjs/plugin/utc';

day.extend(relativeTime);
day.extend(LocalizedFormats);
day.extend(duration);
day.extend(CustomParseFormat);
day.extend(UTC);
day.locale('fr');

export default day;

import { CandidateTypeHttp } from '@data/models/candidate-type.interface';
import { TrainingActionCandidateTrainingHttp } from '@data/models/training-action-candidate-training.interface';
import { TrainingActionTrainingCandidateInvoiceHttp } from '@data/models/training-action-training-candidate-invoice.interface';
import { TRAINING_ACTION_STATUSES } from '@data/models/training-action.interface';
import { TrainingCourseTrainingHttp } from '@data/models/training-course-training.interface';
import { User } from '@data/models/user.interface';
import { Dayjs } from 'dayjs';

import { CityHttp } from './city.interface';
import { CoFunderHttp } from './co-funder.interface';
import { CourseTypeHttp } from './course-type.interface';
import { CustomerTypeHttp } from './customer-type.interface';
import { DeviceType, DeviceTypeHttp } from './device-type.interface';
import { CpefManagerHttp, Manager, ManagerHttp } from './manager.interface';
import { MinWageHttp } from './min-wage.interface';
import { OpcoChargeHttp } from './opco-charge.interface';
import { Profession, ProfessionHttp } from './profession.interface';
import { ProjectOrigin, ProjectOriginHttp } from './project-origin.interface';
import { Region, RegionHttp } from './region.interface';
import { StrategicAxis, StrategicAxisHttp } from './strategic-axis.interface';
import {
  TrainingCourseCandidate,
  TrainingCourseCandidateHttp,
} from './training-course-candidate.interface';
import {
  TrainingCourseCustomer,
  TrainingCourseCustomerHttp,
} from './training-course-customer.interface';
import {
  TrainingCourseEducationalPath,
  TrainingCourseEducationalPathHttp,
} from './training-course-educational-path.interface';
import {
  EducationalCostHttp,
  EstimatorCandidateTypeHttp,
  TrainingCourseEstimator,
  TrainingCourseEstimatorHttp,
} from './training-course-estimator.interface';
import {
  TrainingCourseGroup,
  TrainingCourseGroupHttp,
} from './training-course-group.interface';
import {
  TrainingCourseSession,
  TrainingCourseSessionHttp,
} from './training-course-session.interface';
import { TrainingOptionHttp } from './training.interface';
import { ValidationTypeHttp } from './validation-type.interface';

export const TRAINING_COURSE_STORAGE = 'TRAINING_COURSE_STORAGE';

export enum EditStep {
  CONTEXT = 'context',
  PROGRAM = 'program',
  CANDIDATES = 'candidates',
  ESTIMATOR = 'estimator',
  VALIDATION = 'validation',
  SCHEDULE = 'schedule',
  GED = 'ged',
  SUMMARY = 'summary',
  CONTROL = 'control',
  ACTION_SCHEDULE = 'action_schedule',
  ACTION_DOCUMENT = 'action_document',
  ACTION_HISTORY = 'action_history',
  ACTION_MESSAGE = 'action_message',
}

export enum EditAction {
  FORM = 'FORM',
  GED = 'GED',
  HISTORY = 'HISTORY',
  CONVERSION = 'CONVERSION',
  ACTION = 'ACTION',
}

export enum CancellationType {
  CANCELLATION_TYPE_ERROR = 1,
  CANCELLATION_TYPE_CANDIDATE = 2,
  CANCELLATION_TYPE_CLIENT = 3,
  CANCELLATION_TYPE_OTHER = 4,
}

export type TrainingCourseRawIncluded = Array<
  | TrainingCourseGroupHttp
  | TrainingCourseTrainingHttp
  | TrainingCourseEducationalPathHttp
  | TrainingCourseCandidateHttp
  | TrainingCourseCustomerHttp
  | CandidateTypeHttp
  | ProfessionHttp
  | ProjectOriginHttp
  | RegionHttp
  | DeviceTypeHttp
  | ManagerHttp
  | CpefManagerHttp
  | StrategicAxisHttp
  | CustomerTypeHttp
  | CourseTypeHttp
  | ValidationTypeHttp
  | CityHttp
  | TrainingOptionHttp
  | TrainingCourseSessionHttp
  | TrainingCourseEstimatorHttp
  | EducationalCostHttp
  | MinWageHttp
  | OpcoChargeHttp
  | EstimatorCandidateTypeHttp
  | CoFunderHttp
  | TrainingActionTrainingCandidateInvoiceHttp
  | TrainingActionCandidateTrainingHttp
>;

export type TrainingCourseIncluded = {
  groups: TrainingCourseGroupHttp[];
  potentialCandidates: TrainingCourseCandidateHttp[];
  candidateTypes: CandidateTypeHttp[];
  customers: TrainingCourseCustomerHttp[];
  customerTypes: CustomerTypeHttp[];
  strategicAxes: StrategicAxisHttp[];
  trainings: TrainingCourseTrainingHttp[];
  educationalPaths: TrainingCourseEducationalPathHttp[];
  professions: ProfessionHttp[];
  projectOrigin: ProjectOriginHttp[];
  regions: RegionHttp[];
  deviceTypes: DeviceTypeHttp[];
  managers: ManagerHttp[];
  cpefManagers: CpefManagerHttp[];
  courseTypes: CourseTypeHttp[];
  validationTypes: ValidationTypeHttp[];
  cities: CityHttp[];
  options: TrainingOptionHttp[];
  sessions: TrainingCourseSessionHttp[];
  estimators: TrainingCourseEstimatorHttp[];
  educationalCosts: EducationalCostHttp[];
  minWages: MinWageHttp[];
  opcoCharges: OpcoChargeHttp[];
  estimatorCandidateTypes: EstimatorCandidateTypeHttp[];
  coFunders: CoFunderHttp[];
  invoices: TrainingActionTrainingCandidateInvoiceHttp[];
  candidateTrainings: TrainingActionCandidateTrainingHttp[];
};

export interface TrainingCourseHttp {
  type: 'training_courses';
  id: string;
  attributes: {
    is_locked: number | boolean;
    id: string;
    user_id: number;
    volume: number;
    has_lucie_advertisement: number;
    is_recurring_need: number;
    is_national_need: number;
    unidentified_candidates: boolean;
    lucie_advertisement_reference: string;
    fees_description: string;
    description: string;
    other_strategic_axis: string;
    interlocutor: string;
    reference: string;
    deadline: string;
    accommodation_fees: string;
    transport_fees: string;
    meal_fees: string;
    total_fees: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    acknowledged_at: string;
    manager: string;
    status: TRAINING_COURSE_STATUSES;
    priority: TRAINING_COURSE_PRIORITIES;
    is_teleoperator: boolean | number;
    is_epsp_approach: boolean | number;
    current_step: EditStep;
    cancellation_type: CancellationType | null;
    cancellation_reason: string | null;
    locked_at: string | null;
  };
  relationships: {
    groups: {
      data?: {
        type: 'training_course_groups';
        id: string;
      }[];
    };
    potential_candidates: {
      data?: {
        type: 'training_course_candidates';
        id: string;
      }[];
    };
    customers: {
      data?: {
        type: 'customer_repositories';
        id: string;
      }[];
    };
    estimators: {
      data?: {
        type: 'training_course_estimators';
        id: string;
      }[];
    };
    profession: {
      data?: {
        type: 'profession_repositories';
        id: string;
      };
    };
    region: {
      data?: {
        type: 'region_repositories';
        id: string;
      };
    };
    device_type: {
      data?: {
        type: 'device_type_repositories';
        id: string;
      };
    };
    manager: {
      data?: {
        type: 'manager_repositories';
        id: string;
      };
    };
    cpef_manager: {
      data?: {
        type: 'manager_repositories';
        id: string;
      };
    };
    project_origin: {
      data?: {
        type: 'project_origin_repositories';
        id: string;
      };
    };
    strategic_axes: {
      data?: {
        type: 'strategic_axis_repositories';
        id: string;
      }[];
    };
    educational_paths: {
      data?: {
        type: 'training_course_educational_paths';
        id: string;
      }[];
    };
    candidate_type: {
      data?: {
        type: 'candidate_type_repositories';
        id: string;
      };
    };
  };
}

export interface TrainingCourseWizard {
  type: 'training_courses';
  id: string;
  attributes: {
    user_id: number;
    volume: number;
    has_lucie_advertisement: boolean;
    is_recurring_need: boolean;
    is_national_need: boolean;
    lucie_advertisement_reference?: string;
    fees_description?: string;
    accommodation_fees?: string;
    transport_fees?: string;
    meal_fees?: string;
    total_fees?: string;
    description: string;
    other_strategic_axis: string;
    interlocutor: string;
    deadline: string;
    unidentified_candidates: boolean;
    is_teleoperator: boolean;
    is_epsp_approach: boolean;
    current_step: EditStep;
    status: TRAINING_COURSE_STATUSES | TRAINING_ACTION_STATUSES;
    priority: TRAINING_COURSE_PRIORITIES;
    cancellation_type: CancellationType | null;
    cancellation_reason: string | null;
    deletedAt: Dayjs | null;
  };
  relationships: {
    project_origin: {
      data: {
        type: 'project_origin_repositories';
        id: string;
      } | null;
    };
    profession: {
      data: {
        type: 'profession_repositories';
        id: string;
      } | null;
    };
    manager?: {
      data: {
        type: 'manager_repositories';
        id: string;
      };
    };
    cpef_manager?: {
      data: {
        type: 'manager_repositories';
        id: string;
      };
    };
    region?: {
      data: {
        type: 'region_repositories';
        id: string;
      };
    };
    strategic_axes: {
      data?: {
        type: 'strategic_axis_repositories';
        id: string;
      }[];
    };
    device_type?: {
      data?: {
        type: 'device_type_repositories';
        id: string;
      };
    };
    educational_paths?: {
      data?: {
        type: 'training_course_educational_paths';
        id: string;
      }[];
    };
  };
}

export interface TrainingCourse {
  id: string | null;
  status: TRAINING_COURSE_STATUSES | TRAINING_ACTION_STATUSES;
  priority: TRAINING_COURSE_PRIORITIES;
  reference: string;
  groups: TrainingCourseGroup[];
  educationalPaths: TrainingCourseEducationalPath[];
  candidates: TrainingCourseCandidate[];
  user: User;
  description: string;
  projectOriginId: string;
  projectOrigin?: ProjectOrigin;
  professionId?: string;
  profession?: Profession;
  deadline?: Dayjs;
  strategicAxes: StrategicAxis[];
  otherStrategicAxes: string;
  candidatesNumber: number | null;
  feesDescription: string;
  hasLucieAd: boolean;
  lucieAdRef: string;
  isRecurringNeed: boolean;
  isNationalNeed: boolean;
  customers: TrainingCourseCustomer[];
  deviceType: DeviceType;
  manager: Manager;
  cpefManager: Manager;
  region: Region;
  createdAt: Dayjs;
  updatedAt: Dayjs;
  acknowledgedAt: Dayjs;
  isCustomerOptional: boolean;
  accommodationFees: string;
  transportFees: string;
  mealFees: string;
  totalFees: string;
  interlocutor: string;
  sessions: TrainingCourseSession[];
  unidentifiedCandidates: boolean;
  isTeleoperatorTraining: boolean;
  isEPSPApproach: boolean;
  estimators: TrainingCourseEstimator[];
  currentStep: EditStep;
  cancellationType: CancellationType | null;
  cancellationReason: string | null;
  isLocked: boolean;
  fromLucie?: boolean;
  lockedAt: Dayjs | null;
  deletedAt: Dayjs | null;
}

export interface TrainingCourseStorage
  extends Omit<TrainingCourse, 'deadline'> {
  deadline: string;
}

// with action statuses
export type TrainingCourseStatus =
  | 'to_do'
  | 'waiting_for_ae'
  | 'waiting_for_of_date'
  | 'waiting_for_validation'
  | 'waiting_for_of_convention'
  | 'done'
  | 'to_check'
  | 'cancel'
  | 'waiting_for_retour_ae'
  | 'waiting_for_opco'
  | 'relaunch_opco'
  | 'in_progress'
  | 'complete'
  | 'payroll_control'
  | 'dispute'
  | 'todo_dr'
  | 'scoring'
  | 'closed'
  | 'cancelled';

export enum TRAINING_COURSE_STATUSES {
  TODO = 'to_do',
  WAITING_FOR_AE = 'waiting_for_ae',
  WAITING_FOR_OF_DATE = 'waiting_for_of_date',
  WAITING_FOR_VALIDATION = 'waiting_for_validation',
  WAITING_FOR_OF_CONVENTION = 'waiting_for_of_convention',
  DONE = 'done',
  TO_CHECK = 'to_check',
  CANCEL = 'cancel',
}

export enum TRAINING_COURSE_STATUSES_SELECT {
  TODO = 'to_do',
  WAITING_FOR_AE = 'waiting_for_ae',
  WAITING_FOR_OF_DATE = 'waiting_for_of_date',
  WAITING_FOR_VALIDATION = 'waiting_for_validation',
  WAITING_FOR_OF_CONVENTION = 'waiting_for_of_convention',
  TO_CHECK = 'to_check',
  CANCEL = 'cancel',
}

export enum TRAINING_COURSE_DEFAULT_FILTERS {
  TODO = 'to_do',
  WAITING_FOR_AE = 'waiting_for_ae',
  WAITING_FOR_OF_DATE = 'waiting_for_of_date',
  WAITING_FOR_OF_CONVENTION = 'waiting_for_of_convention',
}

export type TrainingCoursePriority = 'highest' | 'high' | 'medium' | 'low';

export enum TRAINING_COURSE_PRIORITIES {
  HIGHEST_PRIORITY = 'highest',
  HIGH_PRIORITY = 'high',
  MEDIUM_PRIORITY = 'medium',
  LOW_PRIORITY = 'low',
}

export enum TRAINING_COURSE_ATTRIBUTE_CATEGORY {
  UNDEFINED = '',
  GROUPS = 'groups',
  CANDIDATES = 'candidates',
  TRAININGS = 'trainings',
  CUSTOMERS = 'customers',
  ESTIMATORS = 'estimators',
}
export interface TrainingCourseAttribute {
  value: string;
  label: string;
  category: TRAINING_COURSE_ATTRIBUTE_CATEGORY;
}

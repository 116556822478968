import { useEffect } from 'react';

import { TrainingCourse } from '@data/models/training-course.interface';
import TrainingCourseService from '@data/services/training-course.service';
import * as Sentry from '@sentry/react';
import useStore from '@stores/store';
import { useInjection } from 'context/Ioc.context';
import { useShallow } from 'zustand/react/shallow';

const useTrainingCourseRetriever = (
  ref: string,
): {
  trainingCourse: TrainingCourse | undefined;
  setTrainingCourse: (trainingCourse: TrainingCourse) => void;
} => {
  const { addShowTrainingCourse, updateShowTrainingCourse, trainingCourse } =
    useStore(
      useShallow((state) => ({
        addShowTrainingCourse: state.addShowTrainingCourse,
        updateShowTrainingCourse: state.updateShowTrainingCourse,
        trainingCourse: state.findTrainingCourseByRef(`ERPF#${ref}`),
      })),
    );

  const service = useInjection<TrainingCourseService>(TrainingCourseService);

  useEffect(() => {
    document.title = ` ALEC - ERPF#${ref}`;

    service.getTrainingCourseByRef(ref).then((trainingCourseRetrieved) => {
      if (!trainingCourseRetrieved) {
        Sentry.captureException({
          title: 'Get training course by ref',
          message: `Cannot retrieve training course with ${ref}`,
        });
        return;
      }
      if (trainingCourseRetrieved.id !== trainingCourse?.id)
        addShowTrainingCourse(trainingCourseRetrieved);
    });
  }, [ref]);

  const setTrainingCourse = (trainingCourse: TrainingCourse) =>
    updateShowTrainingCourse(trainingCourse);

  return {
    trainingCourse,
    setTrainingCourse,
  };
};

export default useTrainingCourseRetriever;

import i18n from '@_plugins/i18n';
import { AppError, HttpErrorResponse } from '@data/models/error.interface';
import * as Sentry from '@sentry/react';
import useStore from '@stores/store';
import { injectable } from 'inversify';

@injectable()
export default class ErrorService {
  public handleHttpError(error: HttpErrorResponse): void {
    for (const e of error.errors) {
      if (['401', '502', '503', '504'].includes(e.status)) {
        window.location.href = `${String(
          process.env.VITE_APP_LOGIN_URL,
        )}?redirect_uri=${window.location.href}`;
        return;
      }

      useStore.getState().addToast({
        level: 'error',
        title: i18n.t('error.internal_server_error'),
        message: e.detail || i18n.t('error.universal'),
      });
    }
  }

  public handleError({ title, message }: AppError): void {
    Sentry.captureException({
      title,
      message,
    });

    useStore.getState().addToast({
      title,
      message,
      level: 'error',
    });
  }
}

import dashboard from './dashboard';
import repositories from './repositories';
import trainingCourseActions from './training-action';
import trainings from './trainings';

export { datatable } from './datatable';

const navigation = {
  'navigation.links.training-course-list': 'Tous les dossiers',
  'navigation.links.home': 'Accueil',
  'navigation.links.configurations': 'Configurations',
  'navigation.links.repositories': 'Référentiels',
  'navigation.links.estimator-configurations': 'Deviseur',
  'navigation.links.training-outside-catalog': 'Hors catalogue',
  'navigation.links.regions': 'Régions Actual',
  'navigation.links.project_origins': 'Origine du projet',
  'navigation.links.activity_field': "Domaines d'activités",
  'navigation.links.device_type': 'Type de dispositifs',
  'navigation.links.candidate_type': 'Publics',
  'navigation.links.profession': 'Emploi / qualification',
  'navigation.links.managers': 'Utilisateurs',
  'navigation.links.opco_charge': 'Charges OPCO',
  'navigation.links.min_wage': 'SMIC',
  'navigation.links.pec_rate': 'Taux PEC',
  'navigation.links.relief_type': "Types d'allègements",
  'navigation.links.blocklist': 'Blocklist',
  'navigation.links.control_organization': 'Organismes de suivi',
  'navigation.links.course_type': 'Types de parcours',
  'navigation.links.validation_type': 'Types de validation',
  'navigation.links.financing_type': 'Types de financement',
  'navigation.links.customer_type': 'Types de clients',
  'navigation.links.strategic_axis': 'Axes stratégiques',
  'navigation.links.co_funders': 'Cofinanceurs',
  'navigation.links.group_migrations': 'Sirets',
  'navigation.links.training': 'Demande de formation',
  'navigation.links.training_groups': 'Agence(s) concernée(s)',
  'navigation.links.training_courses': 'Parcours de formation',
  'navigation.links.training_candidates': 'Candidat(s) pressenti(s)',
  'navigation.links.potential_candidates': 'Candidats',
  'navigation.links.training_assignment': 'Missions',
  'navigation.links.planned_mission': 'Mission(s) envisagée(s)',
  'navigation.links.create_training': 'Nouvelle demande de formation',
  'navigation.links.modify_training':
    'Modifier une demande de formation - Ref : ',
  'navigation.links.training_mission': 'Missions envisagées',
  'navigation.links.initializer': 'Initialiser une demande',
  'navigation.links.training_confirmation': 'Confirmation de demande',
  'navigation.links.training_error':
    'votre demande de formation n’est pas enregistrée',
  'navigation.links.dashboard': 'Tableau de bord',
  'navigation.links.training-catalog': 'Catalogue formations',
  'navigation.links.statistics': 'Statistiques',
  'navigation.links.add-training': 'Ajouter un dossier',
  'navigation.emulated_by': 'Vous êtes connecté(e) avec le compte de {{name}}',
};

const pages = {
  'pages.dashboard.title': 'Accueil',
  'pages.users.title': 'Utilisateurs',
  'pages.404.title': 'Oups ! Erreur 404',
  'pages.home.card.show_info': "Il s'agit de personnage de la saga Star Wars",
  'pages.home.card.set_action': 'Set name  "{{name}}"',
};

const system = {
  'system.title': 'Starter Kit',
};

const actions = {
  'action.confirm.add': 'Ajouter',
  'action.autocomplete.open': 'Ouvrir',
  'action.autocomplete.close': 'Fermer',
  'action.autocomplete.others': 'AUTRES',
  'action.save.confirm': 'Enregistrement terminé',
  'action.delete.confirm': 'Suppression terminée',
  'action.delete.title': 'Confirmer la suppression?',
  'action.delete.message': 'Cette action est irréversible',
  'action.edit.label': 'Modifier',
  'action.edit.save': 'Sauvegarder',
  'action.edit.save_to_lucie': 'Sauvegarder et envoyer',
  'action.delete.label': 'Supprimer',
  'action.submit.label': 'Valider',
  'action.submit.register': 'Enregistrer',
  'action.confirm.label': 'Confirmer',
  'action.cancel.label': 'Annuler',
  'action.next.label': 'Suivant',
  'action.prev.label': 'Précédent',
  'action.validation.send': 'Envoyer ma demande',
  'action.validation.modify': 'Modifier ma demande',
  'action.restore.title': 'Confirmer la restauration?',
  'action.restore.message': 'Cette action restaurera la demande de formation',
  'action.inactivity.spy.title': 'Inactivité détectée',
  'action.inactivity.spy.message':
    "Vous n'avez pas été actif pendant plus de 2 heures, votre session a expiré. Vous allez être redirigé.",
};

const trainingCourse = {
  'action.training.cancel.label': 'Supprimer le dossier',
  'action.training.restore.label': 'Restaurer le dossier',
  'action.training.cancel.title': 'Annuler le dossier ERP {{ref}}',
  'action.training.cancel.info':
    'Vous vous apprêtez à supprimer le dossier {{ref}}. Une fois cette étape validée, elle ne sera plus accessible.',
  'training.cancel.select.label': 'Motif de la suppression',
  'training.cancel.reason.label': 'Indiquez votre motif',
  'training.cancel.select.cancellation_type.CANCELLATION_TYPE_CANDIDATE':
    'Intérimaire',
  'training.cancel.select.cancellation_type.CANCELLATION_TYPE_CLIENT': 'Client',
  'training.cancel.select.cancellation_type.CANCELLATION_TYPE_ERROR':
    'Doublon/Erreur',
  'training.cancel.select.cancellation_type.CANCELLATION_TYPE_OTHER': 'Autre',
};

const common = {
  'common.props.timestamp': 'Horodatage',
  'common.props.createdAt': 'Date de création',
  'common.props.updatedAt': 'Date de modification',
};

const forms = {
  'form.validation.too_long': '{{number}} caractères max.',
  'form.validation.number_length': '{{number}} caractères attendu(s).',
  'form.validation.too_short': '{{number}} caractères min.',
  'form.validation.required': 'Champ obligatoire',
  'form.validation.file_error':
    'Les documents ne doivent pas dépasser 20Mo et doivent avoir une extension reconnue.',
  'form.validation.file_size': 'Le document ne doit pas dépasser 20 Mo',
  'form.validation.file_extension': 'Le format du document est incorrect',
  'form.validation.hourly_rate':
    'Le taux doit être compris entre {{minWage}} et 50 euros',
  'form.validation.mission_rate':
    'Le taux doit être compris entre {{minWage}} et 50 euros',
  'form.validation.number_min':
    'La valeur doit être supérieure ou égale à {{number}}',
  'form.validation.number_max':
    'La valeur doit être inférieur ou égale à {{number}}',
  'form.validation.date_min': 'La date doit être supérieur au {{date}}',
  'form.validation.date_max':
    'La date doit être inférieur ou égale au {{date}}',
  'form.validation.float': 'Le champ doit être un nombre.',
  'form.validation.integer': 'Le champ doit être un nombre entier.',
  'form.validation.birthday_date.required': 'La date de naissance est requise',
  'form.validation.first_name.required': 'Le prénom est requis',
  'form.validation.last_name.required': 'La nom de famille est requis',
  'form.validation.strategic_axes.required': 'Un axe stratégique est requis.',
  'form.select.loading': 'Chargement...',
  'form.select.no_result': 'Aucun résultat',
  'form.select.tape_to_search':
    'Saisissez {{number}} caractères pour lancer la rechercher',
  'form.training-course.training-select.label': 'Ajouter une formation',
  'form.training-course.training-select.link':
    'Référentiel Catalogue formations',
  'form.training-course.training-select.placeholder':
    'Intitulé de formation, domaine...',
  'form.training-course.candidate-select.label': 'Ajouter un stagiaire',
  'form.training-course.candidate-select.placeholder':
    'Nom, IDBO, matricule,...',
  'form.training-course.candidate.candidates_number_error':
    'Le volume de candidat doit être égale au nombre de candidats',
  'form.search.name.max':
    'Le nom de la recherche ne doit pas excéder 50 caractères.',
  'form.validation.load_file_error': 'Impossible de charger le document',
};

const profile_menu = {
  'profile_menu.link.profile': 'Profil',
  'profile_menu.link.logout': 'Déconnexion',
};

const errors = {
  'error.internal_server_error': 'Erreur serveur',
  'error.sorting': 'Trie impossible',
  'error.deleting': 'Suppression impossible',
  'error.id.missing': "L'id est obligatoire",
  'error.universal': 'Erreur technique. Contactez le support si cela persiste.',
};

const user_notifications = {
  'user_notification.list.region': 'Régions',
  'user_notification.list.quick_access': 'Liens pratiques',
  'user_notification.list.title': 'Liste des notifications',
  'user_notification.list.message': 'Message',
  'user_notification.list.notification_title': 'Titre',
  'user_notification.list.readAt': 'Lu le',
  'user_notification.list.all': 'Voir toutes mes notifications',
  'user_notification.list.mark-as-read': 'Marquer comme lu',
  'user_notification.list.mark-as-unread': 'Marquer comme non lu',
};

const notion = {
  'notion.help.title': 'Journal de mise à jour',
};

const estimator_configurations = {
  'estimator_configurations.main.title': 'Configuration Deviseur',
  'estimator_configuration.main.default_pec_rate': 'Taux PEC par défaut',
  'estimator_configuration.main.duration': 'Durée',
  'estimator_configuration.main.misc': 'Divers',
  'estimator_configuration.form.a2i_grant': 'Subvention A2I',
  'estimator_configuration.form.additional_pec_rate': 'Classique additionnel',
  'estimator_configuration.form.afpr_pec_rate': 'AFPR',
  'estimator_configuration.form.days_worked_per_month':
    'Jours travaillés par mois',
  'estimator_configuration.form.default_mission_duration_per_month':
    'Nombre heures missions par mois',
  'estimator_configuration.form.default_pec_rate': 'Classique',
  'estimator_configuration.form.hours_worked_in_year':
    'Nombre heures de travail par mois',
  'estimator_configuration.form.poec_pec_rate': 'POEC',
  'estimator_configuration.form.senior_age': 'Age sénior',
  'estimator_configuration.form.teleoperator_pec_rate': 'Téléopérateur',
  'estimator_configuration.form.wages_rate': 'Coef multiplicateur des salaires',
  'estimator_configuration.form.t_parameter': 'Paramètre T',
  'estimator_configuration.form.additional_teleoperator_pec_rate':
    'Téléoperateur additionnel',
};

export const documents = {
  'document.detail.title': 'Détail du document',
  'document.toolbar.import': 'Importer',
};

const chat = {
  'chat.error.message': "Problème lors de l'envoi du message",
  'chat.error.document': "Problème lors de l'envoi du document",
  'chat.error.document.version':
    "Problème lors de l'envoi de la version du document",
  'chat.new.message': 'Nouveau message !',
  'chat.start.conversation': 'Démarrez la conversation',
  'chat.send.document': 'Cliquez sur envoyer pour envoyer la pièce jointe',
};

export default {
  ...navigation,
  ...pages,
  ...system,
  ...actions,
  ...common,
  ...forms,
  ...trainingCourse,
  ...repositories,
  ...trainings,
  ...dashboard,
  ...profile_menu,
  ...errors,
  ...user_notifications,
  ...notion,
  ...estimator_configurations,
  ...trainingCourseActions,
  ...documents,
  ...chat,
};
